import { useEffect, useRef } from 'react';

export function useInterval(fn: Function = () => null, delay: number | null) {
    const savedCallback = useRef(fn);

    useEffect(() => {
        savedCallback.current = fn;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
