import { rem } from 'polished';
import styled from 'styled-components';

import { devices } from '../../utils/deviceUtils';

export const ConfirmationCardContainer = styled.div`
    border-radius: ${rem(8)};
    background: #e3e8ed;
    padding-bottom: 5vh;
    max-width: ${rem(768)};
    label {
        color: ${({ theme }) => theme.creditAppConfirmationCardContainerColor};
    }
    @media ${devices.mobileL} {
        padding-bottom: 0;
    }
`;

export const ConfirmationCardBanner = styled.div`
    display: flex;
    border-radius: ${rem(8)};
    background: #ffffff;
    padding: ${rem(24)};
    display: flex;
    justify-content: space-between;
    box-shadow: 0 ${rem(1)} ${rem(4)} 0 rgba(0, 0, 0, 0.2), 0 ${rem(2)} ${rem(5)} 0 rgba(0, 0, 0, 0.19);
    @media ${devices.mobileL} {
        display: block;
        padding: ${rem(13)};
        margin-top: 2vh;
    }
`;

export const BannerTitle = styled.div`
    width: 100%;
    font-size: ${rem(26)};
    font-weight: 600;
    margin: auto;
    margin-left: ${rem(16)};
    @media ${devices.tabletL} {
        font-size: ${rem(20)};
    }
    @media ${devices.tablet} {
        font-size: ${rem(20)};
    }
    @media ${devices.mobileL} {
        font-size: ${rem(19)};
        margin-left: ${rem(8)};
    }
`;

export const ConfirmationSummary = styled.div`
    padding: ${rem(12.8)} ${rem(32)};
    @media ${devices.mobileL} {
        padding: ${rem(6)} ${rem(16)};
    }
`;

export const ApplicationNumberLabel = styled.div`
    font-weight: bold;
    margin: ${rem(16)} 0 ${rem(16)} 0;
`;

export const CheckmarkIcon = styled.div`
    font-size: ${rem(32)};
    @media ${(devices.mobile, devices.mobileL)} {
        font-size: ${rem(24)};
    }
`;

export const Subtitle = styled.div`
    font-size: ${rem(16)};
    line-height: 1.5;
    @media ${devices.mobileL} {
        margin-bottom: ${rem(28)};
        height: 5vh;
        font-size: ${rem(15.5)};
    }
    @media ${devices.mobile} {
        line-height: 1.2;
        margin-bottom: ${rem(12)};
        font-size: ${rem(13)};
    }
`;
