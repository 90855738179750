import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Address } from '../models/Address.model';
import { Housing } from '../models/Housing.model';

export class CopySpouseAddressActionCreator extends ActionCreator<Housing> {
    constructor(fieldValue: string, private primaryApplicant?: Housing) {
        super(fieldValue);
    }
    mutateField = (state: Housing) => {
        return produce(state, (draft) => {
            if (draft.spouse) {
                if (this.primaryApplicant && this.fieldValue === 'true') {
                    draft.spouse.address = Address.copy(this.primaryApplicant?.address as Address);
                } else {
                    draft.spouse.address = new Address();
                }
                draft.spouse.spouseAddressSameAsPrimaryApplicant.setValue(this.fieldValue);
            }
        });
    };
    public getAction = () => (prevState: Housing) => this.mutateField(prevState);
}
