import React from 'react';

export const TermsAndConditionContent = () => {
    return (
        <>
            <strong>TERMS AND CONDITIONS:</strong>
            <ol>
                <li>
                    The words "we," "us," "our" and "ours" as used below refer to us, the dealer, and to the financial
                    institution(s) selected to receive your application.{' '}
                </li>
                <li>The words "you," "your" and "yours" mean each person submitting this application. </li>
                <li>
                    You understand and agree that you are applying for credit by providing the information to complete and submit
                    this credit application.{' '}
                </li>
                <li>
                    We may keep this application and any other application submitted to us and information about you whether or not
                    the application is approved.{' '}
                </li>
                <li>
                    You authorize us to submit this application and any other application submitted in connection with the proposed
                    transaction to the financial institutions disclosed to you by us the dealers; in addition, in accordance with
                    the Fair Credit Reporting Act, you authorize that such financial institutions may submit your applications to
                    other financial institutions for the purpose of fulfilling your request to apply for credit. This application
                    will be reviewed by the dealer and such financial institutions.
                </li>
                <li>
                    You agree that we may obtain a consumer credit report periodically from one or more consumer reporting agencies
                    (credit bureaus) in connection with the proposed transaction and any update, renewal, refinancing, modification
                    or extension of that transaction. You also agree that we or any affiliate of ours may obtain one or more
                    consumer credit reports on you at any time during the term of your financing. If you ask, you will be told
                    whether a credit report was requested, and if so, the name and address of any credit bureau from which we or our
                    affiliate obtained your credit report.{' '}
                </li>
                <li>
                    You agree that the dealer and the financial institutions may verify your employment, pay, assets and debts, and
                    that anyone receiving a copy of this is authorized to provide such dealer and financial institutions with such
                    information. You further authorize the dealer and the financial institutions to gather whatever credit and
                    employment history each considers necessary and appropriate in evaluating this application and any other
                    applications submitted in connection with the proposed transaction.
                </li>
                <li>
                    The dealer and the financial institutions may monitor and record telephone calls regarding your account for
                    quality assurance, compliance, training, or similar purposes.
                </li>
                <li>You understand that we will rely on the information in this credit application in making our decision. </li>
                <li>
                    You certify that the information on the application and in any other application submitted to us, is true and
                    complete. You understand that false statements may subject you to criminal penalties.{' '}
                </li>
            </ol>

            <p>
                <strong>DISCLOSURES</strong>
            </p>

            <p>
                <strong>FEDERAL NOTICES</strong>
                <br />
                IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT If applicable to your credit transaction, to help
                the government fight the funding of terrorism and money laundering activities, Federal law requires financial
                institutions to obtain, verify, and record information that identifies each person who opens an account. What this
                means for you: When you open an account, you will be asked for your name, address, date of birth, and other
                information to identify you. You may also be asked to see your driver's license or other identifying documents.
            </p>

            <p>
                <strong>COMMUNITY PROPERTY NOTICE:</strong> If the applicant is married and lives in a community property state (
                <strong>Arizona, California, Idaho, Louisiana, Nevada, New Mexico, Texas, Washington, Wisconsin</strong>), you may
                be asked to provide the personal credit information on the applicant’s spouse in the co-applicant section. The
                applicant’s spouse is not required to be a co-applicant for the credit requested unless he/she wishes to be a
                co-applicant.
            </p>

            <p>
                <strong>STATE NOTICES, as applicable:</strong>
            </p>

            <p>
                <strong>California Residents:</strong> An applicant, if married, may apply for a separate account.
            </p>

            <p>
                <strong>Maine Residents:</strong> You must have physical damage insurance covering loss or damage to the vehicle for
                the term of the contract. For a lease, you must also have the liability insurance as described in the lease. You may
                purchase required insurance through any insurance agent or broker and from any insurance company that is reasonably
                acceptable to us. You are not required to deal with any of our affiliates when choosing an agent, broker or insurer.
                Your choice of a particular insurance agent, broker or insurer will not affect our credit decision, so long as the
                insurance provides adequate coverage with an insurer who meets our reasonable requirements.
            </p>

            <p>
                <strong>New Hampshire Residents:</strong> If you are applying for a balloon payment contract, you are entitled, if
                you ask, to receive a written estimate of the monthly payment amount for refinancing the balloon payment in accord
                with the creditor’s existing refinance programs. You would be entitled to receive the estimate before you enter into
                a balloon payment contract. A balloon contract is an installment sales contract with a final scheduled payment that
                is at least twice the amount of one of the earlier scheduled equal periodic installment payments.
            </p>

            <p>
                <strong>New York Residents:</strong> In connection with your application for credit, a consumer report may be
                obtained from a consumer reporting agency (credit bureau). If credit is extended, the party or parties extending
                credit or holding such credit may order additional consumer reports in connection with any update, renewal or
                extension of the credit. If you ask, you will be told whether a consumer report was requested and, if so, the name
                and address of any consumer reporting agency (credit bureau) from which such credit report was obtained.
            </p>

            <p>
                <strong>Ohio Residents:</strong> Ohio laws against discrimination require that all creditors make credit equally
                available to all creditworthy customers and that credit reporting agencies maintain separate credit histories on
                each individual upon request. The Ohio Civil Rights Commission administers compliance with this law.
            </p>

            <p>
                <strong>Rhode Island Residents:</strong> Consumer reports may be requested in connection with this application.
                Buyer has the right of free choice in selecting an insurer to provide insurance required in connection with this
                transaction subject to our reasonable approval in accordance with applicable law.
            </p>

            <p>
                <strong>Tennessee Residents:</strong> You must have physical damage insurance covering loss or damage to the vehicle
                for the term of the contract. For a lease, you must also have the liability insurance as described in the lease. You
                may purchase required insurance through any insurance agent or broker and from any insurance company that is
                reasonably acceptable to us. You are not required to deal with any of our affiliates when choosing an agent, broker
                or insurer. Your choice of a particular insurance agent, broker or insurer will not affect our credit decision, so
                long as the insurance provides adequate coverage with an insurer who meets our reasonable requirements.
            </p>

            <p>
                <strong>Vermont Residents:</strong> You authorize us and any financial institution with which this credit
                application is shared, and each of their respective employees or agents, to obtain and verify information about you
                (including one or more credit reports, information about your employment and banking and credit relationships) that
                they may deem necessary or appropriate in evaluating your credit application. If your credit application is approved
                and credit is granted, you also authorize the parties granting credit or holding your account, and their respective
                employees and agents, to obtain additional credit reports and other information about you in connection with
                reviewing the account, increasing the available credit on the account (if applicable), taking collection on the
                account, or for any other legitimate purpose.
            </p>

            <p>
                <strong>Wisconsin Married Residents:</strong> No provision of any marital property agreement, any unilateral
                statement under Wis. Stat § 766.59 or any court decree under § 766.70 applied to marital property adversely affects
                our interest unless you furnish a copy of the agreement, statement, or court decree or we have actual knowledge of
                such adverse provision before credit is granted. If you are making this credit application individually and not
                jointly with your spouse, complete Section A about yourself and Section B about your non-applicant spouse. Your
                non-applicant spouse should not sign the credit application if you are applying for individual credit.
            </p>

            <p>
                By clicking on "Submit", I acknowledge and declare that I have read and agree with the Terms and Conditions. I
                certify that I have provided complete and true information in this application.
            </p>
        </>
    );
};
