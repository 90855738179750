import { CreditDecisionDetails } from '../api/creditDecisionApi';
import { AdobeStandaloneTag } from '../types/AdobeStandaloneTags';
import { AdobeTag } from '../types/AdobeTags';
import { ADOBE_DATA_LAYER_NAME, PrequalEventNamesType, PrequalStatusType } from '../types/Constants';
import { isLocal } from './helper';
import { getUrlVars } from './urlParameters';

interface ShopperContactInfo {
    firstName: string;
    middleName: string;
    lastName: string;
    phone: string;
    email: string;
}
interface FinalResults {
    shopper: ShopperContactInfo;
    creditAppId: string;
    dealRefId?: string; // TODO: clan up dealRefId
    fsDealRefId?: string;
}

interface UCAEvent extends Partial<FinalResults> {
    dealXgId?: string;
    dealXGVersionId?: string;
}

export interface PrequalEventData {
    SourcePartnerID: string;
    SourcePartnerDealerID: string;
    status: PrequalStatusType;
    message: string;
}

export const sendParentMessage = (context: string, message: any) => {
    if (window.parent) {
        const msg = {
            sourceId: 'CREDIT_APP',
            targetId: '',
            context,
            message
        };
        window.parent.postMessage(msg, '*');
    }
};

export const sendLead = (shopper: ShopperContactInfo) => {
    sendParentMessage('PERSONAL_INFO_PAGE', { shopper });
};

export const sendLeadAndCreditApp = (results: FinalResults) => {
    sendParentMessage('CONFIRMATION_PAGE', {
        shopper: results.shopper,
        creditAppId: results.creditAppId,
        dealRefId: results.dealRefId, // TODO: clan up dealRefId
        fsDealRefId: results.fsDealRefId
    });
};

export const sendCreditAppId = (creditAppReferenceId: string) => {
    sendParentMessage('CREDIT_APP_REFERENCE_ID_CREATED', {
        creditAppReferenceId
    });
};

export const sendAccelerateCreditAppId = (accelerateCreditAppId: string) => {
    console.log('Dispatching ACCELERATE_CREDIT_APP_ID_CREATED...', accelerateCreditAppId);
    sendParentMessage('ACCELERATE_CREDIT_APP_ID_CREATED', {
        accelerateCreditAppId
    });
};

export const sendAccelerateInfoRecordCreateStatus = () => {
    console.log('Dispatching CREDIT_APP_BEGIN_CREATING_INFO_RECORD');
    sendParentMessage('CREDIT_APP_BEGIN_CREATING_INFO_RECORD', {});
};

export const sendAccelerateInfoRecordUpdateStatus = () => {
    console.log('Dispatching CREDIT_APP_BEGIN_UPDATING_INFO_RECORD');
    sendParentMessage('CREDIT_APP_BEGIN_UPDATING_INFO_RECORD', {});
};

export const sendAccelerateCreditAppCoApplicantEmail = (accelerateCreditAppCoApplicantEmail: string) => {
    sendParentMessage('ACCELERATE_CREDIT_APP_CO_APPLICANT_EMAIL', {
        accelerateCreditAppCoApplicantEmail
    });
};

export const sendCreditAppFailure = () => {
    sendParentMessage('ERROR_PAGE', {});
};

export const sendCreditAppThemeFailure = () => {
    sendParentMessage(
        'CREDIT_APP_GET_THEME_FAILED',
        'The request to load theme from configuration failed. The default theme will be applied from local assets.'
    );
};

export const sendAdobeEvents = (adobeTag: AdobeTag) => {
    sendParentMessage('CREDIT_APP_SHELL_ADOBE_EVENTS', adobeTag);
};

export const sendCreditDecisionsAvailable = (creditDecisionDetails?: CreditDecisionDetails) => {
    console.log('Dispatching CREDIT_DECISIONS_AVAILABLE...');
    sendParentMessage('CREDIT_DECISIONS_AVAILABLE', creditDecisionDetails ?? {});
};

export const sendCreditDecisionsPending = () => {
    console.log('Dispatching CREDIT_DECISIONS_UNAVAILABLE...');
    sendParentMessage('CREDIT_DECISIONS_UNAVAILABLE', 'Fetch iterations exceeded the maximum and returned no decision results.');
};

export const sendDecisionPinGenerated = () => {
    console.log('Dispatching CREDIT_DECISION_PIN_REQUESTED');
    sendParentMessage('CREDIT_DECISION_PIN_REQUESTED', '');
};

export const sendDecisionPinValidated = () => {
    console.log('Dispatching CREDIT_DECISION_PIN_VALIDATED...');
    sendParentMessage('CREDIT_DECISION_PIN_VALIDATED', '');
};

export const sendAppHeightUpdate = (height: number) => {
    console.log('Dispatching CREDIT_APP_HEIGHT_CHANGE...', height);
    sendParentMessage('CREDIT_APP_HEIGHT_CHANGE', { height });
};

export const sendDecisionHeightUpdate = (height: number) => {
    console.log('Dispatching CREDIT_DECISION_HEIGHT_CHANGE...', height);
    sendParentMessage('CREDIT_DECISION_HEIGHT_CHANGE', { height });
};

export const initPixall = () => {
    (window as any)._pxam?.push({
        type: 'meta-data',
        pageType: 'other',
        pageId: 'standaloneCreditApp',
        dealerCode: 'standaloneCreditApp',
        program: 'DealerDotCom',
        wspCode: 'DealerDotCom',
        applicationType: 'credit'
    });
};

export const triggerSCAanalytics = (scaDataLayer: any, name: any) => {
    window[name] = { ...(scaDataLayer as any) };
    if (!isLocal) {
        (window as any)._satellite?.track(scaDataLayer.actions.type);
    }
};

export const constructSCADataLayer = (standAloneAdobeTag: AdobeStandaloneTag, choice?: string) => {
    // (window as any)._pxam?.push({
    // 	type: 'event',
    // 	eventType: standAloneAdobeTag.engagement,
    // 	eventSource: 'calculator',
    // 	feature: standAloneAdobeTag.tag,
    // 	label: standAloneAdobeTag.desc
    // });

    let desc;
    if (choice === undefined || choice === '') {
        desc = standAloneAdobeTag?.desc;
    } else {
        desc = choice;
    }

    const urlParams = getUrlVars();

    const scaDataLayer = {
        page: {
            dealerId: urlParams.dealerId && urlParams.dealerId,
            sponsor: urlParams.sponsor && urlParams.sponsor
        },
        actions: {
            description: desc,
            type: standAloneAdobeTag?.tag
        }
    };
    triggerSCAanalytics(scaDataLayer, ADOBE_DATA_LAYER_NAME);
};
export function sendCreditAppReferenceId(mockShadowCreditAppId: any) {
    throw new Error('Function not implemented.');
}

export const sendUCACreditAppSubmittedEvent = (UCAEvent: UCAEvent) => {
    sendParentMessage('UCACreditAppSubmittedEvent', {
        creditAppId: UCAEvent.creditAppId,
        dealRefId: UCAEvent.dealRefId,
        dealXgId: UCAEvent.dealXgId,
        dealXGVersionId: UCAEvent.dealXGVersionId || ''
    });
};

export const sendPreQualDataEvent = (eventName: PrequalEventNamesType, prequalEventData: PrequalEventData) => {
    sendParentMessage(eventName, prequalEventData);
};
