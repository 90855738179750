import { Validator } from '../../../formValidator';
import { Clonable } from '../../../utils/Immer.class';
import { InfoCardData } from '../../Interfaces';
import { AdditionalIncome } from './AdditionalIncome.model';
import { EmploymentStatus } from './employmentStatus/EmploymentStatus';
import { EmploymentStatusFactory } from './employmentStatus/Factory';

export class Employment extends Validator implements Clonable<Employment> {
    employmentStatus: EmploymentStatus;
    additionalIncome: Array<AdditionalIncome>;

    constructor(me?: Partial<Employment>) {
        super();
        this.employmentStatus = me?.employmentStatus ?? EmploymentStatusFactory.Build();
        this.additionalIncome = me?.additionalIncome ?? [new AdditionalIncome()];
    }
    clone(): Employment {
        return new Employment({ ...this });
    }

    reviewAdapter = (): InfoCardData => {
        const employeeInfoCardData = this.employmentStatus.reviewAdapter();
        return {
            ...employeeInfoCardData,
            monthlyIncome: {
                label: 'Total Monthly Income',
                value: `$${(
                    Number((employeeInfoCardData?.monthlyIncome?.value ?? '0').replace('$', '')) +
                    AdditionalIncome.getAdditionalMonthlyIncome(this.additionalIncome)
                ).toString()}`
            }
        };
    };
}
