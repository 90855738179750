import './App.css';
import Axios from 'axios';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import { Spinner } from '@makemydeal/ui-bricks/dist/cox';

import AppRouter from './components/AppRouter';
import { SpinnerContainer } from './components/shared.styled';
import { CreditAppProvider } from './contexts/CreditAppContext';
import { useCreditAppExperience } from './contexts/CreditAppExperienceContext';
import { ExternalResourceProvider } from './contexts/ExternalResourceContext';
import { CreditAppState } from './CreditApp.model';
import IFramedApp from './IFramedApp';
import { CreditAppServerResponse } from './types/CreditAppServerResponse';
import { InitData } from './types/ExternalResources';
import { sendAppHeightUpdate, sendCreditAppThemeFailure } from './utils/eventUtils';
import { emptyString, getClientConfig, isEmptyObject } from './utils/helper';
import { ORIGIN_URLS } from './utils/originUrls';
import { preFillCreditApp } from './utils/preFillState';
import { getUrlVars } from './utils/urlParameters';

interface CreditAppInstance {
    initUrl: string;
    isEmbedded: boolean;
    themePath: string;
    fontPath: string;
    [x: string]: any;
}

enum InitStatusEnum {
    Idle = 'Idle',
    Loading = 'Loading',
    Done = 'Done',
    Failure = 'Failure'
}

const App: React.FC = () => {
    const [initData, setInitData] = React.useState<Partial<InitData>>({});
    const [theme, setTheme] = React.useState({});
    const [initStatus, setInitStatus] = React.useState(InitStatusEnum.Idle);
    const [themeLoading, setThemeLoading] = React.useState(false);
    const [fontsLoading, setFontsLoading] = React.useState(false);
    const [dealXgId, setDealXgId] = React.useState('');
    const [sourcePartnerId, setSourcePartnerId] = React.useState('');
    const [sourcePartnerDealerId, setSourcePartnerDealerId] = React.useState('');
    const [creditAppInstance, setCreditAppInstance] = React.useState<CreditAppInstance>({} as CreditAppInstance);
    const { setExperienceData, isStandaloneExperience } = useCreditAppExperience();
    const urlParams = getUrlVars();
    const { themePath, fontPath } = creditAppInstance;
    const isStandaloneCreditAppEnabled = initData?.dealer?.isStandaloneCreditAppEnabled;

    const init = useCallback(async () => {
        if (urlParams.dealerId) {
            let CreditApp: CreditAppInstance;
            setInitStatus(InitStatusEnum.Loading);
            CreditApp = await getClientConfig(urlParams, initData);
            Axios.get<CreditAppServerResponse<InitData>>(CreditApp.initUrl)
                .then(async (result) => {
                    const initData = result.data.body;
                    CreditApp = await getClientConfig(urlParams, initData);

                    setInitData(initData);
                    setCreditAppInstance({ ...CreditApp, ...initData });
                    setExperienceData({ experience: initData.experience, dealerPhone: initData.dealer.phone });
                    setInitStatus(InitStatusEnum.Done);
                })
                .catch((error) => {
                    console.error('There was an error during credit app init', error);
                    setInitStatus(InitStatusEnum.Failure);
                });
        }

        if (!dealXgId) {
            setDealXgId(urlParams.dealXgId);
        }

        if (!sourcePartnerId) {
            if (urlParams.sourcePartnerId) {
                setSourcePartnerId(urlParams.sourcePartnerId);
            }
        }

        if (!sourcePartnerDealerId) {
            if (urlParams.sourcePartnerDealerId) {
                setSourcePartnerDealerId(urlParams.sourcePartnerDealerId);
            }
        }
    }, [urlParams, dealXgId, sourcePartnerId, sourcePartnerDealerId, initData, setExperienceData]);

    const handleDealXgIdUpdate = useCallback((e: any) => {
        if (e.data.targetId === 'CREDIT_APP' && e.data.message.dealXgId) {
            setDealXgId(e.data.message.dealXgId);
        }
    }, []);

    const getFallbackTheme = useCallback(() => {
        console.warn(
            "Something happened while loading the requested theme, or a theme parameter wasn't provided. Falling back to default."
        );
        import('./assets/theme-default.json').then((module) => {
            setTheme(module.default);
            setThemeLoading(false);
            setFontsLoading(false);
        });
    }, []);

    const getTheme = useCallback(() => {
        if (themeLoading || fontsLoading) {
            if (emptyString(urlParams.theme) && emptyString(initData?.dealer?.themeRedesign)) {
                getFallbackTheme();
            } else if (!isEmptyObject(creditAppInstance)) {
                Axios.get(themePath)
                    .then((result) => {
                        setTheme(result.data);
                        setFontsLoading(true);
                    })
                    .then(() => {
                        const link = document.createElement('link');
                        link.rel = 'stylesheet';
                        link.href = fontPath;
                        document.head.appendChild(link);
                        (document as any).fonts.ready.then(() => {
                            setFontsLoading(false);
                        });
                    })
                    .catch((error) => {
                        sendCreditAppThemeFailure();
                        getFallbackTheme();
                    });
            }
        }
    }, [creditAppInstance, fontPath, fontsLoading, getFallbackTheme, initData, themeLoading, themePath, urlParams.theme]);

    const getContentHeight = (): number => {
        const root = document.getElementById('root');
        if (!root) return 0;
        const app = root.getElementsByClassName('App')[0];
        if (!app) return 0;
        return app.scrollHeight || 0;
    };

    const creditAppInitState = new CreditAppState();
    const preFillState = preFillCreditApp(creditAppInitState);
    /**
     * useEffect:callInit
     * description: Invokes the Credit App Init lambda IF dealerId is present in the urlParams
     * update frequency: initial render, then changes to urlParams.dealerId
     */

    if (initStatus === InitStatusEnum.Failure) {
        // trigger Error page
        throw new Error('Credit App initialization failed');
    }

    if (initStatus === InitStatusEnum.Done && isStandaloneExperience && !isStandaloneCreditAppEnabled) {
        // trigger Error page
        throw new Error('Standalone Experience is not enabled');
    }

    useEffect(() => {
        // only call init lambda if we have the dealer
        // if (urlParams.dealerId) init();
        if (emptyString(urlParams.dealerId)) {
            setInitStatus(InitStatusEnum.Done);
            getFallbackTheme();
        }
        if (isEmptyObject(initData) && urlParams.dealerId && initStatus === InitStatusEnum.Idle) {
            init();
        }
    }, [getFallbackTheme, init, initData, initStatus, urlParams.dealerId]);

    /**
     * useEffect:getTheme
     * description: Fetches the external theme IF url.themePath is provided and is NOT an empty string
     * update frequency: initial render, then changes to themePath value
     */
    useEffect(() => {
        if (!isEmptyObject(initData) && isEmptyObject(theme)) {
            setThemeLoading(true);
            getTheme();
        } else {
            setThemeLoading(false);
        }
    }, [getFallbackTheme, getTheme, initData, theme, themeLoading, themePath]);

    /**
     * useEffect:sendAppHeightUpdate
     * description: checks to see if Credit App contains a vertical scrollbar, then auto-adjusts the height
     * update frequency: initial render
     */
    useEffect(() => {
        let currentHeight = getContentHeight();
        const checkHeight = () => {
            const contentHeight = getContentHeight();
            if (contentHeight !== currentHeight) {
                currentHeight = contentHeight;
                sendAppHeightUpdate(currentHeight);
            }
            setTimeout(checkHeight, 500);
        };
        checkHeight();
    }, []);

    useEffect(() => {
        window.addEventListener('message', handleDealXgIdUpdate, false);
        return () => {
            window.removeEventListener('message', handleDealXgIdUpdate, false);
        };
    }, [handleDealXgIdUpdate]);

    if (urlParams.iframed in ORIGIN_URLS) return <IFramedApp logger={console.info} urlParams={urlParams} />;
    if (themeLoading || fontsLoading || [InitStatusEnum.Idle, InitStatusEnum.Loading].includes(initStatus)) {
        return (
            <SpinnerContainer data-testid="landing-spinner">
                <ThemeProvider // at this point theme is still loading
                    theme={{
                        // in order to use Spinner we have to provide some of the required props
                        offerValueHeaderBackgroundColor: '#b0b0b0'
                    }}
                >
                    <Spinner size={60} />
                </ThemeProvider>
            </SpinnerContainer>
        );
    } else {
        return (
            <div className={classNames('App', { embedded: creditAppInstance.isEmbedded })}>
                <ThemeProvider theme={theme}>
                    <CreditAppProvider injectedState={preFillState}>
                        <ExternalResourceProvider injectedState={{ ...initData, dealXgId, sourcePartnerId, sourcePartnerDealerId }}>
                            <AppRouter />
                        </ExternalResourceProvider>
                    </CreditAppProvider>
                </ThemeProvider>
            </div>
        );
    }
};

export default App;
