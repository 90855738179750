import { CREDIT_DECISION_DATA, DecisionType } from '../types/Constants';

export type creditDecisionDataProps = {
    shopperEmail: string;
    shouldRenderEnhancedVersion: boolean;
    pageType: DecisionType;
};

export const getCreditDecisionData = ({ shouldRenderEnhancedVersion, shopperEmail, pageType }: creditDecisionDataProps) => {
    const base = CREDIT_DECISION_DATA[pageType];
    if (!shouldRenderEnhancedVersion) return base;

    const pageTitleDesc1 = `A confirmation email was sent to ${shopperEmail} with a reference code. 
Use this code to access your credit decision.`;
    const pageTitle = 'Application Submitted Successfully';

    return {
        ...base,
        pageTitleDesc1,
        pageTitle
    };
};
