import React from 'react';

import { PopUpLabel, SocialSecurityNumberInput } from '@makemydeal/ui-bricks/dist/cox';

import { ThemedInputWrapper } from '../components/shared.styled';
import { useWindowSize } from '../customHooks/UseWindowSize.hook';
import { FormField, Validator } from '../formValidator';
import { ChangeHandler } from '../formValidator/BaseFormField';
import { tabletWidth } from '../types/Constants';

interface SSNInputFormFieldProps {
    formField: FormField.Input;
    onChangeHandler: ChangeHandler;
}

const SSNInputFormField: React.FC<SSNInputFormFieldProps> = ({ formField, onChangeHandler }) => {
    const size = useWindowSize();
    const screenSize = size.width ?? 0;

    const isMobile = screenSize <= tabletWidth;

    return (
        <ThemedInputWrapper>
            <SocialSecurityNumberInput
                name={formField.name ?? ''}
                placeholder={formField.placeholder}
                errorMessage={Validator.getErrorMessage(formField)}
                label={formField.label ?? ''}
                sublabel="Your personal information is secure with us."
                labelCustomization={{
                    renderCustomComponent: () => {
                        return isMobile ? null : (
                            <PopUpLabel className="primary-link" label="Why do we need this?">
                                {formField.tooltip ?? ''}
                            </PopUpLabel>
                        );
                    }
                }}
                tooltip={isMobile ? formField.tooltip : undefined}
                optional={formField.optional}
                value={formField.value}
                hasError={formField.hasError}
                onChangeFunction={onChangeHandler}
                validationFunction={formField.validationFunction}
                maxlength={formField.maxlength ?? undefined}
            />
        </ThemedInputWrapper>
    );
};

export default SSNInputFormField;
