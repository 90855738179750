import { FetchCityStateZipInfo } from '../../customHooks/useFetchCityState.hook';
import { CustomValidator } from './CustomValidation';

export class ZipAndStateValidation extends CustomValidator {
    fieldMessage = {
        field: 'zip',
        message: 'Zip code not valid for state provided.'
    };

    constructor(private result?: FetchCityStateZipInfo, private state?: string) {
        super();
    }

    validate() {
        if (this.result && this.state) {
            return this.result.state === this.state;
        }

        // if we haven't got anything back yet, then return true
        // because the required check for state and zip will validate this.
        return false;
    }
}
