import { FormField } from '../../../../formValidator';
import { EmploymentStatusSelectOptions, states } from '../../../../utils/selectOptions';
import {
    validateAddress,
    validateCity,
    validateEmployerName,
    validateEmploymentStatus,
    validateIncome,
    validateMonth,
    validateOccupation,
    validatePhoneNumber,
    validateState,
    validateSuiteApartmentNumber,
    validateYear,
    validateZipCode
} from '../../../../utils/Validation';

const newStatus = (value: string) =>
    new FormField.Select({
        name: 'status',
        placeholder: 'Select',
        label: 'Employment Status',
        'data-testid': 'employment-status-select',
        optional: false,
        selectOptions: EmploymentStatusSelectOptions,
        errorMessage: '',
        value,
        validationFunction: validateEmploymentStatus
    });
const newEmployerName = () =>
    new FormField.Input({
        name: 'employerName',
        placeholder: "Employer's Name",
        label: "Employer's Name",
        optional: false,
        errorMessage: 'Employer Name must be longer than 2 Characters and only 30 allowed',
        validationFunction: validateEmployerName,
        maxlength: 30,
        tooltip: 'The maximum length for this field is 30 characters'
    });

const newStudentEmployerName = () =>
    new FormField.Input({
        name: 'employerName',
        placeholder: "School's Name",
        label: 'School’s Name',
        optional: false,
        tooltip:
            // eslint-disable-next-line max-len
            'We require proof of enrollment in an accredited educational institution. The maximum length for this field is 30 characters',
        errorMessage: 'Employer Name must be longer than 2 Characters and only 30 allowed',
        validationFunction: validateEmployerName,
        maxlength: 30
    });

const newMonthlyIncome = () =>
    new FormField.Input({
        name: 'monthlyIncome',
        placeholder: 'e.g. $8000',
        label: 'Monthly Gross Income',
        optional: false,
        tooltip: 'Gross Income is your total monthly income before taxes and deductions.',
        errorMessage: 'Income amount cannot be less than zero or more than six numbers',
        validationFunction: validateIncome
    });

const newStudentMonthlyIncome = () =>
    new FormField.Input({
        name: 'monthlyIncome',
        placeholder: 'e.g. $8000',
        label: 'Monthly Gross Income',
        optional: true,
        tooltip: 'Gross Income is your total monthly income before taxes and deductions.”',
        errorMessage: 'Income amount cannot be less than zero or more than six numbers',
        validationFunction: validateIncome
    });
const newSchoolAddress = () =>
    new FormField.Input({
        name: 'schoolAddress',
        placeholder: 'e.g. 1800 Main Street',
        label: "School's Street Address",
        optional: true,
        errorMessage:
            // eslint-disable-next-line max-len
            'Street Address must contain a street number and a street name that is no longer than 6 numbers and 20 characters.',
        validationFunction: validateAddress,
        maxlength: 60,
        tooltip: 'The maximum length for this field is 60 characters'
    });
const newEmployerAddress = () =>
    new FormField.Input({
        name: 'employerAddress',
        placeholder: 'e.g. 1800 Main Street',
        label: "Employer's Street Address",
        optional: true,
        errorMessage:
            // eslint-disable-next-line max-len
            'Street Address must contain a street number and a street name that is no longer than 6 numbers and 20 characters.',
        validationFunction: validateAddress,
        maxlength: 60,
        tooltip: 'The maximum length for this field is 60 characters'
    });
const newSuiteNumber = () =>
    new FormField.Input({
        name: 'suiteNumber',
        placeholder: 'e.g. Suite 101',
        label: 'Suite Number',
        optional: true,
        errorMessage:
            'Suite/ Apartment Number must contain a suite/apartment number that is no longer than 5 numbers and 5 characters.',
        validationFunction: validateSuiteApartmentNumber,
        maxlength: 60,
        tooltip:
            // eslint-disable-next-line max-len
            'The maximum length for this field is 60 characters. Suite number maximum length is 5.'
    });
const newCity = () =>
    new FormField.Input({
        name: 'city',
        placeholder: 'e.g. Atlanta',
        label: 'City',
        optional: true,
        errorMessage: 'Must be a valid city name',
        validationFunction: validateCity,
        maxlength: 20,
        tooltip: 'The maximum length for this field is 20 characters'
    });
const newState = () =>
    new FormField.Select({
        name: 'state',
        placeholder: 'State',
        label: 'State',
        optional: true,
        selectOptions: states,
        errorMessage: 'Please select a state',
        validationFunction: validateState
    });
const newZip = () =>
    new FormField.Input({
        name: 'zip',
        placeholder: 'e.g. 30339',
        label: 'Zip',
        optional: true,
        errorMessage: 'Enter a valid 5 digit zip code',
        validationFunction: validateZipCode,
        maxlength: 5
    });
const newPhone = () =>
    new FormField.Phone({
        name: 'phone',
        placeholder: '(555) 555-555',
        label: "Employer's Phone Number",
        optional: false,
        validationFunction: validatePhoneNumber,
        errorMessage: 'Must be a valid Phone Number.',
        maxlength: 14
    });
const newStudentPhone = () =>
    new FormField.Phone({
        name: 'phone',
        placeholder: '(555) 555-555',
        label: "School's Phone Number",
        optional: false,
        validationFunction: validatePhoneNumber,
        errorMessage: 'Must be a valid Phone Number.',
        maxlength: 14
    });
const newYearsEmployed = () =>
    new FormField.Input({
        name: 'yearsEmployed',
        placeholder: 'Years',
        label: '',
        customRequiredLabel: 'Required. Enter an amount from 0-99.',
        errorMessage: 'Enter an amount from 0 to 99.',
        maxlength: 2,
        optional: false,
        validationFunction: (value) => validateYear(value)
    });

const newMonthsEmployed = () =>
    new FormField.Input({
        name: 'monthsEmployed',
        placeholder: 'Months',
        label: '',
        customRequiredLabel: 'Required. Enter an amount from 0-11.',
        errorMessage: 'Enter an amount from 0 to 11.',
        maxlength: 2,
        optional: false,
        validationFunction: (value) => validateMonth(value)
    });

const newOccupation = () =>
    new FormField.Input({
        name: 'occupation',
        placeholder: 'e.g. Engineer',
        label: 'Occupation',
        optional: false,
        errorMessage:
            // eslint-disable-next-line max-len
            'Enter your primary role with the employer',
        validationFunction: validateOccupation,
        maxlength: 27,
        tooltip: 'Your Job Title or Role at your Employer'
    });

export {
    newStatus,
    newEmployerName,
    newStudentEmployerName,
    newMonthlyIncome,
    newStudentMonthlyIncome,
    newPhone,
    newStudentPhone,
    newYearsEmployed,
    newMonthsEmployed,
    newSchoolAddress,
    newEmployerAddress,
    newSuiteNumber,
    newCity,
    newState,
    newZip,
    newOccupation
};
