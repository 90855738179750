export interface Clonable<T> {
    clone(): T;
}
/**
 * checks if the argument has implemented Clonable interface
 * @param arg
 */
const canClone = (arg: any): arg is Clonable<any> => {
    return arg && (arg as Clonable<any>).clone !== undefined;
};

class ImmerClass {
    /**
     * The `produce` function takes a value and a "recipe function" (whose
     * return value often depends on the base state). The recipe function is
     * free to mutate its first argument however it wants. All mutations are
     * only ever applied to a __copy__ of the base state.
     *
     * Pass only a function to create a "curried producer" which relieves you
     * from passing the recipe function every time.
     *
     * Only plain objects and arrays are made mutable. All other objects are
     * considered non-mutable.
     *
     * Note: This function is __bound__ to its `ImmerClass` instance.
     *
     * @param {any} model - the initial state
     * @param {Function} producer - function that receives a proxy of the base state as
     * first argument and which can be freely modified
     * @returns {any} a new state, or the initial state if nothing was modified
     */
    produce<T>(model: T, producer?: (draft: T) => void): T {
        let clonedModel: any = model;

        if (canClone(model)) {
            clonedModel = (model as any).clone();
        }

        Object.entries(clonedModel).forEach((entry) => {
            const prop: any = entry[0];
            const modelValue = entry[1];
            if (modelValue instanceof Array) {
                clonedModel[prop] = modelValue.map((m) => produce(m));
            } else {
                if (canClone(modelValue)) {
                    clonedModel[prop] = produce(modelValue);
                }
            }
        });

        if (producer) producer(clonedModel);
        return clonedModel as T;
    }
}

const immerClass = new ImmerClass();
const produce = immerClass.produce;

export { produce };
