import React, { useState } from 'react';

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CollapsibleLabel, Content, DropDownPageContainer, StyledText } from './CreditDecisionCollapsible.styled';

interface CreditDecisionCollapsibleProps {
    dealerName: string;
}

export const CreditDecisionCollapsible: React.FC<CreditDecisionCollapsibleProps> = ({ dealerName }) => {
    const [isOpen, setIsOpen] = useState(false);

    const creditDecisionText =
        // eslint-disable-next-line max-len
        `${dealerName} will attempt to provide a decision in 60 seconds or less. Sometimes system availability affects the ability to make a credit decision or, in some cases, the lender needs to collect additional information. If so, it could take several hours to process your application and you wil receive further details via email.`;

    return (
        <DropDownPageContainer>
            <StyledText onClick={() => setIsOpen(!isOpen)}>
                <CollapsibleLabel>What is Instant Credit Decision?</CollapsibleLabel>
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </StyledText>
            {isOpen && <Content>{creditDecisionText}</Content>}
        </DropDownPageContainer>
    );
};
