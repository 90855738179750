import { Dispatch } from 'react';

import { ChangeHandler, SelectFieldChangeHandler } from '../../formValidator/BaseFormField';
import { allowOnlyNumbers } from '../../utils/helper';
import { Dispatcher } from '../Dispatcher';
import { MonthActionCreator, YearActionCreator } from './ActionCreators';
import { CopyAddressActionCreator } from './ActionCreators/CopyAddressActionCreator';
import { UpdateOwnershipActionCreator } from './ActionCreators/UpdateOwnershipActionCreator';
import { ZipActionCreator } from './ActionCreators/ZipActionCreator';
import { Housing } from './models/Housing.model';

export class HousingDispatcher extends Dispatcher<Housing> {
    /** Injecting Primary Applicant as part of the constructor using Inversion Of Control / Dependency Injection.
     *  This give this dispatcher a reference to the primary applicant information.
     */
    primaryApplicant?: Housing;
    constructor(dispatch: Dispatch<Housing>, primaryApplicant?: Housing) {
        super(dispatch);
        this.primaryApplicant = primaryApplicant;
    }
    /**
     * renderHousingHiddenFieldsYear and renderHousingHiddenFieldsMonths follow the same pattern.
     * They help render the hidden fields based on the time (months) a user has live in a location.
     *
     * ex: if combined time at residence is less than 24 months the the hidden fields will render. The variable 'optionValue'
     * keeps track of that.
     */
    renderHousingHiddenFieldsYear: ChangeHandler = (event) => {
        const fieldValue = allowOnlyNumbers(event.currentTarget.value);
        const actionCreator = new YearActionCreator(fieldValue);
        this.dispatch(actionCreator.getAction());
    };

    renderHousingHiddenFieldsMonths: ChangeHandler = (event) => {
        const fieldValue = allowOnlyNumbers(event.currentTarget.value);
        const actionCreator = new MonthActionCreator(fieldValue);
        this.dispatch(actionCreator.getAction());
    };

    updateCheckboxValue: ChangeHandler = (event) => {
        const fieldValue = event.currentTarget.checked.toString();
        const actionCreator = new CopyAddressActionCreator(fieldValue, this.primaryApplicant);
        this.dispatch(actionCreator.getAction());
    };

    updateOwnershipOptionStatus: SelectFieldChangeHandler = (event) => {
        const fieldValue = event.currentTarget.value;
        const actionCreator = new UpdateOwnershipActionCreator(fieldValue);
        this.dispatch(actionCreator.getAction());
    };
    updateZip: ChangeHandler = (event) => {
        const fieldValue = event.currentTarget.value;
        const actionCreator = new ZipActionCreator(fieldValue);
        this.dispatch(actionCreator.getAction());
    };
}
