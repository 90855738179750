export const states = {
    Alabama: 'AL',
    Alaska: 'AK',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    'District Of Columbia': 'DC',
    Florida: 'FL',
    Georgia: 'GA',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Pennsylvania: 'PA',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY'
};

export const OwnershipTypes = {
    'Own Outright': 'OwnOutright',
    Mortgage: 'Mortgage',
    Rent: 'Rent',
    Family: 'Family',
    Military: 'Military',
    Other: 'Other'
};

export const RelationshipTypes = {
    Spouse: 'Spouse',
    Relative: 'Relative',
    Other: 'Other'
};

export const sourceOfIncomeSelectOptions = {
    Alimony: 'Alimony',
    'Child Support': 'Child Support',
    'Rental Property': 'Rental Property',
    Investment: 'Investment',
    Other: 'Other'
};

export const EmploymentStatusSelectOptions = {
    Employed: 'Employed',
    Unemployed: 'Unemployed',
    'Self-Employed': 'SelfEmployed',
    Retired: 'Retired',
    'Retired Military': 'RetiredMilitary',
    'Active Military': 'ActiveMilitary',
    Student: 'Student',
    Other: 'Other'
};

export const MaritalStatusTypes = {
    Married: 'Married',
    Unmarried: 'Unmarried'
};
