import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Housing } from '../models/Housing.model';

export class UpdateAddressInfoActionCreator extends ActionCreator<Housing> {
    constructor(fieldValue: string, private fieldName: string, private addressOrPrevAddress: 'address' | 'previousAddress') {
        super(fieldValue);
    }
    mutateField = (state: Housing) => {
        return produce(state, (draft) => {
            (draft as any)[this.addressOrPrevAddress][this.fieldName].setValue(this.fieldValue);
        });
    };
    public getAction = () => (prevState: Housing) => this.mutateField(prevState);
}
