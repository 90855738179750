import React from 'react';

import { useCreditAppExperience } from '../contexts/CreditAppExperienceContext';
import { AdobeStandaloneTag } from '../types/AdobeStandaloneTags';
import { constructSCADataLayer, sendAdobeEvents } from '../utils/eventUtils';
import { ADOBE_TAGS, AdobeTag } from './../types/AdobeTags';

export const useAdobeTag = (adobeTag?: AdobeTag, standAloneAdobeTag?: AdobeStandaloneTag, url?: string, sessionId?: string) => {
    const experience = useCreditAppExperience();
    React.useEffect(() => {
        if (experience.isStandaloneExperience) {
            if (standAloneAdobeTag) constructSCADataLayer(standAloneAdobeTag);
        } else {
            if (url !== '/' && sessionId && adobeTag !== ADOBE_TAGS.DR_CREDIT_APP_DISPLAYED) {
                if (adobeTag) sendAdobeEvents(adobeTag);
            }
        }
        // We don't want to display DR_CREDIT_APP_DISPLAYED tag in this repo bc we are displaying it in dr-credit-activities repo
    }, [adobeTag, standAloneAdobeTag, sessionId, url, experience]);
};
