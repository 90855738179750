import { rem } from 'polished';
import styled from 'styled-components';

export const ConfirmationDecisionCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1vh;
    padding-bottom: 1vh;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 7px;
`;

export const ConfirmationDecisionCardInfo = styled.div`
    flex-direction: column;
    justify-content: space-between;
`;

export const ConfirmationTitleHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: auto;
    height: ${rem(50)};
    border-radius: 7px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 17px 0px 16px 11px;
    .credit-decision-confirm-header-icon {
        align-self: center;
        margin: 0 2vw 0 2vw;
    }

    .credit-decision-confirm-header-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .credit-decision-card-title {
            font-size: ${rem(16)};
            color: rgba(0, 0, 0, 0.7);
        }
        .credit-decision-card-status {
            font-size: ${rem(22)};
            font-weight: 600;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.9);
        }
    }
    .credit-decision-confirm-header-lender {
        align-self: center;
        margin-left: auto;
        .credit-decision-confirm-header-lender-logo {
            margin: 0 2vw 0 0;
        }
    }
`;

export const ConfirmationMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin: 16px 22px 0px 22px;
    .credit-decision-message-dealer {
        font-size: ${rem(18)};
        font-weight: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.8);
    }
    .credit-decision-message-disclosure {
        line-height: 1.4;
        color: rgba(0, 0, 0, 0.8);
        margin: 0px 0px 24px 0;
        font-size: ${rem(14)};
    }
    .credit-decision-dealer-phone {
        color: #3c7fc8;
        font-size: ${rem(18)} !important;
    }
    .text1 {
        font-size: ${rem(16)};
    }
    .tac-header {
        color: rgba(0, 0, 0, 0.9);
        font-size: ${rem(16)};
    }

    .title-dealer-comments {
        margin-bottom: 0.5rem;
    }

    .credit-decision-message-disclosure .title-dealer-comments {
        font-weight: 600;
    }

    .majorContainer {
        gap: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;
    }
    .minorContainer {
        gap: 0.5rem;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .minorContainer span {
        text-transform: capitalize;
    }
    .minorContainer .title-lender-card-items {
        color: #6f747c;
    }

    @media only screen and (max-width: 600px) {
        .majorContainer {
            flex-direction: column;
            column-gap: 0.5rem;
        }
        .minorContainer {
            flex-direction: row;
            justify-content: inherit;
        }
    }
`;

export const ConfirmationSubHeader = styled.div`
    font-size: ${rem(18)};
    font-weight: 490;
    margin: 0 22px 12px 22px;
`;

export const LoanInfo = styled.div`
    margin: 0 22px 15px 22px;
    [class*='-wrapper'] {
        display: flex;
        flex-direction: row;
        line-height: 1.5;
    }
    [class*='-label'] {
        font-weight: 400;
    }
    [class*='-value'] {
        font-weight: 500;
        padding-left: 8px;
    }
`;

export const TACContent = styled.div`
    min-height: ${rem(95)};
    color: rgba(0, 0, 0, 0.6);
    font-size: ${rem(12)};
    font-weight: 550;
    width: 90%;
    overflow-y: hidden;
    @media only screen and (max-width: 600px) {
        height: ${({ className }) => (className === 'tac-content-expanded' ? '150px' : '50px')};
        -webkit-mask-image: ${({ className }) =>
            className === 'tac-content-expanded' ? 'null' : 'linear-gradient(to bottom, black 60%, transparent 100%)'};
        mask-image: ${({ className }) =>
            className === 'tac-content-expanded' ? 'null' : 'linear-gradient(to bottom, black 60%, transparent 100%)'};
        transition: height 0.25s linear;
    }
`;

export const DecisionTACContent = styled.div`
    font-size: ${rem(12)};
    line-height: 1.5;
`;

export const DecisionTACHeader = styled.div`
    font-size: ${rem(22)};
    margin-bottom: 8px;
    font-weight: 600;
`;
export const ReadMore = styled.button`
    text-align: center;
    background: transparent;
    cursor: pointer;
    border: unset;
    padding: unset;
    outline: none;
    color: #3c7fc8;
    font-weight: 600;
`;

export const ReadMoreWrapper = styled.div`
    display: none;

    @media only screen and (max-width: 600px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
