import { rem } from 'polished';
import styled from 'styled-components';

export const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #e4e5e6;
    box-shadow: 3px 5px 12px 0px rgba(0, 0, 0, 0.22);
    border-radius: ${rem(5)};
    overflow: hidden;
`;

export const CardInner = styled.div`
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
`;

export const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    padding: ${rem(15)};
    gap: ${rem(15)};
    .credit-decision-confirm-header-icon {
        align-self: center;
    }

    .credit-decision-confirm-header-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .credit-decision-card-title {
            font-size: ${rem(18)};
            color: rgba(0, 0, 0, 0.7);
        }
        .credit-decision-card-status {
            font-size: ${rem(28)};
            font-weight: 600;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 0.9);
        }
    }
`;

export const CardMessage = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    padding: ${rem(15)};
    gap: ${rem(20)};
`;

export const MessageDisclosure = styled.div`
    font-size: ${rem(18)};
    line-height: ${rem(26)};
`;

export const DealerPhoneBold = styled.span`
    font-weight: 700;
    font-size: ${rem(18)} !important;
    line-height: ${rem(26)};
`;

export const DealerPhoneBlue = styled(DealerPhoneBold)`
    font-weight: 600;
    color: #0d65bf;
`;
