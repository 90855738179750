import { FormField } from '../../../formValidator';
import { Clonable } from '../../../utils/Immer.class';
import { sourceOfIncomeSelectOptions } from '../../../utils/selectOptions';
import { validateIncome } from '../../../utils/Validation';
import { Validator } from './../../../formValidator/Validator';

export class AdditionalIncome extends Validator implements Clonable<AdditionalIncome> {
    sourceOfIncome: FormField.Select;
    monthlyIncome: FormField.Input;

    constructor(me?: AdditionalIncome) {
        super();
        this.sourceOfIncome =
            me?.sourceOfIncome ??
            new FormField.Select({
                placeholder: 'Select',
                label: 'Additional Source of Income',
                tooltip: 'e.g.alimony, child support, investment income',
                optional: true,
                selectOptions: sourceOfIncomeSelectOptions,
                validationFunction: () => true
            });
        this.monthlyIncome =
            me?.monthlyIncome ??
            new FormField.Input({
                placeholder: 'e.g. $600',
                label: 'Additional Monthly Income',
                optional: true,
                validationFunction: validateIncome,
                errorMessage: 'Monthly Income is required.'
            });
    }
    clone(): AdditionalIncome {
        return new AdditionalIncome({ ...this });
    }

    static getAdditionalMonthlyIncome(additionalIncomes: AdditionalIncome[]): number {
        return additionalIncomes.reduce((acc, a) => acc + Number(a.monthlyIncome.value), 0);
    }

    static getAdditionalSourceOfIncome(additionalIncomes: AdditionalIncome[]): string | undefined {
        const length = additionalIncomes.length;
        if (length === 0) return;
        if (length === 1) return additionalIncomes[0].sourceOfIncome.value;
        return sourceOfIncomeSelectOptions.Other;
    }
}
