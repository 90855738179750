import React from 'react';

import { DecisionEmailModal } from './components/DecisionEmailModal';
import { DecisionPinAuthModal } from './components/DecisionPinAuthModal';
import ConfirmationPage from './ConfirmationPage';
import { ConfirmationPageWrapper } from './CreditDecisionLandingPage.styled';

const DealerDecisionPage = ({ data }: { data: any }) => {
    const {
        showPinModal,
        showPinErrorMessage,
        savePin,
        handleClosePinModal,
        saveEmail,
        showEmailModal,
        handleCloseEmailModal,
        dealerPhone,
        userIsInactive,
        offerType,
        confirmationPageToggles,
        status,
        dealerDecision,
        shopperEmail
    } = data;

    return (
        <ConfirmationPageWrapper>
            <DecisionPinAuthModal
                show={showPinModal}
                hasError={showPinErrorMessage}
                handleSubmit={savePin}
                handleClose={handleClosePinModal}
            />
            <DecisionEmailModal saveEmail={saveEmail} show={showEmailModal} handleClose={handleCloseEmailModal} />
            <ConfirmationPage
                pageType={status}
                className={'confirmation-approved'}
                dealerPhone={dealerPhone}
                showModal={userIsInactive}
                offerType={offerType}
                toggles={confirmationPageToggles}
                dealerDecision={dealerDecision}
                shopperEmail={shopperEmail}
            />
        </ConfirmationPageWrapper>
    );
};

export default DealerDecisionPage;
