import styled from 'styled-components';

import { CommonHeader } from '../../components/shared.styled';
import { devices } from '../../utils/deviceUtils';

export const PersonalInfoTextContainer = styled.div`
    background-color: ${({ theme }) => theme.creditAppLandingPageBodyContainerBackgroundColor};
    border-radius: ${({ theme }) => theme.creditAppLandingPageBodyContainerBorderRadius};
    margin-bottom: 1rem;
    @media ${devices.desktop && devices} {
        padding: 0.75rem 1rem;
    }
    @media ${devices.tablet && devices.tabletL} {
        padding: 1.5rem 2rem;
        flex: 0.5;
    }
`;

export const PersonalInfoTitleText = styled(CommonHeader)`
    font-size: 1rem;
    @media ${devices.tablet} {
        font-size: 1rem;
    }
    .personal-info-font-icon {
        color: rgba(0, 0, 0, 0.8);
        margin-right: 5px;
    }
`;

export const InfoItemText = styled.span`
    .info-item-text-one {
        margin-left: 2px;
        line-height: 1.81;
        font-weight: 700;
        font-size: 1rem;
        @media ${devices.mobile} {
            font-size: 0.9;
        }
        @media ${devices.tablet && devices.tabletL} {
            flex-direction: column;
        }
    }
    .info-item-text-two {
        margin-left: 2px;
        font-size: 1rem;
        line-height: 1.81;
        font-weight: 400;
        @media ${devices.mobile} {
            font-size: 0.9;
        }
        @media ${devices.tablet && devices.tabletL} {
            flex-direction: column;
        }
    }
`;
