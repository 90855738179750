import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Employment } from '../models/Employment.model';
import { Employed } from '../models/employmentStatus/Employed';
import { PreviousEmploymentFactory } from '../models/employmentStatus/previousStatus/Factory';

export class PreviousStatusActionCreator extends ActionCreator<Employment> {
    mutateField = (state: Employment) => {
        return produce(state, (draft) => {
            const employmentStatus = draft.employmentStatus as Employed;
            employmentStatus.previousEmployment = PreviousEmploymentFactory.Build(this.fieldValue);
        });
    };
    public getAction = () => (prevState: Employment) => this.mutateField(prevState);
}
