import { produce } from '../../utils/Immer.class';
import ActionCreator from './ActionCreator';

export class FieldValueActionCreator<T> extends ActionCreator<T> {
    constructor(private fieldName: string, fieldValue: string) {
        super(fieldValue);
    }
    mutateField = <T>(state: T) => {
        return produce(state, (draft: T) => {
            (draft as any)[this.fieldName].setValue(this.fieldValue);
        }) as T;
    };
    public getAction = () => (prevState: T) => this.mutateField(prevState);
}
