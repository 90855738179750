import React from 'react';

export const WisconsinMarriedDisclaimerContent = () => {
    return (
        <>
            <p>
                <br />
                as a married Wisconsin state resident, you must add your spouse as a co-applicant
            </p>
        </>
    );
};
