import { Clonable } from '../../../utils/Immer.class';
import { Employment } from './Employment.model';

export class EmploymentInfo implements Clonable<EmploymentInfo> {
    primaryApplicant: Employment;
    jointApplicant: Employment;

    constructor(me: Partial<EmploymentInfo> = {}) {
        this.primaryApplicant = me.primaryApplicant ?? new Employment();
        this.jointApplicant = me.jointApplicant ?? new Employment();
    }
    clone(): EmploymentInfo {
        return new EmploymentInfo({ ...this });
    }
}
