import { emptyString, isEmptyObject } from '../utils/helper';
import { BaseFormField } from './BaseFormField';

export class Validator {
    static getErrorMessage = (formField: BaseFormField): string | undefined => {
        if (!formField.optional && emptyString(formField.value)) {
            return formField.customRequiredLabel || `${formField.label} is required.`;
        }
        return formField.errorMessage;
    };
    static getError = (formField: BaseFormField): string | undefined => {
        if (emptyString(formField.value)) {
            if (formField.optional || formField.disabled) {
                return '';
            } else {
                return formField.customRequiredLabel || `${formField.label} is required.`;
            }
        } else {
            return formField.validationFunction(formField.value ?? '') ? '' : formField.errorMessage;
        }
    };

    /**
	 * get errors object for a model
	 * @param model 
	 *  The comment below is an overview of the static getErrors method. 
		if it happens that a partValue is an Array
		loop thro each element and validate
		assigns the errors for that part as an Array, if any error found.
			exp: employment :   {
								...
								additionalIncome: [
									{
										sourceOfIncome: <BaseFormField>
										monthlyIncome: <BaseFormField>
									}
								]	
							}
		errors would be:    {
								...
								additionalIncome: [
									{
										sourceOfIncome: 'sourceOfIncome is required.'
										monthlyIncome: 'monthlyIncome is required.'
									}
								]
							} 
		
		If partValue is an object it'll loop through each error and validate. 
		exp: housing :  {
							...
							previousAddress : {
								streetAddress: FormField.Input;
								city: FormField.Input;
								state: FormField.Select;
								zip: FormField.Input;
								suiteAptNumber: FormField.Input;
							}
								}
		errors would be:{
							previousAddress : {
								streetAddress: 'Address is required' ;
								city: 'city is required';
								state: 'State is required';
								zip: 'Zip is required';
								suiteAptNumber: 'Suite/ Apartment number is required';
							}
								}
	*/
    static getErrors<Model>(model: Model) {
        const errors: any = {};
        Object.entries(model).forEach((entry) => {
            // part is each model element
            const part = entry[0];
            const partValue = entry[1];
            // if partValue is type of a FormField
            if (partValue instanceof BaseFormField) {
                const error = this.getError(partValue);
                const hasError = !emptyString(error);
                if (hasError) {
                    errors[part] = error;
                }
                partValue.hasError = hasError;
            } else if (partValue instanceof Array) {
                const partValueErrorArray = partValue.reduce((acc, formFieldElement) => {
                    const errors = Validator.getErrors(formFieldElement);
                    if (!isEmptyObject(errors)) {
                        acc.push(errors);
                    }
                    return acc;
                }, []);
                if (partValueErrorArray.length > 0) {
                    errors[part] = partValueErrorArray;
                }
            } else if (partValue instanceof Object) {
                const partValueErrors = Validator.getErrors(partValue);
                if (!isEmptyObject(partValueErrors)) {
                    errors[part] = partValueErrors;
                }
            }
        });
        // this is recursive
        return errors;
    }
}
