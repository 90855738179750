import { Dispatch } from 'react';

import { ChangeHandler, SelectFieldChangeHandler } from '../formValidator/BaseFormField';
import { FieldValueActionCreator } from './ActionCreators';

export class Dispatcher<T> {
    protected dispatch: Dispatch<any>;
    constructor(dispatch: Dispatch<T>) {
        this.dispatch = dispatch;
    }

    updateInfo: ChangeHandler = (event) => {
        const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;
        const actionCreator = new FieldValueActionCreator<T>(fieldName, fieldValue);
        this.dispatch(actionCreator.getAction());
    };

    updateSelectField: SelectFieldChangeHandler = (event) => {
        const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;
        const actionCreator = new FieldValueActionCreator<T>(fieldName, fieldValue);
        this.dispatch(actionCreator.getAction());
    };

    updateCheckboxValue: ChangeHandler = (event) => {
        const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.checked.toString();
        const actionCreator = new FieldValueActionCreator<T>(fieldName, fieldValue);
        this.dispatch(actionCreator.getAction());
    };
}
