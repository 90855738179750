import { Dispatch } from 'react';

import { ChangeHandler } from '../../formValidator/BaseFormField';
import { Dispatcher } from '../Dispatcher';
import { CopySpouseAddressActionCreator, SetSpouseActionCreator, UpdateSpouseInfoActionCreator } from './ActionCreators';
import { Housing } from './models/Housing.model';

interface ModelChangeHandler {
    (event: string): void;
}

export class SpouseDispatcher extends Dispatcher<Housing> {
    primaryApplicant?: Housing;
    constructor(dispatch: Dispatch<Housing>, primaryApplicant?: Housing) {
        super(dispatch);
        this.primaryApplicant = primaryApplicant;
    }

    modifySpouseModel: ModelChangeHandler = (fieldValue: string) => {
        const actionCreator = new SetSpouseActionCreator(fieldValue);
        this.dispatch(actionCreator.getAction());
    };

    updateSpouseInfo = (
        event: React.SyntheticEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>,
        fieldNameOverride?: string
    ) => {
        const fieldName = fieldNameOverride || event.currentTarget.name;
        const fieldValue = event.currentTarget.value;
        const actionCreator = new UpdateSpouseInfoActionCreator(fieldValue, fieldName);
        this.dispatch(actionCreator.getAction());
    };

    updateSpouseAddressInfo = (event: React.SyntheticEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const fieldNameOverride = `address.${event.currentTarget.name}`;
        this.updateSpouseInfo(event, fieldNameOverride);
    };

    updateSpouseAddressSameAsPrimaryApplicant: ChangeHandler = (event) => {
        const fieldValue = event.currentTarget.checked.toString();
        const actionCreator = new CopySpouseAddressActionCreator(fieldValue, this.primaryApplicant);
        this.dispatch(actionCreator.getAction());
    };
}
