import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Address } from '../models/Address.model';
import { Housing } from '../models/Housing.model';

export class CopyAddressActionCreator extends ActionCreator<Housing> {
    constructor(fieldValue: string, private primaryApplicant?: Housing) {
        super(fieldValue);
    }
    mutateField = (state: Housing) => {
        return produce(state, (draft) => {
            if (this.primaryApplicant && this.fieldValue === 'true') {
                draft.address = Address.copy(this.primaryApplicant.address);
            } else {
                draft.address = new Address();
            }
            draft.addressSameAsPrimaryApplicant.setValue(this.fieldValue);
        });
    };
    public getAction = () => (prevState: Housing) => this.mutateField(prevState);
}
