export interface Validator {
    func: (...args: (string | undefined)[]) => boolean;
    message: string;
}

// use when you have multiple validators against multiple field(s) for a field
export const crossValidator = (validators: Validator[], ...args: (string | undefined)[]) =>
    validators.reduce((acc: string[], validator: Validator) => {
        if (!validator.func(...args)) return [...acc, validator.message];
        else return acc;
    }, []);
