import { EmploymentStatusSelectOptions } from '../../../../utils/selectOptions';
import { Employed } from './Employed';
import { EmploymentStatus } from './EmploymentStatus';
import { Other } from './Other';
import { Retired } from './Retired';
import { SelfEmployed } from './SelfEmployed';
import { Student } from './Student';
import { UnEmployed } from './UnEmployed';

export class EmploymentStatusFactory {
    static Build(statusValue?: string): EmploymentStatus {
        switch (statusValue) {
            case EmploymentStatusSelectOptions.Unemployed:
                return new UnEmployed();
            case EmploymentStatusSelectOptions.Retired:
            case EmploymentStatusSelectOptions['Retired Military']:
                return new Retired(statusValue);
            case EmploymentStatusSelectOptions.Student:
                return new Student();
            case EmploymentStatusSelectOptions.Other:
                return new Other();
            case EmploymentStatusSelectOptions['Self-Employed']:
                return new SelfEmployed();
            default:
                // Employed, ActiveMilitary
                return new Employed(statusValue);
        }
    }
}
