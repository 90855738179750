import React from 'react';
import { useHistory } from 'react-router-dom';

import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import { Row } from '../../components/shared.styled';
import { useExternalResourceState } from '../../contexts/ExternalResourceContext';
import { PAGES, URLPaths } from '../../types/Constants';
import { Resources } from '../../types/ExternalResources';
import { constructSCADataLayer, sendAdobeEvents } from '../../utils/eventUtils';
import { emptyString } from '../../utils/helper';
import { InfoCardData } from '../Interfaces';
import {
    ContentWrapper,
    EditIconContainer,
    Header,
    HeaderWrapper,
    InfoCardDetails,
    InfoCardLabel,
    StyledEditLink,
    StyledFontIcon
} from './InfoCard.styled';

/**
 * Pre-condition: This component takes in a single object, an action, and a header.
 * The data passed in as props should contain user dataFrom the form
 *
 * Post-condition: When the component renders it is only responsible for rendering the data it is given. Once it renders
 * the user will be able to navigate back the respective pages to edit the forms before submission. Each card will contain
 * header a location to the page that should be edited and the information from the page at that locations
 */
interface InfoCardProps {
    header: string;
    urlPath?: URLPaths;
    infoCardData: InfoCardData;
}

const InfoCard: React.FC<InfoCardProps> = ({ header, urlPath, infoCardData }) => {
    const history = useHistory();
    const experience = (useExternalResourceState() as Resources)?.dealer?.isStandaloneCreditAppEnabled;

    const edit = () => {
        if (urlPath) {
            if (experience) {
                const adobeStandaloneEditNavigation = PAGES[urlPath].adobeStandaloneEditNavigation;
                if (adobeStandaloneEditNavigation) constructSCADataLayer(adobeStandaloneEditNavigation);
            }
            const adobeEditNavigation = PAGES[urlPath].adobeEditNavigation;
            if (adobeEditNavigation) sendAdobeEvents(adobeEditNavigation);

            history.push(urlPath);
        }
    };

    return (
        <div>
            <HeaderWrapper>
                <Header className="common-header">{header}</Header>
                {urlPath && (
                    <EditIconContainer className="primary-link-container">
                        <StyledFontIcon className="primary-link-icon" size="1x" icon={faPencilAlt} />
                        <StyledEditLink className="primary-link" onClick={edit}>
                            Edit
                        </StyledEditLink>
                    </EditIconContainer>
                )}
            </HeaderWrapper>
            <ContentWrapper>
                {Object.values(infoCardData).map((entry, i) => {
                    return (
                        <div key={i} style={{ marginTop: 10 }}>
                            <Row>
                                <InfoCardLabel>
                                    {!emptyString(entry.label) && `${entry.label}${entry.label.endsWith('?') ? '' : ':'}`}
                                </InfoCardLabel>
                                <InfoCardDetails>
                                    <span data-testid={entry.label}>{entry.value}</span>
                                </InfoCardDetails>
                            </Row>
                        </div>
                    );
                })}
            </ContentWrapper>
        </div>
    );
};

export default InfoCard;
