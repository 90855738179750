import React from 'react';

import { Column, DisclaimerText, Row } from '../../components/shared.styled';
import { InputFormField, SelectFormField } from '../../formFields';
import { StyledLink } from './AdditionalIncomePart.styled';
import { EmploymentDispatcher } from './Employment.dispatcher';
import { AdditionalIncome } from './models/AdditionalIncome.model';

interface AdditionalIncomePartProps {
    additionalIncome: AdditionalIncome[];
    employmentDispatchers: EmploymentDispatcher;
    isIncomeValidationEnabled: boolean;
}
const additionalIncomeLinkTxt = `${String.fromCharCode(43)} Add Additional Income`;

const AdditionalIncomePart: React.FC<AdditionalIncomePartProps> = ({
    additionalIncome,
    employmentDispatchers,
    isIncomeValidationEnabled
}) => {
    if (isIncomeValidationEnabled) {
        additionalIncome.forEach((adtnlIncome) => {
            adtnlIncome.monthlyIncome.maxlength = 6;
            adtnlIncome.monthlyIncome.errorMessage = 'The value must be from $1 to $999,999';
        });
    }
    return (
        <>
            <DisclaimerText className="additionalIncomeDisclaimer">
                *Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it
                considered as a basis for repaying this obligation.
            </DisclaimerText>
            {additionalIncome.map((addIncome, index) => (
                <Row key={index}>
                    <Column>
                        <span className="align-field-mobile">
                            <SelectFormField
                                formField={{ ...addIncome.sourceOfIncome, name: `sourceOfIncome-${index}` }}
                                onChangeHandler={(event) => employmentDispatchers.updateAdditionalIncomeSelectHandler(event, index)}
                            />
                        </span>
                    </Column>

                    <Column>
                        <InputFormField
                            formField={{ ...addIncome.monthlyIncome, name: `monthlyIncome-${index}` }}
                            onChangeHandler={(event) => employmentDispatchers.updateAdditionalIncomeInputHandler(event, index)}
                            disabled={addIncome.sourceOfIncome?.value === ''}
                        />
                    </Column>
                </Row>
            ))}
            {additionalIncome.length < 5 && (
                <StyledLink className="primary-link" onClick={employmentDispatchers.additionalIncomeLinkHandler}>
                    {additionalIncomeLinkTxt}
                </StyledLink>
            )}
        </>
    );
};

export default AdditionalIncomePart;
