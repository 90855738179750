import React from 'react';

import { PhoneInput, PopUpLabel } from '@makemydeal/ui-bricks/dist/cox';

import { ThemedInputWrapper } from '../components/shared.styled';
import { useWindowSize } from '../customHooks/UseWindowSize.hook';
import { FormField, Validator } from '../formValidator';
import { ChangeHandler } from '../formValidator/BaseFormField';
import { tabletWidth } from '../types/Constants';
import { emptyString } from '../utils/helper';

interface PhoneInputFormFieldProps {
    formField: FormField.Phone;
    onChangeHandler: ChangeHandler;
}
const PhoneInputFormField: React.FC<PhoneInputFormFieldProps> = ({ formField, onChangeHandler }) => {
    const size = useWindowSize();
    const screenSize = size.width ?? 0;
    const isMobile = screenSize <= tabletWidth;

    return (
        <ThemedInputWrapper>
            <span className={'inputFormFieldTooltipModal'}>
                <PhoneInput
                    name={formField.name ?? ''}
                    placeholder={formField.placeholder}
                    errorMessage={Validator.getErrorMessage(formField)}
                    label={formField.label ?? ''}
                    labelCustomization={{
                        renderCustomComponent: () => {
                            return isMobile || emptyString(formField.tooltip ?? '') ? null : (
                                <PopUpLabel className="primary-link" label="View Disclaimer">
                                    {formField.tooltip ?? ''}
                                </PopUpLabel>
                            );
                        }
                    }}
                    tooltip={isMobile ? formField.tooltip : undefined}
                    optional={formField.optional}
                    value={formField.value}
                    hasError={formField.hasError}
                    onChangeFunction={onChangeHandler}
                    validationFunction={formField.validationFunction}
                    maxlength={formField.maxlength ?? undefined}
                />
            </span>
        </ThemedInputWrapper>
    );
};

export default PhoneInputFormField;
