import { communityPropertyZipCodes } from '../../../utils/communityPropertyZip';
import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Housing } from '../models/Housing.model';

export class ZipActionCreator extends ActionCreator<Housing> {
    constructor(fieldValue: string) {
        super(`${fieldValue}`);
    }
    mutateField = (state: Housing) => {
        return produce(state, (draft) => {
            if (communityPropertyZipCodes.includes(this.fieldValue)) {
                draft.address.zip.tooltip = `Please be aware that if you're under 19 and your application is approved,
             we'll require verification that if you are either married, widowed or have been honorably discharged from
             the U.S Military in order to qualify for credit.`;
            } else {
                draft.address.zip.tooltip = undefined;
            }
            draft.address.zip.setValue(this.fieldValue);
        });
    };
    public getAction = () => (prevState: Housing) => this.mutateField(prevState);
}
