import { KeyValuePair } from './../types/KeyValuePair';

export const ORIGIN_URLS: KeyValuePair = {
    qa: 'https://secure-qa.accelerate.dealer.com',
    uat: 'https://secure-uat.accelerate.dealer.com',
    dev: 'https://secure-dev.accelerate.dealer.com',
    dev2: 'https://secure-dev2.accelerate.dealer.com',
    local: 'http://localhost:3000',
    production: 'https://secure.accelerate.dealer.com'
};
