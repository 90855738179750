import { twoYearsOrLess } from '../../../utils/helper';
import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Employment } from '../models/Employment.model';
import { Employed } from '../models/employmentStatus/Employed';
import { PreviousEmploymentFactory } from '../models/employmentStatus/previousStatus/Factory';

export class YearsActionCreator extends ActionCreator<Employment> {
    mutateField = (state: Employment) => {
        return produce(state, (draft) => {
            const employmentStatus = draft.employmentStatus as Employed;
            const isLessThan2years = twoYearsOrLess(this.fieldValue, employmentStatus.monthsEmployed.value);
            if (isLessThan2years) {
                if (!employmentStatus.previousEmployment && employmentStatus.monthsEmployed.value !== '') {
                    employmentStatus.previousEmployment = PreviousEmploymentFactory.Build();
                }
            } else {
                employmentStatus.previousEmployment = undefined;
            }
            (draft.employmentStatus as Employed).yearsEmployed.setValue(this.fieldValue);
        });
    };
    public getAction = () => (prevState: Employment) => this.mutateField(prevState);
}
