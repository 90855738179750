import React from 'react';

import { Column, Row } from '../../../../../components/shared.styled';
import { YearMonthPicker } from '../../../../../components/yearMonthPicker/YearMonthPicker';
import { InputFormField, SelectFormField } from '../../../../../formFields';
import { FormField } from '../../../../../formValidator';
import { YearAndMonthValidation } from '../../../../../utils/customValidation';
import { findKeyFromValue } from '../../../../../utils/helper';
import { Clonable } from '../../../../../utils/Immer.class';
import { EmploymentStatusSelectOptions } from '../../../../../utils/selectOptions';
import { InfoCardData } from '../../../../Interfaces';
import { EmploymentDispatcher } from '../../../Employment.dispatcher';
import { EmploymentInfoFormProps, EmploymentStatus } from '../EmploymentStatus';
import * as NewFields from './PreviousEmploymentStatusFields';

// This is covering Employed, ActiveMilitary, Other
export class PreviouslyEmployed extends EmploymentStatus implements Clonable<PreviouslyEmployed> {
    previousEmployerName: FormField.Input;
    previousEmployerAddress: FormField.Input;
    previousSuiteNumber: FormField.Input;
    previousCity: FormField.Input;
    previousState: FormField.Select;
    previousZip: FormField.Input;
    previousYearsEmployed: FormField.Input;
    previousMonthsEmployed: FormField.Input;
    previousOccupation: FormField.Input;

    /**
     * @param statusValue to specify if this is for Employed, ActiveMilitary, Other
     * by default Employed
     * @param me @type Employed
     */
    constructor(statusValue?: string, me?: PreviouslyEmployed) {
        super({ ...me, status: NewFields.previousEmploymentStatus(statusValue ?? EmploymentStatusSelectOptions.Employed) });
        this.previousEmployerName = me?.previousEmployerName ?? NewFields.previousEmployerName();
        this.previousEmployerAddress = me?.previousEmployerAddress ?? NewFields.newPreviousEmployerAddress();
        this.previousSuiteNumber = me?.previousSuiteNumber ?? NewFields.newPreviousSuiteNumber();
        this.previousCity = me?.previousCity ?? NewFields.newPreviousCity();
        this.previousState = me?.previousState ?? NewFields.newPreviousState();
        this.previousZip = me?.previousZip ?? NewFields.newPreviousZip();
        this.previousYearsEmployed = me?.previousYearsEmployed ?? NewFields.newPreviousYearsEmployed();
        this.previousMonthsEmployed = me?.previousMonthsEmployed ?? NewFields.newPreviousMonthsEmployed();
        this.previousOccupation = me?.previousOccupation ?? NewFields.newPreviousOccupation();
    }
    clone = (): PreviouslyEmployed => {
        return new PreviouslyEmployed(this.status.value, { ...this });
    };
    renderEmploymentStatus = (
        dispatcher: EmploymentDispatcher,
        {
            isEmployerAddressRequired,
            isCreditAppOccupationFieldEnabled,
            enableEmploymentTimeVsAgeValidation,
            previousYearMonthPickerErrorMessage
        }: EmploymentInfoFormProps
    ) => {
        let yearAndMonthValidationMessage;
        if (enableEmploymentTimeVsAgeValidation) {
            yearAndMonthValidationMessage = previousYearMonthPickerErrorMessage;
        } else {
            yearAndMonthValidationMessage = new YearAndMonthValidation(
                this.previousYearsEmployed.value,
                this.previousMonthsEmployed.value
            ).getErrorMessage();
        }

        const isEmployerAddressOptional = !isEmployerAddressRequired;

        this.previousEmployerAddress.optional = isEmployerAddressOptional;
        this.previousCity.optional = isEmployerAddressOptional;
        this.previousState.optional = isEmployerAddressOptional;
        this.previousZip.optional = isEmployerAddressOptional;
        return (
            <>
                {isCreditAppOccupationFieldEnabled ? (
                    <>
                        <Row>
                            <Column>
                                <SelectFormField formField={this.status} onChangeHandler={dispatcher.updatePreviousStatus} />
                            </Column>
                            <Column></Column>
                        </Row>
                        <Row>
                            <Column>
                                <InputFormField
                                    formField={this.previousEmployerName}
                                    onChangeHandler={dispatcher.updatePreviousStatusInputs}
                                />
                            </Column>
                            <Column>
                                <InputFormField
                                    formField={this.previousOccupation}
                                    onChangeHandler={dispatcher.updatePreviousStatusInputs}
                                />
                            </Column>
                        </Row>
                    </>
                ) : (
                    <Row>
                        <Column>
                            <SelectFormField formField={this.status} onChangeHandler={dispatcher.updatePreviousStatus} />
                        </Column>
                        <Column>
                            <InputFormField
                                formField={this.previousEmployerName}
                                onChangeHandler={dispatcher.updatePreviousStatusInputs}
                            />
                        </Column>
                    </Row>
                )}
                <Row>
                    <Column>
                        <InputFormField
                            formField={this.previousEmployerAddress}
                            onChangeHandler={dispatcher.updatePreviousStatusInputs}
                        />
                    </Column>
                    <Column>
                        <InputFormField
                            formField={this.previousSuiteNumber}
                            onChangeHandler={dispatcher.updatePreviousStatusInputs}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <InputFormField formField={this.previousCity} onChangeHandler={dispatcher.updatePreviousStatusInputs} />
                    </Column>
                    <Column>
                        <SelectFormField
                            formField={this.previousState}
                            onChangeHandler={dispatcher.updatePreviousEmploymentAddressStateValue}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <InputFormField formField={this.previousZip} onChangeHandler={dispatcher.updatePreviousStatusInputs} />
                    </Column>
                    <Column>
                        <YearMonthPicker
                            label="How Long Did You Work Here?"
                            year={this.previousYearsEmployed}
                            month={this.previousMonthsEmployed}
                            monthChangeHandler={dispatcher.updatePreviousYearsMonthEmployed}
                            yearChangeHandler={dispatcher.updatePreviousYearsMonthEmployed}
                            errorMessage={yearAndMonthValidationMessage}
                        />
                    </Column>
                </Row>
            </>
        );
    };

    reviewAdapter = (): InfoCardData => {
        return {
            previousEmploymentStatus: {
                label: this.status.label ?? '',
                value: findKeyFromValue(this.status.value, EmploymentStatusSelectOptions) ?? ''
            },
            previousEmployerName: {
                label: this.previousEmployerName.label ?? '',
                value: this.previousEmployerName.value ?? ''
            },
            previousEmployerAddress: {
                label: "Previous Employer's Address",
                value:
                    `${this.previousEmployerAddress.value}${
                        this.previousSuiteNumber.value ? `, ${this.previousSuiteNumber.value}` : ''
                    }` ?? ''
            },
            previousCityStateZip: {
                label: '',
                value: `${this.previousCity.value}, ${this.previousState.value} ${this.previousZip.value}`
            }
        };
    };
}
