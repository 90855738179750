import React from 'react';

import { Column, Row, StyledHorizontalRule } from '../../components/shared.styled';
import { YearMonthPicker } from '../../components/yearMonthPicker/YearMonthPicker';
import { InputFormField, SelectFormField } from '../../formFields';
import { AddressDispatcher } from './Address.Dispatcher';
import { Address } from './models/Address.model';

interface PreviousAddressInfoFormProps {
    previousAddress: Address;
    addressDispatcher: AddressDispatcher;
    previousYearMonthPickerErrorMessage: string;
    preStringId: string;
}

const PreviousAddressInfoForm: React.FC<PreviousAddressInfoFormProps> = ({
    previousAddress,
    previousYearMonthPickerErrorMessage,
    addressDispatcher,
    preStringId
}) => {
    return (
        <>
            <StyledHorizontalRule />
            <Row>
                <Column>
                    <InputFormField
                        id={`${preStringId}-previousStreetAddress`}
                        formField={previousAddress.streetAddress}
                        onChangeHandler={addressDispatcher.updateAddressInfo}
                    />
                </Column>
                <Column>
                    <InputFormField
                        id={`${preStringId}-previousSuiteAptNumber`}
                        formField={previousAddress.suiteAptNumber}
                        onChangeHandler={addressDispatcher.updateAddressInfo}
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <InputFormField
                        id={`${preStringId}-previousCity`}
                        formField={previousAddress.city}
                        onChangeHandler={addressDispatcher.updateAddressInfo}
                    />
                </Column>
                <Column>
                    <SelectFormField
                        id={`${preStringId}-previousState`}
                        formField={previousAddress.state}
                        onChangeHandler={addressDispatcher.updateAddressInfo}
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <InputFormField
                        id={`${preStringId}-previousZip`}
                        formField={previousAddress.zip}
                        onChangeHandler={addressDispatcher.updateAddressInfo}
                    />
                </Column>
                <Column>
                    <YearMonthPicker
                        id={`${preStringId}-previousTimeAtAddress`}
                        label="How Long Did You Live Here?"
                        year={previousAddress.yearsAtAddress}
                        month={previousAddress.monthsAtAddress}
                        monthChangeHandler={addressDispatcher.updateAddressInfo}
                        yearChangeHandler={addressDispatcher.updateAddressInfo}
                        errorMessage={previousYearMonthPickerErrorMessage}
                    />
                </Column>
            </Row>
            <Row>
                <Column>
                    <InputFormField
                        id={`${preStringId}-previousCounty`}
                        formField={previousAddress.county}
                        onChangeHandler={addressDispatcher.updateAddressInfo}
                    />
                </Column>
                <Column></Column>
            </Row>
        </>
    );
};

export default PreviousAddressInfoForm;
