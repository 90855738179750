import { twoYearsOrLess } from '../../../utils/helper';
import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Address } from '../models/Address.model';
import { Housing } from '../models/Housing.model';

export class YearActionCreator extends ActionCreator<Housing> {
    mutateField = (state: Housing) => {
        return produce(state, (draft) => {
            const isLessThan2years = twoYearsOrLess(this.fieldValue, state.address.monthsAtAddress.value);

            if (isLessThan2years) {
                // checks if previous address exist or not if it does not exist we create a new Address model
                if (!draft.previousAddress && draft.address.monthsAtAddress.value !== '') {
                    // if it does not exist we create a new Address model
                    draft.previousAddress = new Address(true);
                }
            } else {
                draft.previousAddress = undefined;
            }
            draft.address.yearsAtAddress.setValue(this.fieldValue);
        });
    };
    public getAction = () => (prevState: Housing) => this.mutateField(prevState);
}
