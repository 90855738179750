const AdobeTagConfiguration = {
    DR_STANDALONE_CREDIT_APP_DISPLAYED: {
        tag: 'DR_STANDALONE_CREDIT_APP_DISPLAYED',
        desc: 'Automatic loading of Standalone Accelerate Credit Application',
        engagement: 'impression'
    },
    DR_STANDALONE_CREDIT_APP_STARTED: {
        tag: 'DR_STANDALONE_CREDIT_APP_STARTED',
        desc: 'Credit App started',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_TYPE: {
        tag: 'DR_STANDALONE_CREDIT_APP_TYPE',
        desc: 'Credit App type',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_PERSONALINFO_PAGE_DISPLAYED: {
        tag: 'DR_STANDALONE_CREDIT_APP_PERSONALINFO_PAGE_DISPLAYED',
        desc: 'Credit App Personal Info Page opened',
        engagement: 'impression'
    },
    DR_STANDALONE_CREDIT_APP_PERSONALINFO_RETURN_CLICKED: {
        tag: 'DR_STANDALONE_CREDIT_APP_PERSONALINFO_RETURN_CLICKED',
        desc: 'Credit App User clicked on status bar for PERSONAL',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_EDIT_PERSONAL_CLICKED: {
        tag: 'DR_STANDALONE_CREDIT_APP_EDIT_PERSONAL_CLICKED',
        desc: 'Credit App Review Page',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_PERSONAL_CTA_CLICKED: {
        tag: 'DR_STANDALONE_CREDIT_APP_PERSONAL_CTA_CLICKED',
        desc: 'Credit App Personal Info Page completed',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_HOUSING_PAGE_DISPLAYED: {
        tag: 'DR_STANDALONE_CREDIT_APP_HOUSING_PAGE_DISPLAYED',
        desc: 'Credit App Housing Page opened',
        engagement: 'impression'
    },
    DR_STANDALONE_CREDIT_APP_HOUSING_RETURN_CLICKED: {
        tag: 'DR_STANDALONE_CREDIT_APP_HOUSING_RETURN_CLICKED',
        desc: 'Credit App User clicked on status bar for HOUSING',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_HOUSING_CTA_CLICKED: {
        tag: 'DR_STANDALONE_CREDIT_APP_HOUSING_CTA_CLICKED',
        desc: 'Credit App Housing Page completed',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_EDIT_HOUSING_CLICKED: {
        tag: 'DR_CREDIT_APP_EDIT_HOUSING_CLICKED',
        desc: 'Credit App Review Page',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_EMPLOYMENT_DISPLAYED: {
        tag: 'DR_STANDALONE_CREDIT_APP_EMPLOYMENT_DISPLAYED',
        desc: 'Credit App Employment Page opened',
        engagement: 'impression'
    },
    DR_STANDALONE_CREDIT_APP_EMPLOYMENT_CTA_CLICKED: {
        tag: 'DR_STANDALONE_CREDIT_APP_EMPLOYMENT_CTA_CLICKED',
        desc: 'Credit App Employment Page completed',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_EMPLOYMENT_RETURN_CLICKED: {
        tag: 'DR_STANDALONE_CREDIT_APP_EMPLOYMENT_RETURN_CLICKED',
        desc: 'Credit App User clicked on status bar for EMPLOYMENT',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_EDIT_EMPLOYMENT_CLICKED: {
        tag: 'DR_CREDIT_APP_EDIT_EMPLOYMENT_CLICKED',
        desc: 'Credit App Review Page',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_REVIEW_PAGE_DISPLAYED: {
        tag: 'DR_STANDALONE_CREDIT_APP_REVIEW_PAGE_DISPLAYED',
        desc: 'Credit App Review Page opened',
        engagement: 'impression'
    },
    DR_STANDALONE_CREDIT_APP_SUBMIT_CTA_CLICKED: {
        tag: 'DR_STANDALONE_CREDIT_APP_SUBMIT_CTA_CLICKED',
        desc: 'Accelerate Standalone Credit App Submission Started',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_SUBMITTED: {
        tag: 'DR_STANDALONE_CREDIT_APP_SUBMITTED',
        desc: 'Accelerate Standalone Credit App Submission',
        engagement: 'click'
    },
    DR_STANDALONE_CREDIT_APP_SUCCESS_DISPLAYED: {
        tag: 'DR_STANDALONE_CREDIT_APP_SUCCESS_DISPLAYED',
        desc: 'Accelerate Standalone Credit App Submitted Successfully',
        engagement: 'impression'
    },
    DR_STANDALONE_CREDIT_APP_FAILURE_DISPLAYED: {
        tag: 'DR_STANDALONE_CREDIT_APP_FAILURE_DISPLAYED',
        desc: 'Accelerate Standalone Credit App failed',
        engagement: 'impression'
    }
};

type AdobeTags = keyof typeof AdobeTagConfiguration;
export type AdobeStandaloneTag = {
    tag: string;
    desc: string;
    engagement?: string;
};

type AdobeStandaloneTagData = {
    [prop in AdobeTags]: AdobeStandaloneTag;
};

export const ADOBE_STANDALONE_TAGS = AdobeTagConfiguration as AdobeStandaloneTagData;
