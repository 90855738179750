import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { faCheck, faUser, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, RadioGroup, RadioTile } from '@makemydeal/ui-bricks/dist/cox';

import { setApplicantChoice } from '../../actions/CreditAppMutators';
import { CreditDecisionCollapsible } from '../../components/CreditDecisionCollapsible/CreditDecisionCollapsible';
import { ButtonWrapper, CreditDecisionDisclaimerText, ErrorMessageWrapper, PageContainer } from '../../components/shared.styled';
import WithPageUI from '../../components/WithPageUI';
import { useCreditAppDispatch } from '../../contexts/CreditAppContext';
import { useCreditAppExperience } from '../../contexts/CreditAppExperienceContext';
import { useExternalResourceState } from '../../contexts/ExternalResourceContext';
import { useSubmit } from '../../customHooks/UseSubmit.hook';
import { LandingPageDisclaimerText } from '../../pages/Landing/LandingPage.styled';
import { ADOBE_STANDALONE_TAGS } from '../../types/AdobeStandaloneTags';
import { APPLICANT_CHOICE, PAGES } from '../../types/Constants';
import { Resources } from '../../types/ExternalResources';
import { KeyValuePair } from '../../types/KeyValuePair';
import { constructSCADataLayer } from '../../utils/eventUtils';
import { emptyString, setNewRelicCustomAttributes } from '../../utils/helper';
import { getUrlVars } from '../../utils/urlParameters';
import {
    ApplicantChoice,
    ApplicantInfo,
    CheckIcon,
    ChoiceHeader,
    InfoList,
    InfoListItem,
    LandingPageBodyContainer,
    LandingPageBodyTitle
} from './LandingPage.styled';

const { INDIVIDUAL, JOINT } = APPLICANT_CHOICE;

const InfoListItems: KeyValuePair = {
    ssn: 'Social security number and date of birth',
    housingInfo: 'Housing information',
    employment: 'Employment details'
};
const LandingPage: React.FC<{ location: Location }> = ({ location }) => {
    const dispatch = useCreditAppDispatch();
    const externalResourcesState = useExternalResourceState();
    const isCreditDecisionEnabled = (externalResourcesState as Resources)?.dealer?.isAccelerateCreditDecisionEnabled;
    const { isStandaloneExperience } = useCreditAppExperience();

    const dealerName = (externalResourcesState as Resources)?.dealer?.name || 'The dealer';
    const [applicantSelection, setApplicantSelection] = useState('');
    const urlParameter = useMemo(() => getUrlVars(), []);
    const redirectToDecisioning =
        urlParameter.dealerId &&
        urlParameter.dealerId !== '' &&
        urlParameter.sessionId &&
        urlParameter.sessionId !== '' &&
        urlParameter.activateDecision &&
        urlParameter.activateDecision === 'true';

    const displayCreditDecisionVerbiage = !isStandaloneExperience && isCreditDecisionEnabled;

    setNewRelicCustomAttributes({
        coxAutoCorrelationId: (externalResourcesState as Resources)['x-coxauto-correlation-id'],
        'coxauto:ci-id': (externalResourcesState as Resources)['coxauto:ci-id'],
        creditAppVersion: (externalResourcesState as Resources)['credit-app-version'],
        dealerId: urlParameter.dealerId,
        decisionEnabled: isCreditDecisionEnabled,
        dxgId: (externalResourcesState as Resources).dealXgId,
        experience: (externalResourcesState as Resources).experience
    });

    const history = useHistory();

    const handleRoute = () => {
        history.push(PAGES['/personal'].urlPath);
    };

    if (redirectToDecisioning) history.push('/decision');

    const setApplicationType = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isStandaloneExperience) {
            constructSCADataLayer(ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_TYPE, e.target.value);
        }
        setApplicantSelection(e.target.value);
    };

    const Submit = () => {
        const statePayload = {
            body: {
                state: { applicantSelection, externalData: externalResourcesState as Resources }
            }
        };
        useSubmit(dispatch, setApplicantChoice, statePayload, handleRoute, location, isStandaloneExperience);
    };

    return (
        <PageContainer>
            {displayCreditDecisionVerbiage && <CreditDecisionCollapsible dealerName={dealerName} />}
            <LandingPageBodyContainer>
                <ApplicantInfo>
                    <LandingPageBodyTitle className="common-header">What you need for all applicants</LandingPageBodyTitle>
                    <InfoList>
                        {Object.keys(InfoListItems).map((item) => (
                            <InfoListItem key={item}>
                                <CheckIcon>
                                    <FontAwesomeIcon icon={faCheck} />
                                </CheckIcon>
                                <span>{InfoListItems[item]}</span>
                            </InfoListItem>
                        ))}
                    </InfoList>
                </ApplicantInfo>
            </LandingPageBodyContainer>
            <ApplicantChoice>
                <ChoiceHeader className="common-header">Are you applying individually or jointly?</ChoiceHeader>
                <RadioGroup id="applicant-choice" name="applicant_choice" onChangeFunction={setApplicationType}>
                    <RadioTile
                        className="applicantChoiceRadioOption"
                        id={INDIVIDUAL}
                        label={INDIVIDUAL}
                        value={INDIVIDUAL}
                        icon={<FontAwesomeIcon icon={faUser} size="2x" />}
                    />
                    <RadioTile id={JOINT} label={JOINT} value={JOINT} icon={<FontAwesomeIcon icon={faUserFriends} size="2x" />} />
                </RadioGroup>
            </ApplicantChoice>
            {applicantSelection === APPLICANT_CHOICE.JOINT && (
                <LandingPageDisclaimerText>
                    {`Please be aware that by selecting “Joint” the applicant and the co-applicant agree they intend to apply for
                    joint credit. The co-applicant must be present and must indicate his or her acceptance of the Terms and
                    Conditions at the end of this application before it is submitted.`}
                </LandingPageDisclaimerText>
            )}

            <ButtonWrapper onClick={Submit} disabled={emptyString(urlParameter.dealerId) || emptyString(applicantSelection)}>
                Start
            </ButtonWrapper>
            {displayCreditDecisionVerbiage && (
                <CreditDecisionDisclaimerText>
                    Your application will be evaluated in real time to provide an instant credit decision.
                </CreditDecisionDisclaimerText>
            )}

            {emptyString(urlParameter.dealerId) && (
                <ErrorMessageWrapper className="error-message-wrapper">
                    <ErrorMessage>Oops, something went wrong. Please try and refresh this page.</ErrorMessage>
                </ErrorMessageWrapper>
            )}
        </PageContainer>
    );
};
export default WithPageUI(LandingPage);
