import { rem } from 'polished';
import styled from 'styled-components';

export const DropDownPageContainer = styled.div`
    margin-top: ${rem(-32)};
`;

export const StyledText = styled.button`
    display: flex;
    flex-wrap: none;
    justify-content: flex-between;
    align-items: center;
    background: transparent;
    cursor: pointer;
    border: unset;
    padding: unset;
    outline: none;
    font-size: ${rem(16)};
    color: ${({ theme }) => theme.primaryLinkColor};
    text-decoration: ${({ theme }) => theme.primaryLinkTextDecoration};
    font-family: ${({ theme }) => theme.linkFontFamily};
    font-weight: ${({ theme }) => theme.linkFontWeight};
    text-transform: ${({ theme }) => theme.linkTextTransform};

    &:hover {
        color: ${({ theme }) => theme.primaryLinkHoverColor};
        text-decoration: ${({ theme }) => theme.primaryLinkHoverTextDecoration};
    }
`;

export const CollapsibleLabel = styled.p`
    margin-right: 5px;
`;

export const Content = styled.div`
    overflow: hidden;
    background-color: transparent;
    margin-bottom: ${rem(16)};
    font-size: ${rem(13)};
`;
