import { Clonable } from '../../../utils/Immer.class';
import { Housing } from './Housing.model';

export class HousingInfo implements Clonable<HousingInfo> {
    primaryApplicant: Housing;
    jointApplicant: Housing;

    constructor(me: Partial<HousingInfo> = {}) {
        this.primaryApplicant = me.primaryApplicant ?? new Housing();
        this.jointApplicant = me.jointApplicant ?? new Housing();
    }
    clone(): HousingInfo {
        return new HousingInfo({ ...this });
    }
}
