import React from 'react';

import { Option } from '@makemydeal/ui-bricks/dist/cox';

import { ThemedInputWrapper } from '../components/shared.styled';
import { FormField, Validator } from '../formValidator';
import { SelectFieldChangeHandler } from '../formValidator/BaseFormField';
import { StyledSelect } from './SelectFormField.styled';

interface SelectFormFieldProps {
    id?: string;
    formField: FormField.Select;
    onChangeHandler: SelectFieldChangeHandler;
}

const SelectFormField: React.FC<SelectFormFieldProps> = ({ id, formField, onChangeHandler }) => (
    <ThemedInputWrapper>
        <StyledSelect
            id={id}
            name={formField.name ?? ''}
            errorMessage={Validator.getErrorMessage(formField)}
            label={formField.label ?? ''}
            value={formField.value}
            defaultValue={formField.value ?? ''}
            disabled={formField.disabled ?? false}
            onChangeFunction={onChangeHandler}
            validationFunction={formField.validationFunction}
            hasError={formField.hasError}
            labelCustomization={{
                optional: formField.optional,
                tooltip: formField.tooltip
            }}
        >
            <>
                {/* NOTE: The condtiton around the disable attritbute will fail if the label name changes */}
                <Option disabled={formField.label === 'Additional Source of Income' ? false : true} value="">
                    {formField.placeholder}
                </Option>
                {formField.selectOptions &&
                    Object.entries(formField.selectOptions).map((option, i) => {
                        const [key, value] = option;
                        return (
                            <Option key={`option-${i}`} value={value} disabled={formField.disabled}>
                                {key}
                            </Option>
                        );
                    })}
            </>
        </StyledSelect>
    </ThemedInputWrapper>
);

export default SelectFormField;
