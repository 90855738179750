import React from 'react';

import { ErrorMessage, Label } from '@makemydeal/ui-bricks/dist/cox';

import { InputFormField } from '../../formFields';
import { FormField } from '../../formValidator';
import { ChangeHandler } from '../../formValidator/BaseFormField';
import { ErrorMessageWrapper, MonthCol, YearCol, YearMonthContainer, YearMonthRow } from './YearMonthPicker.styled';

interface YearMonthPickerProps {
    id?: string;
    label: string;
    year: FormField.Input;
    month: FormField.Input;
    yearChangeHandler: ChangeHandler;
    monthChangeHandler: ChangeHandler;
    errorMessage?: string;
}

export const YearMonthPicker: React.FC<YearMonthPickerProps> = ({
    id,
    label,
    year,
    month,
    yearChangeHandler,
    monthChangeHandler,
    errorMessage
}) => {
    return (
        <YearMonthContainer id={id}>
            <Label htmlFor="yearMonthRow" shouldShowValue={true}>
                {label}
            </Label>
            <YearMonthRow id="yearMonthRow">
                <YearCol>
                    <InputFormField
                        id={id ? `${id}-year` : ''}
                        formField={year}
                        onChangeHandler={yearChangeHandler}
                        icon={'Yr(s)'}
                        classname="yearmonthInput"
                    />
                </YearCol>
                <MonthCol>
                    <InputFormField
                        id={id ? `${id}-month` : ''}
                        formField={month}
                        onChangeHandler={monthChangeHandler}
                        icon={'Mo(s)'}
                        classname="yearmonthInput"
                    />
                </MonthCol>
            </YearMonthRow>
            {errorMessage && (
                <ErrorMessageWrapper data-testid="year-month-picker-error-message">
                    <ErrorMessage>{errorMessage}</ErrorMessage>
                </ErrorMessageWrapper>
            )}
        </YearMonthContainer>
    );
};
