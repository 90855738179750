import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import ConfirmationPage from '../pages/ConfirmationPage/ConfirmationPage';
import CreditDecisionLandingPage from '../pages/CreditDecision/CreditDecisionLandingPage';
import EmploymentInfoPage from '../pages/EmploymentInfo/EmploymentInfoPage';
import HousingInfoPage from '../pages/HousingInfo/HousingInfoPage';
import LandingPage from '../pages/Landing/LandingPage';
import PersonalInfoPage from '../pages/PersonalInfo/PersonalInfoPage';
import ReviewPage from '../pages/ReviewPage/ReviewPage';
import { PAGES } from '../types/Constants';

const AppRouter: React.FC = () => {
    return (
        <Switch>
            <Route exact path={PAGES['/'].urlPath} component={LandingPage} />
            <Route exact path={PAGES['/personal'].urlPath} component={PersonalInfoPage} />
            <Route exact path={PAGES['/housing'].urlPath} component={HousingInfoPage} />
            <Route exact path={PAGES['/employment'].urlPath} component={EmploymentInfoPage} />
            <Route exact path={PAGES['/review'].urlPath} component={ReviewPage} />
            <Route exact path={PAGES['/confirmation'].urlPath} component={ConfirmationPage} />
            <Route exact path={PAGES['/confirmationStandAlone'].urlPath} component={ConfirmationPage} />
            <Route exact path={PAGES['/decision'].urlPath} component={CreditDecisionLandingPage} />
            <Route path="*" render={() => <Redirect to={'/'} />} />
        </Switch>
    );
};

export default withRouter(AppRouter);
