import styled from 'styled-components';

export const AccelerateLoaderAnimationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
    align-items: center;
    background-color: ${(props) => props.theme.modalBackgroundColor};
    box-shadow: ${(props) => props.theme.commonDrModalBoxShadow};
    border: ${(props) => props.theme.commonDrModalBorder};
    width: 100%;
    padding: 1.5rem 0.5rem;
    border-radius: 0.5rem;

    img.animation {
        max-width: 100%;
        height: auto;
        object-fit: cover;
        object-position: bottom;
    }
`;

export const HeaderReel = styled.div`
    font-size: 24px;
    font-weight: bold;
    width: 25rem;
    text-align: center;
    margin-bottom: 1rem;
    flex-shrink: 2;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 25rem;
    color: ${(props) => props.theme.secondaryFontColor};
`;

export const SubGif = styled.div`
    width: 12.5rem;
    font-size: large;
    color: ${(props) => props.theme.primaryFontColor};
`;

export const ContinueButton = styled.div`
    color: ${(props) => props.theme.primaryLinkActiveColor};
    font-size: large;
    text-decoration: none;

    :hover {
        color: ${(props) => props.theme.primaryLinkHoverColor};
        text-decoration: none;
    }
`;
