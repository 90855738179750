import { Clonable } from '../../../utils/Immer.class';
import { Applicant } from './Applicant.model';

export class PersonalInfo implements Clonable<PersonalInfo> {
    primaryApplicant: Applicant;
    jointApplicant: Applicant;

    constructor(me: Partial<PersonalInfo> = {}) {
        this.primaryApplicant = me.primaryApplicant ?? new Applicant();
        this.jointApplicant = me.jointApplicant ?? new Applicant();
    }
    clone(): PersonalInfo {
        return new PersonalInfo({ ...this });
    }
}
