import { produce } from '../../../utils/Immer.class';
import { OwnershipTypes } from '../../../utils/selectOptions';
import { ActionCreator } from '../../ActionCreators';
import { Housing } from '../models/Housing.model';

export class UpdateOwnershipActionCreator extends ActionCreator<Housing> {
    mutateField = (state: Housing) => {
        const isOwnOutright = this.fieldValue === OwnershipTypes['Own Outright'];

        return produce(state, (draft) => {
            draft.ownOrRent.setValue(this.fieldValue);
            draft.monthlyPayment.optional = isOwnOutright;
            if (isOwnOutright) {
                draft.monthlyPayment.value = '';
            }
        });
    };
    public getAction = () => (prevState: Housing) => this.mutateField(prevState);
}
