import { Clonable } from '../../../utils/Immer.class';

export class CreditDecision implements Clonable<CreditDecision> {
    decisions: any[];
    creditAppId: string;
    dealRefId: string; // TODO: clan up dealRefId
    fsDealRefId?: string;
    shadowCreditAppId: string;

    constructor(me?: CreditDecision) {
        this.decisions = me?.decisions ?? [];
        this.creditAppId = me?.creditAppId ?? '';
        this.dealRefId = me?.dealRefId ?? ''; // TODO: clan up dealRefId
        this.fsDealRefId = me?.fsDealRefId ?? '';
        this.shadowCreditAppId = me?.shadowCreditAppId ?? '';
    }

    clone = (): CreditDecision => new CreditDecision({ ...this });
}
