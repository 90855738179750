import React from 'react';

import { Column, Row } from '../../../../../components/shared.styled';
import { YearMonthPicker } from '../../../../../components/yearMonthPicker/YearMonthPicker';
import { InputFormField, SelectFormField } from '../../../../../formFields';
import { FormField } from '../../../../../formValidator';
import { findKeyFromValue } from '../../../../../utils/helper';
import { Clonable } from '../../../../../utils/Immer.class';
import { EmploymentStatusSelectOptions } from '../../../../../utils/selectOptions';
import { InfoCardData } from '../../../../Interfaces';
import { EmploymentDispatcher } from '../../../Employment.dispatcher';
import { EmploymentInfoFormProps, EmploymentStatus } from '../EmploymentStatus';
import * as NewFields from './PreviousEmploymentStatusFields';

export class PreviouslyStudent extends EmploymentStatus implements Clonable<PreviouslyStudent> {
    previousEmployerName: FormField.Input;
    previousSchoolAddress: FormField.Input;
    previousSuiteNumber: FormField.Input;
    previousCity: FormField.Input;
    previousState: FormField.Select;
    previousZip: FormField.Input;
    previousYearsEmployed: any;
    previousMonthsEmployed: any;

    constructor(me?: PreviouslyStudent) {
        super({ ...me, status: NewFields.previousEmploymentStatus(EmploymentStatusSelectOptions.Student) });
        this.previousEmployerName = me?.previousEmployerName ?? NewFields.previousStudentEmployerName();
        this.previousSchoolAddress = me?.previousSchoolAddress ?? NewFields.newPreviousSchoolAddress();
        this.previousSuiteNumber = me?.previousSuiteNumber ?? NewFields.newPreviousSuiteNumber();
        this.previousCity = me?.previousCity ?? NewFields.newPreviousCity();
        this.previousState = me?.previousState ?? NewFields.newPreviousState();
        this.previousZip = me?.previousZip ?? NewFields.newPreviousZip();
        this.previousYearsEmployed = me?.previousYearsEmployed ?? NewFields.newPreviousYearsEmployed();
        this.previousMonthsEmployed = me?.previousMonthsEmployed ?? NewFields.newPreviousMonthsEmployed();
    }
    clone = (): PreviouslyStudent => {
        return new PreviouslyStudent({ ...this });
    };

    renderEmploymentStatus = (
        dispatcher: EmploymentDispatcher,
        {
            isEmployerAddressRequired,
            enableEmploymentTimeVsAgeValidation,
            previousYearMonthPickerErrorMessage
        }: EmploymentInfoFormProps
    ) => {
        let yearAndMonthValidationMessage = '';
        if (enableEmploymentTimeVsAgeValidation) {
            yearAndMonthValidationMessage = previousYearMonthPickerErrorMessage;
        }

        const isEmployerAddressOptional = !isEmployerAddressRequired;
        this.previousSchoolAddress.optional = isEmployerAddressOptional;
        this.previousCity.optional = isEmployerAddressOptional;
        this.previousState.optional = isEmployerAddressOptional;
        this.previousZip.optional = isEmployerAddressOptional;
        return (
            <>
                <Row>
                    <Column>
                        <SelectFormField formField={this.status} onChangeHandler={dispatcher.updatePreviousStatus} />
                    </Column>
                    <Column>
                        <InputFormField
                            formField={this.previousEmployerName}
                            onChangeHandler={dispatcher.updatePreviousStatusInputs}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <InputFormField
                            formField={this.previousSchoolAddress}
                            onChangeHandler={dispatcher.updatePreviousStatusInputs}
                        />
                    </Column>
                    <Column>
                        <InputFormField
                            formField={this.previousSuiteNumber}
                            onChangeHandler={dispatcher.updatePreviousStatusInputs}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <InputFormField formField={this.previousCity} onChangeHandler={dispatcher.updatePreviousStatusInputs} />
                    </Column>
                    <Column>
                        <SelectFormField
                            formField={this.previousState}
                            onChangeHandler={dispatcher.updatePreviousEmploymentAddressStateValue}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <InputFormField formField={this.previousZip} onChangeHandler={dispatcher.updatePreviousStatusInputs} />
                    </Column>
                    <Column>
                        <YearMonthPicker
                            label="How Long Did You Work Here?"
                            year={this.previousYearsEmployed}
                            month={this.previousMonthsEmployed}
                            monthChangeHandler={dispatcher.updatePreviousYearsMonthEmployed}
                            yearChangeHandler={dispatcher.updatePreviousYearsMonthEmployed}
                            errorMessage={yearAndMonthValidationMessage}
                        />
                    </Column>
                </Row>
            </>
        );
    };

    reviewAdapter = (): InfoCardData => {
        return {
            previousEmploymentStatus: {
                label: this.status.label ?? '',
                value: findKeyFromValue(this.status.value, EmploymentStatusSelectOptions) ?? ''
            },
            previousEmployerName: {
                label: this.previousEmployerName.label ?? '',
                value: this.previousEmployerName.value ?? ''
            },
            previousEmployerAddress: {
                label: "Previous Employer's Address",
                value:
                    `${this.previousSchoolAddress.value}${
                        this.previousSuiteNumber.value ? `, ${this.previousSuiteNumber.value}` : ''
                    }` ?? ''
            },
            previousCityStateZip: {
                label: '',
                value: `${this.previousCity.value}, ${this.previousState.value} ${this.previousZip.value}`
            }
        };
    };
}
