import React from 'react';

import { Link, Modal } from '@makemydeal/ui-bricks/dist/cox';

import { LinkLabelComponentWrapper } from './LabelWithModal.styled';

interface LabelWithModalProps {
    isOpen: boolean;
    linkLabel: string;
    modalTitleLabel: string;
    closeButtonLabel: string;
    content: () => JSX.Element;
    modalHeight?: string;
    isCtaButton?: boolean;
    handleClick: any;
}

const LabelWithModal: React.FC<LabelWithModalProps> = ({
    isOpen,
    linkLabel,
    isCtaButton,
    modalTitleLabel,
    closeButtonLabel,
    modalHeight,
    content,
    handleClick
}) => {
    return (
        <>
            <LinkLabelComponentWrapper>
                <Link href="#" className="primary-link" onClick={handleClick}>
                    {linkLabel}
                </Link>
            </LinkLabelComponentWrapper>
            {isOpen && (
                <Modal
                    modalHeader={modalTitleLabel}
                    closeButtonText={closeButtonLabel}
                    handleOpenClose={handleClick}
                    height={modalHeight}
                    isCtaButton={isCtaButton}
                >
                    {content()}
                </Modal>
            )}
        </>
    );
};

export default LabelWithModal;
