import { formatDateString, formatPhoneNumberString, formatSocialSecurityNumberString } from '@makemydeal/ui-bricks/dist/cox';

import { emptyString } from '../utils/helper';
import { KeyValuePair } from './../types/KeyValuePair';
import { BaseFormField, PureFormField } from './BaseFormField';

export class Input extends BaseFormField {
    constructor(me: PureFormField<BaseFormField>) {
        super(me as PureFormField<BaseFormField>);
    }
    getTrimmed = () => this.value?.trim() ?? '';
}
export class Select extends BaseFormField {
    selectOptions: KeyValuePair;
    constructor(me?: PureFormField<Select>) {
        super(me as PureFormField<Select>);
        this.selectOptions = me?.selectOptions ?? {};
    }
    getTrimmed = () => this.value?.trim() ?? '';
}

export class Checkbox extends BaseFormField {
    isChecked: boolean;
    value: 'false' | 'true';
    constructor(me?: PureFormField<Checkbox>) {
        super(me as PureFormField<Checkbox>);
        this.value = me?.value ?? 'false';
        this.isChecked = me?.isChecked ?? false;
    }
    // depends on the type of payload to have false| true or 0|1, for now assumed false
    getTrimmed = () => (emptyString(this.value) ? 'false' : this.value ?? '');
}

export class SSN extends BaseFormField {
    constructor(me?: PureFormField<SSN>) {
        super(me as PureFormField<SSN>);
        this.formatter = formatSocialSecurityNumberString;
    }
    getTrimmed = () => this.value?.replace(/\D+/g, '') ?? '';
}
export class Phone extends BaseFormField {
    constructor(me?: PureFormField<Phone>) {
        super(me as PureFormField<Phone>);
        this.formatter = formatPhoneNumberString;
    }
    getTrimmed = () => this.value?.replace(/\D+/g, '') ?? ''; // straight digits
}
export class DateInput extends BaseFormField {
    constructor(me?: PureFormField<DateInput>) {
        super(me as PureFormField<DateInput>);
        this.formatter = formatDateString; // follows mm/dd/yyyy format
    }
    // generate YYYY-MM-DD
    getTrimmed = () =>
        emptyString(this.value)
            ? ''
            : `${this.value?.substring(6, 10)}-${this.value?.substring(0, 2)}-${this.value?.substring(3, 5)}`;
}
