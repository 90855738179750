import React, { useState } from 'react';

import { Input, Modal } from '@makemydeal/ui-bricks/dist/cox';

import { ThemedInputWrapper } from '../../../components/shared.styled';
import {
    DecisionModal,
    DecisionModalInput,
    DecisionModalInputWrapper,
    ModalText,
    SendCodeButton
} from './DecisionEmailModal.styled';

export const DecisionPinAuthModal: React.FC<any> = React.memo((props: any) => {
    const { show, hasError, handleSubmit, handleClose } = props;

    const [pinFormatted, setPinFormatted] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const pinText =
        'For your security, please enter the PIN code you received via email when you submitted your credit application.';

    const handlePinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formatted = e.target.value.length > 4 ? true : false;
        setPinFormatted(formatted);
    };

    const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        setSubmitting(true);
        await handleSubmit(e);
        setSubmitting(false);
    };

    return (
        show && (
            <DecisionModal className="decision-modal-style blurred">
                <Modal
                    modalHeader={'Enter PIN Code'}
                    closeButtonText={'Re-send a New Code'}
                    handleOpenClose={handleClose}
                    height={'400'}
                    isCtaButton={false}
                >
                    <ModalText>{pinText}</ModalText>
                    <ThemedInputWrapper>
                        <DecisionModalInputWrapper>
                            <DecisionModalInput>
                                <Input
                                    label={''}
                                    type="text"
                                    name={'decision_pincode'}
                                    placeholder={'Enter Code'}
                                    errorMessage={'Pin code invalid. Please try again.'}
                                    validationFunction={() => !hasError}
                                    hasError={hasError}
                                    onChangeFunction={handlePinChange}
                                />
                            </DecisionModalInput>
                            <SendCodeButton
                                className="decision-modal-button-confirm"
                                onClick={handleClick}
                                disabled={!pinFormatted || submitting}
                            >
                                Confirm
                            </SendCodeButton>
                        </DecisionModalInputWrapper>
                    </ThemedInputWrapper>
                </Modal>
            </DecisionModal>
        )
    );
});
