import { Dispatch } from 'react';

import { Dispatcher } from '../Dispatcher';
import { UpdateAddressInfoActionCreator } from './ActionCreators';
import { Housing } from './models/Housing.model';

export class AddressDispatcher extends Dispatcher<Housing> {
    constructor(dispatch: Dispatch<Housing>, private addressOrPrevAddress: 'address' | 'previousAddress') {
        super(dispatch);
    }

    /**
     * updateAddressInfo fn updates the address information on the form field dynamically.
     * The piece of state that will change will be predicated on the second parameters value.
     * That value will be either address or previous address
     * values to change will be passed into this change handler in HousingInfoForm.tsx respectively
     *
     **/
    updateAddressInfo = (event: React.SyntheticEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;
        const actionCreator = new UpdateAddressInfoActionCreator(fieldValue, fieldName, this.addressOrPrevAddress);
        this.dispatch(actionCreator.getAction());
    };
}
