import { twoYearsOrLess } from '../../../utils/helper';
import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Address } from '../models/Address.model';
import { Housing } from '../models/Housing.model';

export class MonthActionCreator extends ActionCreator<Housing> {
    mutateField = (state: Housing) => {
        return produce(state, (draft) => {
            const isLessThan2years = twoYearsOrLess(state.address.yearsAtAddress.value, this.fieldValue);
            if (isLessThan2years) {
                if (!draft.previousAddress && draft.address.yearsAtAddress.value !== '') {
                    draft.previousAddress = new Address(true);
                }
            } else {
                draft.previousAddress = undefined;
            }
            draft.address.monthsAtAddress.setValue(this.fieldValue);
        });
    };
    public getAction = () => (prevState: Housing) => this.mutateField(prevState);
}
