import React from 'react';

import AppErrorIcon from '../../assets/AppError.icon';
import { useCreditAppExperience } from '../../contexts/CreditAppExperienceContext';
import { sendCreditAppFailure } from '../../utils/eventUtils';
import { PageContainer, StyledLink } from './ErrorPage.styled';
import { ErrorPageContainer, ErrorPageTitle, Text, TextBody } from './ErrorPage.styled';

const ErrorPage: React.FC = () => {
    const { isEmbeddedExperience, dealerPhone } = useCreditAppExperience();
    let message = '';

    if (isEmbeddedExperience) {
        const errorText = 'No worries though, you can still continue your deal online';
        const phoneSectionText = ` or call the dealer to apply for financing ${dealerPhone}.`;

        message = `${errorText}${dealerPhone ? phoneSectionText : '.'}`;
    } else {
        message =
            'No worries though, you can try refreshing this page' +
            ` or call us directly to apply for financing${dealerPhone ? ` at ${dealerPhone}.` : '.'}`;
    }

    return (
        <PageContainer>
            <ErrorPageContainer>
                <AppErrorIcon />
                <ErrorPageTitle>Oh No!</ErrorPageTitle>
                <Text>Something's gone wrong.</Text>
                <TextBody data-testid="error-message">{message}</TextBody>
                {isEmbeddedExperience ? (
                    <StyledLink onClick={() => sendCreditAppFailure()}>Go to My Deal Dashboard</StyledLink>
                ) : null}
            </ErrorPageContainer>
        </PageContainer>
    );
};
export default ErrorPage;
