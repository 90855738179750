import { rem } from 'polished';
import styled from 'styled-components';

import { Link } from '@makemydeal/ui-bricks/dist/cox';

import { devices } from '../../utils/deviceUtils';

export const PageContainer = styled.div`
    background-color: ${({ theme }) => theme.creditAppPageContainerBackgroundColor};
    width: 50%;
    max-width: ${rem(1256)};
    margin: 0 auto;
    padding: ${rem(32)} 0;
    @media ${devices.tabletL} {
        width: 80%;
    }
`;

export const ErrorPageContainer = styled.div`
    text-align: center;
    border-radius: ${rem(8)};
    background: ${({ theme }) => theme.creditAppErrorPageContainerBackground};
    padding: 5vh 0;
    max-width: ${rem(768)};
    label {
        color: ${({ theme }) => theme.creditAppErrorPageContainerLabelColor};
    }
    @media ${devices.mobileL} {
        padding-bottom: 0;
    }
`;

export const ErrorPageTitle = styled.div`
    font-size: ${rem(36)};
    font-weight: 600;
    color: ${({ theme }) => theme.creditAppErrorPageTitleColor};
    padding: ${rem(32)} ${rem(32)};
    @media ${devices.tablet} {
        font-size: ${rem(32)};
    }
`;
export const Text = styled.div`
    font-size: ${rem(20)};
    font-color: ${({ theme }) => theme.creditAppErrorPageTextFontColor};
`;
export const TextBody = styled.div`
    font-size: ${rem(20)};
    font-color: ${({ theme }) => theme.creditAppErrorPageTextBodyFontColor};
    padding: ${rem(0)} ${rem(80)} ${rem(32)} ${rem(80)};
    @media ${devices.desktop} {
        padding: ${rem(0)} ${rem(0)} ${rem(24)} ${rem(0)};
    }
    @media ${devices.mobileL} {
        padding: ${rem(0)} ${rem(0)} ${rem(24)} ${rem(0)};
    }
`;

export const StyledLink = styled(Link)`
    font-size: ${rem(20)};
`;
