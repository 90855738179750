import { CreditAppState } from '../CreditApp.model';
import { Employed } from '../pages/EmploymentInfo/models/employmentStatus/Employed';
import { getUrlVars } from './urlParameters';

export function preFillCreditApp(state: CreditAppState): CreditAppState {
    const urlParams = getUrlVars();
    if (urlParams.preFillCreditApp === 'true') {
        // Personal info
        state.personalInfo.primaryApplicant.firstName.setValue('Test');
        state.personalInfo.primaryApplicant.middleName.setValue('MiddleName');
        state.personalInfo.primaryApplicant.lastName.setValue('Accelerate');
        state.personalInfo.primaryApplicant.phone.setValue('(503) 736-2985');
        state.personalInfo.primaryApplicant.ssn.setValue('489-76-5227');
        state.personalInfo.primaryApplicant.dob.setValue('10/22/1981');
        state.personalInfo.primaryApplicant.maritalStatus.setValue('Unmarried');
        state.personalInfo.primaryApplicant.email.setValue('nan@email.com');
        state.personalInfo.primaryApplicant.verifyEmail.setValue('nan@email.com');

        // Housing info
        state.housingInfo.primaryApplicant.ownOrRent.setValue('Mortgage');
        state.housingInfo.primaryApplicant.monthlyPayment.setValue('1200');
        state.housingInfo.primaryApplicant.address.streetAddress.setValue('261 Chesterton Street');
        state.housingInfo.primaryApplicant.address.city.setValue('Salem');
        state.housingInfo.primaryApplicant.address.state.setValue('NC');
        state.housingInfo.primaryApplicant.address.zip.setValue('27104');
        state.housingInfo.primaryApplicant.address.yearsAtAddress.setValue('2');
        state.housingInfo.primaryApplicant.address.monthsAtAddress.setValue('4');
        state.housingInfo.primaryApplicant.address.county.setValue('Nassau');

        // Employment status
        const empStat = state.employmentInfo.primaryApplicant.employmentStatus as Employed;
        empStat.employerName.setValue('AMCO');
        empStat.phone.setValue('(801) 287-2323');
        empStat.occupation.setValue('Engineer');
        empStat.monthlyIncome.setValue('6250');
        empStat.employerAddress.setValue('3001 Summit Blvd');
        empStat.city.setValue('Atlanta');
        empStat.state.setValue('GA');
        empStat.zip.setValue('30319');
        empStat.yearsEmployed.setValue('3');
        empStat.monthsEmployed.setValue('4');

        state.reviewInfo.privacyNoticeIndicator.setValue('true');
    }

    return state;
}
