import React from 'react';

import { Column, Row } from '../../../../../components/shared.styled';
import { SelectFormField } from '../../../../../formFields';
import { findKeyFromValue } from '../../../../../utils/helper';
import { Clonable } from '../../../../../utils/Immer.class';
import { EmploymentStatusSelectOptions } from '../../../../../utils/selectOptions';
import { InfoCardData } from '../../../../Interfaces';
import { EmploymentDispatcher } from '../../../Employment.dispatcher';
import { EmploymentStatus } from '../EmploymentStatus';
import * as NewFields from './PreviousEmploymentStatusFields';

export class PreviouslyUnEmployedOrRetired extends EmploymentStatus implements Clonable<PreviouslyUnEmployedOrRetired> {
    constructor(statusValue?: string, me?: PreviouslyUnEmployedOrRetired) {
        super({ ...me, status: NewFields.previousEmploymentStatus(statusValue ?? EmploymentStatusSelectOptions.Unemployed) });
    }
    clone = (): PreviouslyUnEmployedOrRetired => {
        return new PreviouslyUnEmployedOrRetired(this.status.value, { ...this });
    };
    renderEmploymentStatus = (dispatcher: EmploymentDispatcher) => {
        return (
            <Row>
                <Column>
                    <SelectFormField formField={this.status} onChangeHandler={dispatcher.updatePreviousStatus} />
                </Column>
                <Column />
            </Row>
        );
    };

    reviewAdapter = (): InfoCardData => {
        return {
            previousEmploymentStatus: {
                label: this.status.label ?? '',
                value: findKeyFromValue(this.status.value, EmploymentStatusSelectOptions) ?? ''
            }
        };
    };
}
