import { FormField, Validator } from '../../../formValidator';
import { calculateAge, calculateAgeMonths } from '../../../utils/helper';
import { Clonable } from '../../../utils/Immer.class';
import { MaritalStatusTypes, RelationshipTypes } from '../../../utils/selectOptions';
import {
    validateDOB,
    validateEmail,
    validateMaritalStatusType,
    validateName,
    validatePhoneNumber,
    validateRelationshipType
} from '../../../utils/Validation';
import { InfoCardData } from '../../Interfaces';

export class Applicant extends Validator implements Clonable<Applicant> {
    firstName: FormField.Input;
    middleName: FormField.Input;
    lastName: FormField.Input;
    email: FormField.Input;
    verifyEmail: FormField.Input;
    phone: FormField.Phone;
    dob: FormField.DateInput;
    ssn: FormField.SSN;
    relationship: FormField.Select;
    maritalStatus: FormField.Select;
    constructor(me?: Applicant) {
        super();
        this.firstName =
            me?.firstName ??
            new FormField.Input({
                name: 'firstName',
                placeholder: 'John',
                label: 'First Name',
                optional: false,
                validationFunction: validateName,
                errorMessage: 'Letters, spaces and hyphens(-) only. Minimum 2 characters.',
                maxlength: 15,
                tooltip: 'The maximum length for this field is 15 characters'
            });
        this.middleName =
            me?.middleName ??
            new FormField.Input({
                name: 'middleName',
                placeholder: 'Doe',
                label: 'Middle Name',
                optional: true,
                validationFunction: validateName,
                errorMessage: 'Letters, spaces and hyphens(-) only. Minimum 2 characters.',
                maxlength: 25,
                tooltip: "If appears on driver's license. The maximum length for this field is 25 characters"
            });
        this.lastName =
            me?.lastName ??
            new FormField.Input({
                name: 'lastName',
                placeholder: 'Smith',
                label: 'Last Name',
                optional: false,
                validationFunction: validateName,
                errorMessage: 'Letters, spaces and hyphens(-) only. Minimum 2 characters.',
                maxlength: 25,
                tooltip: 'The maximum length for this field is 25 characters'
            });
        this.email =
            me?.email ??
            new FormField.Input({
                name: 'email',
                placeholder: 'email@example.com',
                label: 'Email',
                optional: false,
                validationFunction: validateEmail,
                errorMessage: 'Must be a valid Email address.',
                maxlength: 50,
                tooltip: 'The maximum length for this field is 50 characters'
            });
        this.verifyEmail =
            me?.verifyEmail ??
            new FormField.Input({
                name: 'verifyEmail',
                placeholder: 'email@example.com',
                label: 'Verify Email',
                optional: false,
                // has to be overwritten in consumer since it needs the email and verifyEmail value
                validationFunction: () => true,
                errorMessage: 'Must be a valid Email address, and must match your email.',
                maxlength: 50,
                tooltip: 'The maximum length for this field is 50 characters'
            });
        this.phone =
            me?.phone ??
            new FormField.Phone({
                name: 'phone',
                placeholder: '(555) 555-555',
                label: 'Phone',
                optional: false,
                validationFunction: validatePhoneNumber,
                tooltip: `I consent to receive autodialed, pre-recorded and artificial voice 
				telemarketing and sales calls and text messages from or on behalf of dealer
				(or any financing source to which dealer assigns my contract) at the telephone number(s) 
				provided in this communication, including any cell phone numbers.
				I understand that this consent is not a condition of purchase or credit.`,
                errorMessage: 'Must be a valid Phone Number.'
            });
        this.dob =
            me?.dob ??
            new FormField.DateInput({
                name: 'dob',
                placeholder: 'mm/dd/yyyy',
                label: 'Date of Birth',
                optional: false,
                validationFunction: validateDOB,
                tooltip: 'The Month, Day and Year a person was born',
                errorMessage: 'Date must contain valid month, day and year matching mm/dd/yyyy format'
            });
        this.ssn =
            me?.ssn ??
            new FormField.SSN({
                name: 'ssn',
                placeholder: '___-__-___',
                label: 'Social Security Number',
                optional: false,
                validationFunction: () => true,
                tooltip:
                    // eslint-disable-next-line max-len
                    "Please enter your Social Security Number or Individual Taxpayer Identification Number to ensure we're reviewing the correct credit report information while we consider your application.",
                errorMessage: 'Should be a valid SSN matching the XXX-XX-XXXX format.',
                maxlength: 11
            });
        this.relationship =
            me?.relationship ??
            new FormField.Select({
                name: 'relationship',
                placeholder: 'Select',
                label: 'Relationship to the Applicant',
                customRequiredLabel: 'Please make a selection.',
                optional: false,
                selectOptions: RelationshipTypes,
                validationFunction: validateRelationshipType,
                tooltip: 'Relationship to the primary applicant'
            });
        this.maritalStatus =
            me?.maritalStatus ??
            new FormField.Select({
                name: 'maritalStatus',
                placeholder: 'Select Marital Status',
                label: 'Marital Status',
                customRequiredLabel: 'Please make a selection.',
                optional: false,
                selectOptions: MaritalStatusTypes,
                validationFunction: validateMaritalStatusType,
                tooltip: 'Primary applicant marital status'
            });
    }

    currentApplicantAge = () => {
        return calculateAge(`${this.dob.value}`);
    };

    currentApplicantAgeMonths = () => {
        return calculateAgeMonths(`${this.dob.value}`);
    };

    clone = (): Applicant => {
        return new Applicant({ ...this });
    };

    /**
     * These functions serve as getters for the data to be rendered in the Review Form Page. The objects within them contain
     * all the information from each section in the context state respectively
     */
    reviewAdapter = (showRelationshipToApplicant?: boolean): InfoCardData => {
        const schema: InfoCardData = {
            name: {
                label: 'Name',
                value: `${this.firstName.value}${this.middleName.value ? ` ${this.middleName.value}` : ''} ${this.lastName.value}`
            },
            emailName: {
                label: this.email.label ?? '',
                value: this.email.value ?? ''
            },
            phone: {
                label: this.phone.label ?? '',
                value: this.phone.value ?? ''
            },
            dob: {
                label: this.dob.label ?? '',
                value: this.dob.value ?? ''
            },
            ssn: {
                label: this.ssn.label ?? '',
                value: '***-**-****'
            }
        };
        if (showRelationshipToApplicant) {
            schema.relationship = {
                label: this.relationship.label ?? '',
                value: this.relationship.value ?? ''
            };
        }
        schema.maritalStatus = {
            label: this.maritalStatus.label ?? '',
            value: this.maritalStatus.value ?? ''
        };
        return schema;
    };
}
