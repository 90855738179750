import React from 'react';

import CreditDecisionSVG from '../../assets/CreditDecision.icon';
import { CreditDecisionDisclaimerText } from '../../components/shared.styled';
interface CreditDecisionDisclaimerProps {
    dealerName: string;
}

const CreditDecisionDisclaimer: React.FC<CreditDecisionDisclaimerProps> = ({ dealerName }) => {
    const creditDecisionText =
        // eslint-disable-next-line max-len
        `${dealerName} will attempt to provide a decision in 60 seconds or less. Sometimes system availability affects the ability to make a credit decision or, in some cases, the lender needs to collect additional information. If so, it could take several hours to process your application and you wil receive further details via email.`;

    return (
        <div>
            <CreditDecisionDisclaimerText>
                <p>
                    By clicking on "Submit", I acknowledge and declare that I have read and agree with the Terms and Conditions. I
                    certify that I have provided complete and true information in this application.
                </p>
                <p>Your application will be evaluated in real time to provide an instant credit decision.</p>
            </CreditDecisionDisclaimerText>
            <CreditDecisionSVG />
            <CreditDecisionDisclaimerText className="credit-decision-disclaimer-review">
                {creditDecisionText}
            </CreditDecisionDisclaimerText>
        </div>
    );
};

export default CreditDecisionDisclaimer;
