import {
    ChangeHandler,
    IndexedChangeHandler,
    IndexedSelectFieldChangeHandler,
    LinkClickHandler,
    SelectFieldChangeHandler
} from '../../formValidator/BaseFormField';
import { allowOnlyNumbers } from '../../utils/helper';
import { Dispatcher } from '../Dispatcher';
import {
    AdditionalIncomeInputActionCreator,
    AdditionalIncomeLinkActionCreator,
    AdditionalIncomeSelectActionCreator,
    EmploymentAddressActionCreator,
    MonthsActionCreator,
    PreviousEmploymentAddressActionCreator,
    PreviousStatusActionCreator,
    PreviousStatusInputsActionCreator,
    PreviousYearsOrMonthActionCreator,
    StatusActionCreator,
    StatusInputsActionCreator,
    YearsActionCreator
} from './ActionCreators';
import { Employment } from './models/Employment.model';

export class EmploymentDispatcher extends Dispatcher<Employment> {
    updateEmploymentStatusValue: SelectFieldChangeHandler = (event) => {
        const fieldValue = event.currentTarget.value;
        const actionCreator = new StatusActionCreator(fieldValue);
        this.dispatch(actionCreator.getAction());
    };
    updateEmploymentAddressStateValue: SelectFieldChangeHandler = (event) => {
        const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;
        const actionCreator = new EmploymentAddressActionCreator(fieldValue, fieldName);
        this.dispatch(actionCreator.getAction());
    };

    updateEmploymentStatusInputs: ChangeHandler = (event) => {
        const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;
        const actionCreator = new StatusInputsActionCreator(fieldValue, fieldName);
        this.dispatch(actionCreator.getAction());
    };
    updatePreviousStatusInputs: ChangeHandler = (event) => {
        const fieldValue = event.currentTarget.value;
        const fieldName = event.currentTarget.name;
        const actionCreator = new PreviousStatusInputsActionCreator(fieldValue, fieldName);
        this.dispatch(actionCreator.getAction());
    };

    updatePreviousStatus: SelectFieldChangeHandler = (event) => {
        const fieldValue = event.currentTarget.value;
        const actionCreator = new PreviousStatusActionCreator(fieldValue);
        this.dispatch(actionCreator.getAction());
    };

    updatePreviousEmploymentAddressStateValue: SelectFieldChangeHandler = (event) => {
        const fieldName = event.currentTarget.name;
        const fieldValue = event.currentTarget.value;
        const actionCreator = new PreviousEmploymentAddressActionCreator(fieldValue, fieldName);
        this.dispatch(actionCreator.getAction());
    };

    updatePreviousYearsMonthEmployed: ChangeHandler = (event) => {
        const fieldValue = allowOnlyNumbers(event.currentTarget.value);
        const fieldName = event.currentTarget.name as 'previousYearsEmployed' | 'previousMonthsEmployed';
        const actionCreator = new PreviousYearsOrMonthActionCreator(fieldValue, fieldName);
        this.dispatch(actionCreator.getAction());
    };

    updateYearsEmployed: ChangeHandler = (event) => {
        const fieldValue = allowOnlyNumbers(event.currentTarget.value);
        const actionCreator = new YearsActionCreator(fieldValue);
        this.dispatch(actionCreator.getAction());
    };

    updateMonthsEmployed: ChangeHandler = (event) => {
        const fieldValue = allowOnlyNumbers(event.currentTarget.value);
        const actionCreator = new MonthsActionCreator(fieldValue);
        this.dispatch(actionCreator.getAction());
    };

    updateAdditionalIncomeInputHandler: IndexedChangeHandler = (event, index): void => {
        const fieldValue = event.currentTarget.value;
        const actionCreator = new AdditionalIncomeInputActionCreator(fieldValue, index);
        this.dispatch(actionCreator.getAction());
    };

    updateAdditionalIncomeSelectHandler: IndexedSelectFieldChangeHandler = (event, index): void => {
        const fieldValue = event.currentTarget.value;
        const actionCreator = new AdditionalIncomeSelectActionCreator(fieldValue, index);
        this.dispatch(actionCreator.getAction());

        // If the Shopper deselects the Additional Source of Income then empty content of Additional Monthly Income field
        if (fieldValue === '') {
            const actionCreatorForAdditionalIncomeInput = new AdditionalIncomeInputActionCreator('', index);
            this.dispatch(actionCreatorForAdditionalIncomeInput.getAction());
        }
    };

    additionalIncomeLinkHandler: LinkClickHandler = () => {
        const actionCreator = new AdditionalIncomeLinkActionCreator();
        this.dispatch(actionCreator.getAction());
    };
}
