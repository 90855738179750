import { CreditDecision } from './pages/CreditDecision/models/CreditDecision.model';
import { EmploymentInfo } from './pages/EmploymentInfo/models/EmploymentInfo.model';
import { HousingInfo } from './pages/HousingInfo/models/HousingInfo.model';
import { PersonalInfo } from './pages/PersonalInfo/models/PersonalInfo.model';
import { Review } from './pages/ReviewPage/models/Review.model';
import { Clonable } from './utils/Immer.class';

export class CreditAppState implements Clonable<CreditAppState> {
    personalInfo: PersonalInfo;
    applicantChoice: string;
    housingInfo: HousingInfo;
    employmentInfo: EmploymentInfo;
    reviewInfo: Review;
    creditDecision: CreditDecision;

    constructor(me: Partial<CreditAppState> = {}) {
        this.personalInfo = me.personalInfo ?? new PersonalInfo();
        this.applicantChoice = me.applicantChoice ?? '';
        this.housingInfo = me.housingInfo ?? new HousingInfo();
        this.employmentInfo = me.employmentInfo ?? new EmploymentInfo();
        this.reviewInfo = me.reviewInfo ?? new Review();
        this.creditDecision = me.creditDecision ?? new CreditDecision();
    }

    clone(): CreditAppState {
        return new CreditAppState({ ...this });
    }
}
