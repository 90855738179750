import React from 'react';

import { PAGES, URLPaths } from '../../types/Constants';
import InfoCard from './InfoCard';
import { ReviewFormData } from './ReviewPage';
import { Header, InfoCardBodyPadding, InfoCardWrapper, InformationWrapper, TitleWrapper } from './ReviewPage.styled';

interface ReviewPageFromProps {
    title: string;
    formData: ReviewFormData;
    className?: string;
    experience: boolean;
}

const ReviewPageForm: React.FC<ReviewPageFromProps> = ({ title, formData, className, experience }) => {
    return (
        <InformationWrapper className={className}>
            <Header className="common-header">
                <TitleWrapper>{title}</TitleWrapper>
            </Header>
            <InfoCardBodyPadding>
                <InfoCardWrapper>
                    <InfoCard
                        header="Personal Information"
                        infoCardData={formData.personalInfo}
                        urlPath={PAGES['/personal'].urlPath as URLPaths}
                    />
                </InfoCardWrapper>
                <InfoCardWrapper>
                    <InfoCard
                        header="Housing"
                        infoCardData={formData.housingInfo}
                        urlPath={PAGES['/housing'].urlPath as URLPaths}
                    />
                </InfoCardWrapper>
                <InfoCardWrapper>
                    <InfoCard
                        header="Income"
                        infoCardData={formData.employmentInfo}
                        urlPath={PAGES['/employment'].urlPath as URLPaths}
                    />
                </InfoCardWrapper>
            </InfoCardBodyPadding>
        </InformationWrapper>
    );
};

export default ReviewPageForm;
