import { validateRelationshipType, validateRelationshipTypecoApp } from '../Validation';
import { Validator } from './Validator';

export const RelationshipTypeValidator: Validator[] = [
    {
        func: validateRelationshipType,
        message: 'Please make a selection.'
    },
    {
        func: validateRelationshipTypecoApp,
        message: 'Invalid Relationship'
    }
];
