import styled from 'styled-components';

import { Button } from '@makemydeal/ui-bricks/dist/cox';

export const DecisionModal = styled.div`
    position: relative;
    display: inline-block;
    flex-direction: column;
    justify-content: space-between;
    font-weight: ${({ theme }) => theme.headerFontWeight};
    font-family: ${({ theme }) => theme.headerFontFamily};
    text-transform: ${({ theme }) => theme.headerTextTransform};
    color: ${({ theme }) => theme.primaryFontColor};

    // blur the modal background
    [data-testid='ui-bricks-modal'] {
        background-color: rgba(0, 0, 0, 0.1) !important;
        backdrop-filter: blur(5px) !important;
    }

    // Styling for Modal Width
    [data-testid='ui-bricks-modal'] > div {
        max-width: 22.75rem !important;
        padding: 0.5rem 0rem 2.0625rem 0em !important;
    }

    // Styling for Modal Title
    &.decision-modal-style > div > div > div:nth-of-type(1n) span {
        font-size: 1.75rem !important;
        padding: 1rem 1.375rem 1rem 1.625rem;
    }

    // Styling for 'Close' link
    [data-testid='ui-bricks-modal-close-button'] {
        font-size: 1.1rem !important;
        cursor: pointer !important;
    }

    [data-testid='ui-bricks-modal-close-button']:hover {
        color: ${({ theme }) => theme.primaryLinkHoverColor};
        text-decoration: ${({ theme }) => theme.primaryLinkHoverTextDecoration};
    }

    // Hide the close icon
    .close-icon {
        display: none !important;
    }
`;

export const ModalText = styled.div`
    color: rgba(0, 0, 0, 0.7) !important;
    font-weight: 400 !important;
    line-height: 1.25rem;
    padding: 0px 1.375rem 25px 1.625rem;
`;

export const DecisionModalInputWrapper = styled.div`
    padding: 15px 0.8rem 15px 0.8rem;
    background-color: #f6f6f6;
`;

export const DecisionModalInput = styled.div`
    padding: 0 !important;
`;

export const DecisionModalForm = styled.form`
    padding: 0 !important;
`;

export const SendCodeButton = styled(Button)`
    width: 95%;
    margin: 0px 8px;
    padding: 1rem 1.875rem;
`;
