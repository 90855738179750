import React from 'react';

import { Column, Row } from '../../../../components/shared.styled';
import { InputFormField, SelectFormField } from '../../../../formFields';
import { FormField } from '../../../../formValidator';
import { emptyString, findKeyFromValue } from '../../../../utils/helper';
import { Clonable } from '../../../../utils/Immer.class';
import { EmploymentStatusSelectOptions } from '../../../../utils/selectOptions';
import { InfoCardData } from '../../../Interfaces';
import { EmploymentDispatcher } from '../../Employment.dispatcher';
import { EmploymentStatus } from './EmploymentStatus';
import * as NewFields from './EmploymentStatusFields';

// this covers Retired and RetiredMilliary
export class Retired extends EmploymentStatus implements Clonable<Retired> {
    monthlyIncome: FormField.Input;

    constructor(statusValue?: string, me?: Retired) {
        super({ ...me, status: NewFields.newStatus(statusValue ?? EmploymentStatusSelectOptions.Retired) });
        this.monthlyIncome = me?.monthlyIncome ?? NewFields.newMonthlyIncome();
    }
    clone = (): Retired => {
        return new Retired(this.status.value, { ...this });
    };

    renderEmploymentStatus = (dispatcher: EmploymentDispatcher) => {
        return (
            <Row>
                <Column>
                    <SelectFormField formField={this.status} onChangeHandler={dispatcher.updateEmploymentStatusValue} />
                </Column>
                <Column>
                    <InputFormField formField={this.monthlyIncome} onChangeHandler={dispatcher.updateEmploymentStatusInputs} />
                </Column>
            </Row>
        );
    };

    reviewAdapter = (): InfoCardData => {
        return {
            employmentStatus: {
                label: this.status.label ?? '',
                value: findKeyFromValue(this.status.value, EmploymentStatusSelectOptions) ?? ''
            },
            monthlyIncome: {
                label: this.monthlyIncome.label ?? '',
                value: `$${emptyString(this.monthlyIncome.value) ? 0 : this.monthlyIncome.value}`
            }
        };
    };
}
