import React from 'react';

export const PrivacyContent = () => {
    return (
        <>
            AGREEMENT
            <p>
                <br />
                You understand and agree that you are applying for credit by providing the information to complete and submit this
                credit application. We may keep this application and any other application submitted to us and information about you
                whether or not the application is approved. You certify that the information on the application and in any other
                application submitted to us, is true and complete. You understand that false statements may subject you to criminal
                penalties.
            </p>
        </>
    );
};
