import { Cloned } from '../pages/Interfaces';

type Trimmed<T> = Omit<T, 'getTrimmed'>;

export type PureFormField<T> = Cloned<Trimmed<Omit<T, 'setValue'>>>;

interface ValidateValue {
    (value: string): boolean;
}

interface Formatter {
    (value: string, previousValue?: string | undefined): string;
}

export interface ChangeHandler {
    (event: React.SyntheticEvent<HTMLInputElement>): void;
}

export interface IndexedChangeHandler {
    (event: React.SyntheticEvent<HTMLInputElement>, index: number): void;
}

export interface SelectFieldChangeHandler {
    (event: React.ChangeEvent<HTMLSelectElement>): void;
}

export interface IndexedSelectFieldChangeHandler {
    (event: React.ChangeEvent<HTMLSelectElement>, index: number): void;
}

export interface LinkClickHandler {
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

interface RawFormField {
    /**
     * implements a function to get trimmed or clean version of a form field
     */
    getTrimmed: { (): string };
}

// encapsulating to prevent being used by mistake
export abstract class BaseFormField implements RawFormField {
    value?: string;
    label?: string;
    name?: string;
    placeholder?: string;
    tooltip?: string;
    errorMessage?: string;
    ['data-testid']?: string;
    optional?: boolean;
    hasError?: boolean;
    customRequiredLabel?: string;
    disabled?: boolean;
    // formats the value after user interacts with FormField
    formatter?: Formatter;
    validationFunction: ValidateValue;
    maxlength?: number;
    /**
     * @typeparam <FormField> builds meta data around each Model property.
     * Provide FormField - Omitting onChangeFunction from the constructor.
     * The onChangeFunction already has been implemented as part of the class
     */
    constructor(me: PureFormField<BaseFormField>) {
        this.name = me.name ?? '';
        this.placeholder = me.placeholder ?? '';
        this.tooltip = me.tooltip ?? '';
        this.value = me.value ?? '';
        this.label = me.label ?? '';
        this.errorMessage = me.errorMessage ?? '';
        this.optional = me.optional ?? true;
        this.hasError = false;
        this.customRequiredLabel = me.customRequiredLabel ?? '';
        this.disabled = me.disabled ?? false;
        this.validationFunction = (value) => me.validationFunction(value.trim());
        this.maxlength = me.maxlength ?? undefined;
    }

    /**
     * updates the FormField.value and uses the formatter if FormField provides it
     */
    setValue = (fieldValue: string) => {
        this.value = this.formatter ? this.formatter(fieldValue) : fieldValue;
        return this;
    };

    abstract getTrimmed(): string;
}
