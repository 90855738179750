import { rem } from 'polished';
import styled from 'styled-components';

export const PageSubtitle = styled.div`
    white-space: pre-line;
    font-size: ${rem(16)};
    font-weight: normal;
    line-height: 1.56;
`;

export const Disclaimer = styled.div`
    padding: 1rem 0;
`;

export const TermsAndConditionsContainer = styled.div`
    padding-top: ${rem(5)};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    h3 {
        margin: 0;
        font-weight: 700;
        line-height: ${rem(19.07)};
    }
`;

export const QuestionsDisclaimer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    & span {
        padding: 0.5rem 0;
    }
    & span:first-child {
        color: #636569;
    }
    & span:last-child {
        font-size: ${rem(16)};
    }
    & span > a {
        text-decoration: none;
        color: #0d65bf;
    }
`;
