import React from 'react';

import { DateInput } from '@makemydeal/ui-bricks/dist/cox';

import { ThemedInputWrapper } from '../components/shared.styled';
import { FormField, Validator } from '../formValidator';
import { ChangeHandler } from '../formValidator/BaseFormField';

interface DateInputFormFieldProps {
    formField: FormField.DateInput;
    onChangeHandler: ChangeHandler;
}
const DateInputFormField: React.FC<DateInputFormFieldProps> = ({ formField, onChangeHandler }) => (
    <ThemedInputWrapper>
        <DateInput
            name={formField.name ?? ''}
            placeholder={formField.placeholder}
            errorMessage={Validator.getErrorMessage(formField)}
            label={formField.label ?? ''}
            tooltip={formField.tooltip ?? ''}
            optional={formField.optional}
            value={formField.value}
            hasError={formField.hasError}
            onChangeFunction={onChangeHandler}
            validationFunction={formField.validationFunction}
            maxLength={10}
        />
    </ThemedInputWrapper>
);

export default DateInputFormField;
