import React, { Dispatch, useMemo } from 'react';
import { MemoryRouter } from 'react-router-dom';

import { CreditAppState } from '../CreditApp.model';
import { CreditAppActionTypes } from '../types/PayloadTypes';

export const CreditAppStateContext = React.createContext<CreditAppState | undefined>(undefined);
export const CreditAppDispatchContext = React.createContext<Dispatch<CreditAppActionTypes> | undefined>(undefined);

function creditAppReducer(state: CreditAppState, action: CreditAppActionTypes): CreditAppState {
    return action.mutator(action.payload, state);
}

export function CreditAppProvider(props: any) {
    const { children, ...rest } = props;
    const { injectedState, injectedDispatch } = rest;
    const store = useMemo(() => injectedState ?? new CreditAppState(), [injectedState]);
    const [state, dispatch] = React.useReducer(creditAppReducer, store);
    return (
        <CreditAppStateContext.Provider value={state}>
            <CreditAppDispatchContext.Provider value={injectedDispatch ?? dispatch}>
                <MemoryRouter>{children}</MemoryRouter>
            </CreditAppDispatchContext.Provider>
        </CreditAppStateContext.Provider>
    );
}

export function useCreditAppState(): CreditAppState {
    const context = React.useContext(CreditAppStateContext);
    if (!context) {
        throw new Error('useCreditAppState must be used within a CreditAppProvider');
    }
    return context;
}

export function useCreditAppDispatch() {
    const context = React.useContext(CreditAppDispatchContext);
    if (!context) {
        throw new Error('useCreditAppDispatch must be used within a CreditAppProvider');
    }
    return context;
}
