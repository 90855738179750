import { rem } from 'polished';
import styled from 'styled-components';

import { CommonHeader } from '../../components/shared.styled';

export const InformationWrapper = styled.div`
    width: 100%;
    height: auto;
    border-radius: ${({ theme }) => theme.creditAppInformationWrapperBorderRadius};
    background-color: ${({ theme }) => theme.creditAppInformationWrapperBackgroundColor};
`;

export const Header = styled(CommonHeader)`
    height: ${rem(57)};
    border-radius: ${({ theme }) => theme.creditAppHeaderBorderRadius};
    background-color: ${({ theme }) => theme.creditAppHeaderBackgroundColor};
    box-shadow: ${({ theme }) => theme.creditAppHeaderBoxShadow};
    border: ${({ theme }) => theme.creditAppHeaderBorder};
    font-size: ${rem(30)};
`;

export const JointInfoWrapper = styled.div`
    margin: ${rem(40)} 0 0 0;
`;
export const InfoCardWrapper = styled.div`
    margin-top: ${rem(24)};
`;

export const InfoCardBodyPadding = styled.div`
    padding: 0 ${rem(32)} ${rem(24)} ${rem(32)};
`;
export const FooterText = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.creditAppFooterTextColor};
    font-size: 0.8rem;
    margin: ${rem(24)} auto 2rem auto;
    white-space: pre-line;
    width: 70%;
`;

export const TitleWrapper = styled.div`
    display: flex;
    font-size: 1.66rem;
    align-items: center;
    padding: ${rem(12)} 0;
    margin-left: ${rem(30)};
`;
