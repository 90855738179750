import React from 'react';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatPhoneNumberString } from '@makemydeal/ui-bricks/dist/cox';

import { CreditDecisionDetails } from '../../../api/creditDecisionApi';
import {
    DECISION_APPROVED_HEADER,
    DECISION_CONDITIONALLY_APPROVED_HEADER,
    DECISION_DECLINED_HEADER,
    DECISION_PENDING_HEADER,
    DECISION_TYPE_HEADER_MAP,
    DecisionType
} from '../../../types/Constants';
import { DealerDecision } from '../../../types/ExternalResources';
import {
    CardHeader,
    CardInner,
    CardMessage,
    CardWrapper,
    DealerPhoneBlue,
    DealerPhoneBold,
    MessageDisclosure
} from './DecisionCard.styled';
import { DecisionTable } from './DecisionTable';

type Props = {
    creditDecisionDetails?: CreditDecisionDetails;
    dealerDecision?: DealerDecision;
    dealerPhone: string;
    pageType: DecisionType;
};

export const DecisionCard = (props: Props) => {
    const { creditDecisionDetails, dealerDecision, dealerPhone, pageType } = props;
    const dealerPhoneFormatted = formatPhoneNumberString(dealerPhone);
    const availableDetails = dealerDecision || creditDecisionDetails || false;
    const header = DECISION_TYPE_HEADER_MAP[pageType];

    const isApproved = header === DECISION_APPROVED_HEADER;
    const isConditionallyApproved = header === DECISION_CONDITIONALLY_APPROVED_HEADER;
    const isPending = header === DECISION_PENDING_HEADER;
    const isDeclined = header === DECISION_DECLINED_HEADER;

    return (
        <CardWrapper>
            <CardInner>
                <CardHeader>
                    {(isApproved || isConditionallyApproved) && (
                        <div className="credit-decision-confirm-header-icon">
                            <FontAwesomeIcon icon={faCheckCircle} size="2x" color="#00b900" />
                        </div>
                    )}
                    {isDeclined && (
                        <svg width="35" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_305_462)">
                                <path
                                    // eslint-disable-next-line max-len
                                    d="M8 0.75L16 15.25H0L8 0.75ZM8 11.5C7.30964 11.5 6.75 12.0596 6.75 12.75C6.75 13.4404 7.30964 14 8 14C8.69036 14 9.25 13.4404 9.25 12.75C9.25 12.0596 8.69036 11.5 8 11.5ZM8 5C7.30964 5 6.75 5.53726 6.75 6.2C6.75 6.28218 6.7586 6.36242 6.775 6.43996L7.16667 10.2C7.16667 10.6418 7.53976 11 8 11C8.46024 11 8.83333 10.6418 8.83333 10.2L9.225 6.43996C9.24139 6.36242 9.25 6.28218 9.25 6.2C9.25 5.53726 8.69036 5 8 5Z"
                                    fill="rgba(255, 152, 2, 1)"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_305_462">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    )}
                    <div className="credit-decision-confirm-header-text">
                        <div className="credit-decision-card-title">Your application {!isDeclined && ' is'}</div>
                        <div className="credit-decision-card-status">{header}</div>
                    </div>
                </CardHeader>
                <CardMessage>
                    {isApproved && !availableDetails && (
                        <MessageDisclosure>
                            You have been approved for financing!
                            <br />
                            <br />
                            Your sales representative will reach out to discuss your offer, or you can call us directly at
                            <DealerPhoneBold> {dealerPhoneFormatted}</DealerPhoneBold>.
                        </MessageDisclosure>
                    )}
                    {(isApproved || isConditionallyApproved) && availableDetails && <DecisionTable decision={availableDetails} />}
                    {isPending && (
                        <MessageDisclosure>
                            Your sales representative will reach out to discuss your loan options or you can call us directly at
                            <DealerPhoneBlue> {dealerPhoneFormatted}</DealerPhoneBlue>.
                            <br />
                            <br />
                            Some lenders may take 1 to 3 days to process your information. You will receive an email when a new
                            credit decision becomes available.
                        </MessageDisclosure>
                    )}
                    {isDeclined && (
                        <MessageDisclosure>
                            Your application has been submitted but it looks like some additional information may be required before
                            providing a decision. Your sales representative will contact you shortly or you can call the dealership
                            at <DealerPhoneBlue>{dealerPhoneFormatted}</DealerPhoneBlue>.
                        </MessageDisclosure>
                    )}
                </CardMessage>
            </CardInner>
        </CardWrapper>
    );
};
