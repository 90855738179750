import { emptyString } from '../helper';
import { CustomValidator } from './CustomValidation';

class YearAndMonthValidation extends CustomValidator {
    fieldMessage = {
        field: 'MonthAndYear',
        message: 'Total of year and months must be greater than 0'
    };
    constructor(private yearValue?: string, private monthValue?: string) {
        super();
    }
    validate(): boolean {
        if (!emptyString(this.monthValue) && !emptyString(this.yearValue)) {
            return Number(this.monthValue) + Number(this.yearValue) > 0;
        }
        return true;
    }
}

class YearMonthAgeValidation extends CustomValidator {
    fieldMessage = {
        field: 'MonthAndYear',
        message: ''
    };
    constructor(
        private yearValue?: string,
        private monthValue?: string,
        private ageMonthsValue?: number,
        private formName?: string
    ) {
        super();
    }
    responseMessages = {
        greaterThanZeroPrompt: 'Total of year and months must be greater than 0.',
        notExceedAgePrompt: `Time at ${this.formName} cannot be greater than age.`
    };

    validate(): boolean {
        if (!emptyString(this.monthValue) && !emptyString(this.yearValue) && this.ageMonthsValue) {
            const [months, years] = [Number(this.monthValue), Number(this.yearValue)];
            if (months + years <= 0) {
                this.fieldMessage.message = this.responseMessages.greaterThanZeroPrompt;
                return false;
            }
            const yearsInMonths = years * 12;
            if (yearsInMonths + months > this.ageMonthsValue) {
                this.fieldMessage.message = this.responseMessages.notExceedAgePrompt;
                return false;
            }
        }
        this.fieldMessage.message = '';
        return true;
    }
}

class PreviousYearMonthAgeValidation extends CustomValidator {
    fieldMessage = {
        field: 'MonthAndYear',
        message: ''
    };
    constructor(
        private yearValue?: string,
        private monthValue?: string,
        private ageMonthsValue?: number,
        private previousYearValue?: string,
        private previousMonthValue?: string,
        private formName?: string
    ) {
        super();
    }
    pageName = this.formName === 'address' ? 'addresses' : this.formName;
    responseMessages = {
        greaterThanZeroPrompt: 'Total of year and months must be greater than 0.',
        notExceedAgePrompt: `Time at previous ${this.formName} cannot be greater than age.`,
        sumNotExceedAgePrompt: `Sum of time at ${this.pageName} cannot be greater than age.`
    };

    validate(): boolean {
        if (!emptyString(this.previousMonthValue) && !emptyString(this.previousYearValue) && this.ageMonthsValue) {
            const [previousMonths, previousYears] = [Number(this.previousMonthValue), Number(this.previousYearValue)];
            if (previousMonths + previousYears <= 0) {
                this.fieldMessage.message = this.responseMessages.greaterThanZeroPrompt;
                return false;
            }
            const previousYearsInMonths = previousYears * 12;

            if (previousYearsInMonths + previousMonths > this.ageMonthsValue) {
                this.fieldMessage.message = this.responseMessages.notExceedAgePrompt;
                return false;
            }
            if (!emptyString(this.yearValue) && !emptyString(this.monthValue)) {
                const [months, years] = [Number(this.monthValue), Number(this.yearValue)];
                const yearsInMonths = years * 12;
                if (previousYearsInMonths + yearsInMonths + previousMonths + months > this.ageMonthsValue) {
                    this.fieldMessage.message = this.responseMessages.sumNotExceedAgePrompt;
                    return false;
                }
            }
        }
        this.fieldMessage.message = '';
        return true;
    }
}

export { YearAndMonthValidation, YearMonthAgeValidation, PreviousYearMonthAgeValidation };
