import { FormField, Validator } from '../../../formValidator';
import { Clonable } from '../../../utils/Immer.class';
import { validatedPrivacyNoticeCheckBox, validatedRegulationBCheckBox } from '../../../utils/Validation';

export class Review extends Validator implements Clonable<Review> {
    privacyNoticeIndicator: FormField.Checkbox;
    regulationBIndicator: FormField.Checkbox;

    constructor(me?: Review) {
        super();
        this.privacyNoticeIndicator =
            me?.privacyNoticeIndicator ??
            new FormField.Checkbox({
                value: 'false',
                name: 'privacyNoticeIndicator',
                label: '',
                isChecked: false,
                // eslint-disable-next-line max-len
                errorMessage: 'You have to agree on the policies are required.', // Business not required to show this message, and we don't have any place to render error message in UI bricks
                optional: false,
                validationFunction: (value) => validatedPrivacyNoticeCheckBox(value)
            });
        this.regulationBIndicator =
            me?.regulationBIndicator ??
            new FormField.Checkbox({
                value: 'false',
                name: 'regulationBIndicator',
                label: '',
                isChecked: false,
                // eslint-disable-next-line max-len
                errorMessage: 'You have to agree on the policies are required.', // Business not required to show this message, and we don't have any place to render error message in UI bricks
                optional: false,
                validationFunction: (value) => validatedRegulationBCheckBox(value)
            });
    }

    clone = (): Review => new Review({ ...this });
}
