import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Housing } from '../models/Housing.model';
import { Spouse } from '../models/Spouse.model';

export class SetSpouseActionCreator extends ActionCreator<Housing> {
    mutateField = (state: Housing) => {
        return produce(state, (draft) => {
            if (this.fieldValue === 'add') {
                draft.spouse = new Spouse();
            } else {
                draft.spouse = undefined;
            }
        });
    };
    public getAction = () => (prevState: Housing) => this.mutateField(prevState);
}
