import React from 'react';

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useCreditAppState } from '../../contexts/CreditAppContext';
import { useCreditAppExperience } from '../../contexts/CreditAppExperienceContext';
import { useExternalResourceState } from '../../contexts/ExternalResourceContext';
import { ADOBE_STANDALONE_TAGS } from '../../types/AdobeStandaloneTags';
import { Resources } from '../../types/ExternalResources';
import { constructSCADataLayer } from '../../utils/eventUtils';
import { emptyString } from '../../utils/helper';
import {
    ApplicationNumberLabel,
    BannerTitle,
    CheckmarkIcon,
    ConfirmationCardBanner,
    ConfirmationCardContainer,
    ConfirmationSummary,
    Subtitle
} from './ConfirmationPage.styled';

const ConfirmationContainer: React.FC<{ creditAppId?: string }> = ({ creditAppId }) => {
    const creditAppState = useCreditAppState();
    const externalResourceState = useExternalResourceState();

    let dealerName = '';
    let dealerPhoneNumber = '';
    const ExternalResources = externalResourceState as Resources;
    dealerName = ExternalResources.dealer?.name;
    const { isStandaloneExperience } = useCreditAppExperience();

    dealerPhoneNumber = ExternalResources.dealer?.phone;
    const email = creditAppState.personalInfo.primaryApplicant.email.value;

    const errorMessage = creditAppId ? void 0 : 'Application Submission Failed';

    const checkMarkColor = '#00b900';

    const applicationNumLbl = `Application #: ${creditAppId}`;
    if (isStandaloneExperience) {
        constructSCADataLayer(ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_SUCCESS_DISPLAYED);
        if (errorMessage) {
            constructSCADataLayer(ADOBE_STANDALONE_TAGS.DR_STANDALONE_CREDIT_APP_FAILURE_DISPLAYED);
        }

        return emptyString(errorMessage) ? (
            <ConfirmationCardContainer>
                <ConfirmationCardBanner>
                    <CheckmarkIcon>
                        <FontAwesomeIcon icon={faCheckCircle} color={checkMarkColor}></FontAwesomeIcon>
                    </CheckmarkIcon>
                    <BannerTitle>Application Submitted</BannerTitle>
                </ConfirmationCardBanner>
                <ConfirmationSummary>
                    <ApplicationNumberLabel data-testid="confirmation-application-number">
                        {applicationNumLbl}
                    </ApplicationNumberLabel>
                    <Subtitle>
                        A copy of this application has been sent to <b data-testid="confirmation-email">{email}</b>
                    </Subtitle>
                    <Subtitle>
                        Someone from <b data-testid="confirmation-dealer-name">{dealerName}</b> dealership will contact you.
                    </Subtitle>
                    <Subtitle>
                        If you have questions, give us a call at <b data-testid="confirmation-phone">{dealerPhoneNumber}</b>.
                    </Subtitle>
                </ConfirmationSummary>
            </ConfirmationCardContainer>
        ) : (
            <div data-testid="confirmation-failed-message">Application Submission Failed</div>
        );
    }
    return emptyString(errorMessage) ? (
        <ConfirmationCardContainer>
            <ConfirmationCardBanner>
                <CheckmarkIcon>
                    <FontAwesomeIcon icon={faCheckCircle} color={checkMarkColor}></FontAwesomeIcon>
                </CheckmarkIcon>
                <BannerTitle>Application Submitted</BannerTitle>
            </ConfirmationCardBanner>
            <ConfirmationSummary>
                <ApplicationNumberLabel data-testid="confirmation-application-number">{applicationNumLbl}</ApplicationNumberLabel>
                <Subtitle>
                    A copy of this application has been sent to <b data-testid="confirmation-email">{email}</b>
                </Subtitle>
                <Subtitle>
                    Someone from <b data-testid="confirmation-dealer-name">{dealerName}</b> dealership will contact you.
                </Subtitle>
                <Subtitle>
                    Questions? Call <b data-testid="confirmation-phone">{dealerPhoneNumber}</b>.
                </Subtitle>
            </ConfirmationSummary>
        </ConfirmationCardContainer>
    ) : (
        <div data-testid="confirmation-failed-message">Application Submission Failed</div>
    );
};

export default ConfirmationContainer;
