import styled from 'styled-components';

import { Select } from '@makemydeal/ui-bricks/dist/cox';
export const StyledSelect = styled(Select)`
    select {
        min-width: unset;
    }
    &:not(:focus) {
        color: ${(props) => props.theme.defaultInputFieldPlaceholderTextColor};
    }
`;
