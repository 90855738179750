import { rem } from 'polished';
import styled from 'styled-components';

export const TableItem = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 600;
    @media only screen and (max-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const TableItemTitle = styled.span`
    color: #54565a;
    font-weight: 400;
`;

export const ConfirmationSubHeader = styled.div`
    font-size: ${rem(20)};
    font-weight: 700;
    line-height: ${rem(27.24)};
`;

export const TableContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: ${rem(16)};
    gap: 10px;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;
