import React from 'react';

export enum ExperienceEnum {
    Embedded = 'Embedded',
    Standalone = 'Standalone',
    ucaEmbedded = 'UCA Embedded'
}

type ExperienceContext = {
    isEmbeddedExperience: boolean;
    isStandaloneExperience: boolean;
    isUCAEmbeddedExperience: boolean;
    experience: ExperienceEnum;
    dealerPhone: string;
    setExperience: React.Dispatch<React.SetStateAction<ExperienceEnum>>;
    setExperienceData: (experienceData: any) => void;
};

type ExperienceData = {
    experience: ExperienceEnum;
    dealerPhone?: string;
};

export const CreditAppExperienceContext = React.createContext<ExperienceContext | undefined>(undefined);

export function useCreditAppExperience() {
    const context = React.useContext(CreditAppExperienceContext);

    if (!context) {
        throw new Error('useCreditAppExperience must be used within a CreditAppExperienceProvider');
    }

    return context;
}

export function CreditAppExperienceProvider(props: any) {
    const [experience, setExperience] = React.useState<ExperienceEnum>(ExperienceEnum.Embedded);
    const [dealerPhone, setDealerPhone] = React.useState<string>('');
    const isStandaloneExperience = experience === ExperienceEnum.Standalone;
    const isEmbeddedExperience = experience === ExperienceEnum.Embedded;
    const isUCAEmbeddedExperience = experience === ExperienceEnum.ucaEmbedded;

    const setExperienceData = React.useCallback((experienceData: ExperienceData) => {
        setExperience(experienceData.experience);
        setDealerPhone(experienceData.dealerPhone ?? '');
    }, []);

    const value = {
        experience,
        dealerPhone,
        setExperience,
        setExperienceData,
        isStandaloneExperience,
        isEmbeddedExperience,
        isUCAEmbeddedExperience
    };

    return <CreditAppExperienceContext.Provider value={value}>{props.children}</CreditAppExperienceContext.Provider>;
}
