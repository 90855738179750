import React from 'react';

import { Spinner } from '@makemydeal/ui-bricks/dist/cox';

import { LoaderOverlayContainer } from './shared.styled';

interface LoaderOverlayProp {
    showMessage: boolean;
}
const LoaderOverlay: React.FC<LoaderOverlayProp> = ({ showMessage }) => {
    return (
        <LoaderOverlayContainer>
            <Spinner size={60} />
            {showMessage && (
                <div className="loadMessage">
                    Verifying Information...
                    <br />
                    Verification may take up to 10 seconds.
                    <br />
                    Please do not refresh this page.
                </div>
            )}
        </LoaderOverlayContainer>
    );
};

export default LoaderOverlay;
