import React from 'react';

import { Spinner } from '@makemydeal/ui-bricks/dist/cox';

import AccelerateLoaderAnimation from '../../components/accelerate/AccelerateLoaderAnimation';
import { PageContainer, SpinnerContainer } from '../../components/shared.styled';
import WithPageUI from '../../components/WithPageUI';
import { CREDIT_DECISION_TYPES } from '../../types/Constants';
import { DecisionEmailModal } from './components/DecisionEmailModal';
import { DecisionPinAuthModal } from './components/DecisionPinAuthModal';
import ConfirmationPage from './ConfirmationPage';
import { CreditDecisionState } from './CreditDecisionLandingPage';
import { ConfirmationPageWrapper } from './CreditDecisionLandingPage.styled';

interface LenderDecisionPageProps {
    show: boolean;
}

const LenderDecisionPage = ({ data }: { data: any }) => {
    const {
        theme,
        AccelerateGifSrc,
        handleSkipLoadingAnimation,
        showPinModal,
        showPinErrorMessage,
        savePin,
        handleClosePinModal,
        saveEmail,
        showEmailModal,
        handleCloseEmailModal,
        dealerPhone,
        userIsInactive,
        offerType,
        confirmationPageToggles,
        decisionStatus,
        enableAccelerateLoader,
        showSkipLink,
        decisionDetails,
        shopperEmail
    } = data;

    if (decisionStatus === CreditDecisionState.Loading) {
        const hideSkipCTA = confirmationPageToggles.enableCreditDecisionDetails;
        const PendingSpinnerPage = () => {
            return enableAccelerateLoader ? (
                <AccelerateLoaderAnimation
                    theme={theme}
                    gifSrc={AccelerateGifSrc}
                    onSkip={handleSkipLoadingAnimation}
                    showSkipLink={showSkipLink}
                    hideSkipCTA={hideSkipCTA}
                />
            ) : (
                <SpinnerContainer data-testid="confirmation-spinner">
                    <Spinner size={60} />
                </SpinnerContainer>
            );
        };
        const PendingSpinnerPageUI = WithPageUI(PendingSpinnerPage, { pageTitle: undefined, pageSubTitle: undefined });

        return (
            <PageContainer>
                <PendingSpinnerPageUI />
            </PageContainer>
        );
    } else if (decisionStatus === CreditDecisionState.Approved) {
        return (
            <ConfirmationPageWrapper>
                <DecisionPinAuthModal
                    show={showPinModal}
                    hasError={showPinErrorMessage}
                    handleSubmit={savePin}
                    handleClose={handleClosePinModal}
                />
                <DecisionEmailModal saveEmail={saveEmail} show={showEmailModal} handleClose={handleCloseEmailModal} />
                <ConfirmationPage
                    pageType={CREDIT_DECISION_TYPES.APPROVED_OR_CONDITIONALLY_APPROVED}
                    className={'confirmation-approved'}
                    dealerPhone={dealerPhone}
                    showModal={userIsInactive}
                    offerType={offerType}
                    toggles={confirmationPageToggles}
                    creditDecisionDetails={decisionDetails}
                    shopperEmail={shopperEmail}
                />
            </ConfirmationPageWrapper>
        );
    } else {
        // decisionStatus === 'Pending'
        return (
            <ConfirmationPageWrapper>
                <DecisionPinAuthModal
                    show={showPinModal}
                    hasError={showPinErrorMessage}
                    handleSubmit={savePin}
                    handleClose={handleClosePinModal}
                />
                <DecisionEmailModal saveEmail={saveEmail} show={showEmailModal} handleClose={handleCloseEmailModal} />
                <ConfirmationPage
                    pageType={CREDIT_DECISION_TYPES.PENDING}
                    className={'confirmation-pending'}
                    dealerPhone={dealerPhone}
                    showModal={userIsInactive}
                    offerType={offerType}
                    toggles={confirmationPageToggles}
                    shopperEmail={shopperEmail}
                />
            </ConfirmationPageWrapper>
        );
    }
};

export default LenderDecisionPage;
