import { isValidDate, validateDOB } from '../Validation';
import { Validator } from './Validator';

export const DOBValidators: Validator[] = [
    {
        func: validateDOB,
        message: 'Date must contain valid month, day and year matching mm/dd/yyyy format'
    },
    {
        func: isValidDate,
        message: 'Invalid birth day'
    }
];
