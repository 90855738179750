import { EmploymentStatusSelectOptions } from '../../../../../utils/selectOptions';
import { EmploymentStatus } from '../EmploymentStatus';
import { PreviouslyEmployed } from './PreviouslyEmployed';
import { PreviouslySelfEmployed } from './PreviouslySelfEmployed';
import { PreviouslyStudent } from './PreviouslyStudent';
import { PreviouslyUnEmployedOrRetired } from './PreviouslyUnEmployedOrRetired';

export class PreviousEmploymentFactory {
    static Build(statusValue?: string): EmploymentStatus {
        switch (statusValue) {
            case EmploymentStatusSelectOptions.Unemployed:
            case EmploymentStatusSelectOptions.Retired:
            case EmploymentStatusSelectOptions['Retired Military']:
                return new PreviouslyUnEmployedOrRetired(statusValue);
            case EmploymentStatusSelectOptions.Student:
                return new PreviouslyStudent();
            case EmploymentStatusSelectOptions['Self-Employed']:
                return new PreviouslySelfEmployed();
            default:
                // Employed, ActiveMilitary, Other
                return new PreviouslyEmployed(statusValue);
        }
    }
}
