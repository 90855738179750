import React from 'react';

import { VehicleSectionTitles } from '../../types/Constants';
import InfoCard from './InfoCard';
import { VehicleFormData } from './ReviewPage';
import { Header, InfoCardBodyPadding, InfoCardWrapper, InformationWrapper, TitleWrapper } from './ReviewPage.styled';

interface VehicleSummaryProps {
    title: string;
    formData: VehicleFormData;
    showVehicleInfoInReview: boolean;
    showTradeInInfoInReview: boolean;
    className?: string;
    showFinanceInfoInReview: boolean;
}

const VehicleSummary: React.FC<VehicleSummaryProps> = ({
    title,
    formData,
    className,
    showVehicleInfoInReview,
    showTradeInInfoInReview,
    showFinanceInfoInReview
}) => {
    return (
        <InformationWrapper className={className}>
            <Header className="common-header">
                <TitleWrapper>{title}</TitleWrapper>
            </Header>
            <InfoCardBodyPadding>
                {showVehicleInfoInReview && formData?.vehicleInfo && (
                    <InfoCardWrapper>
                        <InfoCard header={VehicleSectionTitles.VEHICLE} infoCardData={formData.vehicleInfo} />
                    </InfoCardWrapper>
                )}
                {showTradeInInfoInReview && formData?.tradeInInfo && (
                    <InfoCardWrapper>
                        <InfoCard header={VehicleSectionTitles.TRADE_IN} infoCardData={formData.tradeInInfo} />
                    </InfoCardWrapper>
                )}
                {showFinanceInfoInReview && formData?.financeInfo && (
                    <InfoCardWrapper>
                        <InfoCard header={VehicleSectionTitles.FINANCE} infoCardData={formData.financeInfo} />
                    </InfoCardWrapper>
                )}
            </InfoCardBodyPadding>
        </InformationWrapper>
    );
};

export default VehicleSummary;
