const size = {
    mobile: '320px',
    mobileM: '360px',
    mobileL: '395px',
    mobileXL: '414px',
    tablet: '480px',
    tabletL: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
};

export const devices = {
    mobile: `(max-device-width: ${size.mobile})`,
    mobileL: `(max-device-width: ${size.mobileL})`,
    mobileM: `(max-device-width: ${size.mobileM})`,
    mobileXL: `(max-device-width: ${size.mobileXL})`,
    tablet: `(max-device-width: ${size.tablet})`,
    tabletL: `(max-device-width: ${size.tabletL})`,
    laptop: `(max-device-width: ${size.laptop})`,
    laptopL: `(max-device-width: ${size.laptopL})`,
    desktop: `(max-device-width: ${size.desktop})`,
    desktopL: `(max-device-width: ${size.desktop})`,
    desktopX: '(min-width: 1025px) and (max-width: 1280px)'
};

// Mobile first design variables not tied to device width
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const screens = {
    phone: `(min-width: ${size.tablet})`,
    tablet: `(min-width: ${size.tabletL})`,
    desktop: `(min-width: ${size.laptop})`
};
