import { BaseFormField } from '../../../formValidator/BaseFormField';
import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Employment } from '../models/Employment.model';
import { Employed } from '../models/employmentStatus/Employed';

export class PreviousStatusInputsActionCreator extends ActionCreator<Employment> {
    constructor(fieldValue: string, private fieldName: string) {
        super(fieldValue);
    }
    mutateField = (state: Employment) => {
        return produce(state, (draft) => {
            (((draft.employmentStatus as Employed)?.previousEmployment as any)[this.fieldName] as BaseFormField).setValue(
                this.fieldValue
            );
        });
    };
    public getAction = () => (prevState: Employment) => this.mutateField(prevState);
}
