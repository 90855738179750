import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Employment } from '../models/Employment.model';

export class StatusInputsActionCreator extends ActionCreator<Employment> {
    constructor(fieldValue: string, private fieldName: string) {
        super(fieldValue);
    }
    mutateField = (state: Employment) => {
        return produce(state, (draft) => {
            (draft.employmentStatus as any)[this.fieldName].setValue(this.fieldValue);
        });
    };
    public getAction = () => (prevState: Employment) => this.mutateField(prevState);
}
