import { rem } from 'polished';
import styled from 'styled-components';

import { devices } from '../../utils/deviceUtils';

export const YearMonthRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    min-height: ${({ theme }) => theme.defaultInputFieldMinHeight || 'auto'};
    margin-top: ${rem(8)};
`;

export const YearCol = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    margin-right: 0.625rem;

    @media ${devices.tablet} {
        flex-direction: column;
        margin-right: 0rem;
        min-width: 160px;
    }
    @media ${devices.mobileL} {
        min-width: 160px;
    }
    @media ${devices.mobileM} {
        min-width: 110px;
    }
`;

export const MonthCol = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    margin-left: 0.625rem;

    @media ${devices.tablet} {
        flex-direction: column;
        margin-left: 0px;
    }
`;

export const YearMonthContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & label[for='yearMonthRow'] {
        margin-left: 10px;
    }
    @media ${devices.tablet} {
        flex-direction: column;
    }
    @media ${(devices.mobile, devices.mobileL)} {
        width: 100%;
    }
`;

export const ErrorMessageWrapper = styled.div`
    margin-top: ${rem(-15)};
`;
