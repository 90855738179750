import React from 'react';

import { Column, Row } from '../../../../components/shared.styled';
import { SelectFormField } from '../../../../formFields';
import { findKeyFromValue } from '../../../../utils/helper';
import { Clonable } from '../../../../utils/Immer.class';
import { EmploymentStatusSelectOptions } from '../../../../utils/selectOptions';
import { InfoCardData } from '../../../Interfaces';
import { EmploymentDispatcher } from '../../Employment.dispatcher';
import { EmploymentStatus } from './EmploymentStatus';
import * as NewFields from './EmploymentStatusFields';

export class UnEmployed extends EmploymentStatus implements Clonable<UnEmployed> {
    constructor(me?: UnEmployed) {
        super({ ...me, status: NewFields.newStatus(EmploymentStatusSelectOptions.Unemployed) });
    }
    clone = (): UnEmployed => {
        return new UnEmployed({ ...this });
    };
    renderEmploymentStatus = (dispatcher: EmploymentDispatcher) => {
        return (
            <Row>
                <Column>
                    <SelectFormField formField={this.status} onChangeHandler={dispatcher.updateEmploymentStatusValue} />
                </Column>
                <Column />
            </Row>
        );
    };

    reviewAdapter = (): InfoCardData => {
        return {
            employmentStatus: {
                label: this.status.label ?? '',
                value: findKeyFromValue(this.status.value, EmploymentStatusSelectOptions) ?? ''
            }
        };
    };
}
