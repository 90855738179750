/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch } from 'react';

import { Resources } from '../types/ExternalResources';
import { ExternalResourceActionTypes } from '../types/PayloadTypes';

type ExternalResources = Resources | {};

const ExternalResourceStateContext = React.createContext<ExternalResources>({});
const ExternalResourceDispatchContext = React.createContext<Dispatch<ExternalResourceActionTypes> | undefined>(undefined);

function externalResourceReducer(state: ExternalResources, action: ExternalResourceActionTypes): ExternalResources {
    return action.mutator(action.payload, state as Resources);
}

export function ExternalResourceProvider(props: any) {
    const { children, ...rest } = props;
    const { injectedState, injectedDispatch } = rest;

    const [state, dispatch] = React.useReducer(externalResourceReducer, {});
    return (
        <ExternalResourceStateContext.Provider value={injectedState ?? state}>
            <ExternalResourceDispatchContext.Provider value={injectedDispatch ?? dispatch}>
                {children}
            </ExternalResourceDispatchContext.Provider>
        </ExternalResourceStateContext.Provider>
    );
}

export function useExternalResourceState(): ExternalResources {
    const context = React.useContext(ExternalResourceStateContext);
    if (!context) {
        throw new Error('useExternalResourceState must be used within a ExternalResourceProvider');
    }
    return context;
}

function useExternalResourceDispatch() {
    const context = React.useContext(ExternalResourceDispatchContext);
    if (!context) {
        throw new Error('useExternalResourceDispatch must be used within a ExternalResourceProvider');
    }
    return context;
}
