import React from 'react';

import { Checkbox } from '@makemydeal/ui-bricks/dist/cox';

import { ThemedInputWrapper } from '../components/shared.styled';
import { FormField } from '../formValidator';
import { ChangeHandler } from '../formValidator/BaseFormField';

interface CheckboxFormFieldProps {
    formField: FormField.Checkbox;
    onChangeHandler: ChangeHandler;
}

const CheckboxFormField: React.FC<CheckboxFormFieldProps> = ({ formField, onChangeHandler }) => (
    <ThemedInputWrapper>
        <Checkbox
            data-testid="checkbox"
            label={formField.label ?? ''}
            name={formField.name}
            checked={formField.value === 'true'}
            onChange={onChangeHandler}
            hasError={formField.hasError}
        />
    </ThemedInputWrapper>
);

export default CheckboxFormField;
