import React from 'react';

import { formatPhoneNumberString } from '@makemydeal/ui-bricks/dist/cox';

import { CreditDecisionDetails } from '../../api/creditDecisionApi';
import { PageContainer } from '../../components/shared.styled';
import WithPageUI from '../../components/WithPageUI';
import { APPROVED_DECISION_TYPES, TERMS_AND_CONDITIONS_CONTENT, TERMS_AND_CONDITIONS_HEADER } from '../../types/Constants';
import { DecisionType } from '../../types/Constants';
import { DealerDecision } from '../../types/ExternalResources';
import { getCreditDecisionData } from '../../utils/getCreditDecisionData';
import { ConfirmationCard } from './components/ConfirmationCard';
import { DecisionCard } from './components/DecisionCard';
import { Disclaimer, PageSubtitle, QuestionsDisclaimer, TermsAndConditionsContainer } from './ConfirmationPage.styled';

interface ConditionalToggles {
    isAccelerateCreditDecisionEnabled: boolean;
    enableAppliedLenderInfo: boolean;
    enableCreditDecisionDetails: boolean;
}
interface ConfirmationPageProps {
    className: string;
    pageType: DecisionType;
    disableScrollToTop?: boolean;
    dealerPhone: string;
    showModal: boolean;
    toggles: ConditionalToggles;
    offerType: string;
    dealerDecision?: DealerDecision;
    creditDecisionDetails?: CreditDecisionDetails;
    shopperEmail: string;
}

const ConfirmationPage = React.memo((props: ConfirmationPageProps) => {
    const { pageType, dealerPhone, showModal, toggles, offerType, dealerDecision, creditDecisionDetails, shopperEmail } = props;
    const isAccelerateCreditDecisionEnabled = toggles.isAccelerateCreditDecisionEnabled ?? false;
    const enableAppliedLenderInfo = toggles.enableAppliedLenderInfo ?? false;
    const enableCreditDecisionDetails = toggles.enableCreditDecisionDetails;
    const togglesCondition = !!(isAccelerateCreditDecisionEnabled && enableAppliedLenderInfo);
    const shouldRenderEnhancedVersion = enableCreditDecisionDetails;
    const renderTaCOnFooter = shouldRenderEnhancedVersion && APPROVED_DECISION_TYPES.findIndex((t) => t === pageType) !== -1;
    const dealerPhoneFormatted = formatPhoneNumberString(dealerPhone);
    const cardProps = {
        dealerPhone,
        togglesCondition,
        offerType,
        dealerDecision
    };

    const creditDecisionDataProps = {
        shouldRenderEnhancedVersion,
        shopperEmail,
        pageType
    };

    const PageData = getCreditDecisionData(creditDecisionDataProps);
    const { pageTitle, pageTitleDesc1 } = PageData;
    const pageSubTitle = pageTitleDesc1 ? <PageSubtitle>{pageTitleDesc1}</PageSubtitle> : undefined;
    const renderDisclaimer = shouldRenderEnhancedVersion
        ? () => {
              return (
                  <Disclaimer>
                      {renderTaCOnFooter && (
                          <TermsAndConditionsContainer data-testid="terms-and-conditions-in-disclaimer">
                              <h3>{TERMS_AND_CONDITIONS_HEADER}</h3>
                              <span>{TERMS_AND_CONDITIONS_CONTENT}</span>
                          </TermsAndConditionsContainer>
                      )}
                      <QuestionsDisclaimer data-testid="questions-disclaimer">
                          <span>Have a question about your credit decision?</span>
                          <span>
                              Call us at <a href={`tel:${dealerPhone}`}>{dealerPhoneFormatted}</a>
                          </span>
                      </QuestionsDisclaimer>
                  </Disclaimer>
              );
          }
        : undefined;

    const ConfirmationPageUI = () => {
        return shouldRenderEnhancedVersion ? (
            <DecisionCard
                dealerPhone={dealerPhone}
                pageType={pageType}
                creditDecisionDetails={creditDecisionDetails}
                dealerDecision={dealerDecision}
            />
        ) : (
            <ConfirmationCard PageData={PageData} {...cardProps} />
        );
    };

    const ConfirmationPage = WithPageUI(ConfirmationPageUI, {
        pageTitle,
        pageSubTitle,
        renderDisclaimer
    });

    // As Firefox does not supports backdrop-filter we use this conditional rendering to display the blur effect
    // Applies a filter to get the same that backdrop∫
    // https://rally1.rallydev.com/#/104883497140d/backlog?detail=%2Fdefect%2F602027998667
    if (showModal) {
        return (
            <PageContainer
                style={{
                    filter: 'blur(5px)',
                    backgroundColor: 'rgba(0, 0, 0, 0.1)'
                }}
            >
                <ConfirmationPage />
            </PageContainer>
        );
    } else {
        return (
            <PageContainer>
                <ConfirmationPage />
            </PageContainer>
        );
    }
});

export default ConfirmationPage;
