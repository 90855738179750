const AdobeTagConfiguration = {
    DR_CREDIT_APP_DISPLAYED: {
        tag: 'DR_CREDIT_APP_DISPLAYED',
        desc: 'Automatic loading of credit app page.'
    },
    DR_CREDIT_APP_STARTED: {
        tag: 'DR_CREDIT_APP_STARTED',
        desc: 'Credit App Personal Info Page'
    },
    DR_CREDIT_APP_PERSONALINFO_PAGE_DISPLAYED: {
        tag: 'DR_CREDIT_APP_PERSONALINFO_PAGE_DISPLAYED',
        desc: 'Credit App Personal Info Page'
    },
    DR_CREDIT_APP_PERSONALINFO_RETURN_CLICKED: {
        tag: 'DR_CREDIT_APP_PERSONALINFO_RETURN_CLICKED',
        desc: 'Credit App Housing Page'
    },
    DR_CREDIT_APP_EDIT_PERSONAL_CLICKED: {
        tag: 'DR_CREDIT_APP_EDIT_PERSONAL_CLICKED',
        desc: 'Credit App Review Page'
    },
    DR_CREDIT_APP_PERSONAL_CTA_CLICKED: {
        tag: 'DR_CREDIT_APP_PERSONAL_CTA_CLICKED',
        desc: 'Credit App Personal Info Page'
    },
    DR_CREDIT_APP_HOUSING_PAGE_DISPLAYED: {
        tag: 'DR_CREDIT_APP_HOUSING_PAGE_DISPLAYED',
        desc: 'Credit App Housing Page'
    },
    DR_CREDIT_APP_HOUSING_RETURN_CLICKED: {
        tag: 'DR_CREDIT_APP_HOUSING_RETURN_CLICKED',
        desc: 'Credit App Housing Page'
    },
    DR_CREDIT_APP_HOUSING_CTA_CLICKED: {
        tag: 'DR_CREDIT_APP_HOUSING_CTA_CLICKED',
        desc: 'Credit App Housing Page'
    },
    DR_CREDIT_APP_EDIT_HOUSING_CLICKED: {
        tag: 'DR_CREDIT_APP_EDIT_HOUSING_CLICKED',
        desc: 'Credit App Review Page'
    },
    DR_CREDIT_APP_EMPLOYMENT_INFO_DISPLAYED: {
        tag: 'DR_CREDIT_APP_EMPLOYMENT_INFO_DISPLAYED',
        desc: 'Credit App Employment Page'
    },
    DR_CREDIT_APP_EMPLOYMENT_CTA_CLICKED: {
        tag: 'DR_CREDIT_APP_EMPLOYMENT_CTA_CLICKED',
        desc: 'Credit App Employment Page'
    },
    DR_CREDIT_APP_EMPLOYMENT_RETURN_CLICKED: {
        tag: 'DR_CREDIT_APP_EMPLOYMENT_RETURN_CLICKED',
        desc: 'Credit App Employment Page'
    },
    DR_CREDIT_APP_EDIT_EMPLOYMENT_CLICKED: {
        tag: 'DR_CREDIT_APP_EDIT_EMPLOYMENT_CLICKED',
        desc: 'Credit App Review Page'
    },
    DR_CREDIT_APP_REVIEW_PAGE_DISPLAYED: {
        tag: 'DR_CREDIT_APP_REVIEW_PAGE_DISPLAYED',
        desc: 'Credit App Review Page'
    },
    DR_CREDIT_APP_SUBMITTED: {
        tag: 'DR_CREDIT_APP_SUBMITTED',
        desc: 'Accelerate Embedded Credit App'
    },
    DR_CREDIT_APP_SUBMIT_CTA_CLICKED: {
        tag: 'DR_CREDIT_APP_SUBMIT_CTA_CLICKED',
        desc: 'Accelerate Embedded Credit App Submission Started'
    },
    DR_CREDIT_APP_DECISION_PAGE_DISPLAYED: {
        tag: 'DR_CREDIT_APP_DECISION_PAGE_DISPLAYED',
        desc: 'User opened Credit Decision page. Decision: APPROVED/PENDING'
    },
    DR_CREDIT_APP_DECISION_WAIT_PAGE_CONTINUE_TO_NEXT_STEPS_CLICKED: {
        tag: 'DR_CREDIT_APP_DECISION_WAIT_PAGE_CONTINUE_TO_NEXT_STEPS_CLICKED',
        desc: 'User clicked Continue to Next Steps instead of waiting for decision.'
    }
};

type AdobeTags = keyof typeof AdobeTagConfiguration;
export type AdobeTag = {
    tag: string;
    desc: string;
};

type AdobeTagData = {
    [prop in AdobeTags]: AdobeTag;
};

export const ADOBE_TAGS = AdobeTagConfiguration as AdobeTagData;
