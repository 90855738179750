import axios from 'axios';
import { useEffect, useRef, useState } from 'react';

import { xml2json } from '../utils/helper';

export interface FetchCityStateZipInfo {
    state: string;
    city: string;
    zip: string;
}

interface USPSCityStateZip {
    CityStateLookupResponse: {
        ZipCode: {
            Error?: {
                Number: number;
                Source: string;
                Description: string;
            };
            Zip5?: string;
            City?: string;
            State?: string;
        };
    };
}

/**
 * @function useFetchCityState
 * @param zip
 *
 * useFetchCityState handles fetching and parsing data from USPS API
 * The purpose is so we cross validate the zip and state fields on the Housing info form
 *
 */
export const useFetchCityState = (zip?: string) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [data, setData] = useState<FetchCityStateZipInfo | any>(zip || undefined); // @param: 'zip' default value
    const lastEvalZipRef = useRef<string | undefined>();

    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);
                if (zip !== lastEvalZipRef.current) {
                    lastEvalZipRef.current = zip;
                    if (zip && zip.length === 5) {
                        // eslint-disable-next-line max-len
                        const url = `https://secure.shippingapis.com/ShippingAPI.dll?API=CityStateLookup&XML=<CityStateLookupRequest USERID="527COXAU0093"><ZipCode ID='0'><Zip5>${zip}</Zip5></ZipCode></CityStateLookupRequest>`;
                        const result = await axios.get(url);
                        const parser = new DOMParser();
                        const srcDom = parser.parseFromString(result.data, 'application/xml');
                        const json = xml2json(srcDom) as USPSCityStateZip;

                        const zipResult = json.CityStateLookupResponse.ZipCode;
                        if (zipResult) {
                            if (zipResult.Error) {
                                // we have an error from the API
                                setData(undefined);
                                setError(new Error(zipResult.Error.Description));
                            } else {
                                // this object gets passed into ZipAndStateValidation constructor for custom validation.
                                setData({
                                    zip: zipResult.Zip5 as string,
                                    state: zipResult.State as string,
                                    city: zipResult.City as string
                                });
                                setError(undefined);
                            }
                        } else {
                            setData(undefined);
                            setError(new Error('Result not in correct format.'));
                        }
                    } else {
                        // zip can't be less than 5, so no need to call API
                        setData(undefined);
                        setError(new Error('Zip Code not 5 characters'));
                    }
                }
            } catch (e) {
                setError(e as Error);
                setData(undefined); // if there is an error, then no data
            } finally {
                setLoading(false);
            }
        };
        fetch();
    });

    return [{ data, loading, error }];
};
