import { KeyValuePair } from './../types/KeyValuePair';

const REGEX = {
    DEALER: '^.+/dealer/([0-9]+)([?&=.A-z0-9-]+)?',
    QUERY_STRINGS: '[?&]+([^=&]+)=([^&]*)'
};
export const getUrlVars = (): KeyValuePair => {
    const queryStringVars: KeyValuePair = {};
    const pathParamVars: KeyValuePair = {};

    // Check for querystring params
    window.location.href.replace(new RegExp(REGEX.QUERY_STRINGS, 'ig'), function(m, key, value) {
        queryStringVars[key] = value;
        return '';
    });

    // Check for path parameters
    if (window.location.pathname) {
        const dealerStandAlonePattern = new RegExp(REGEX.DEALER, 'ig');
        const results = dealerStandAlonePattern.exec(window.location.href);
        if (results && results[1]) {
            pathParamVars.dealerId = results[1];
        }
    }
    const vars = { ...queryStringVars };
    if (pathParamVars) {
        Object.entries(pathParamVars).forEach((entry, index) => {
            const key = entry[0];
            const value = entry[1];
            if (!queryStringVars[key]) {
                vars[key] = value;
            }
        });
    }
    return vars;
};

export const getUrlParam = (parameter: string) => {
    let urlParameter = '';
    if (window.location.href.indexOf(parameter) > -1) {
        urlParameter = getUrlVars()[parameter];
    }
    return urlParameter;
};
