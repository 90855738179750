import { FormField } from '../../../../../formValidator';
import { EmploymentStatusSelectOptions, states } from '../../../../../utils/selectOptions';
import {
    validateAddress,
    validateCity,
    validateEmployerName,
    validateEmploymentStatus,
    validateMonth,
    validateState,
    validateSuiteApartmentNumber,
    validateYear,
    validateZipCode
} from '../../../../../utils/Validation';

const previousEmploymentStatus = (value: string) =>
    new FormField.Select({
        name: 'previousEmploymentStatus',
        placeholder: 'Select',
        label: 'Previous Employment Status',
        optional: false,
        selectOptions: EmploymentStatusSelectOptions,
        errorMessage: '',
        value,
        validationFunction: validateEmploymentStatus
    });
const previousEmployerName = () =>
    new FormField.Input({
        name: 'previousEmployerName',
        placeholder: "Employer's Name",
        label: "Employer's Name",
        optional: false,
        errorMessage: 'Previous Employer Name is required.',
        validationFunction: validateEmployerName,
        maxlength: 30,
        tooltip: 'The maximum length for this field is 30 characters'
    });
const previousStudentEmployerName = () =>
    new FormField.Input({
        name: 'previousEmployerName',
        placeholder: "School's Name",
        label: 'School’s Name',
        optional: false,
        tooltip:
            // eslint-disable-next-line max-len
            'We require proof of enrollment in an accredited educational institution. The maximum length for this field is 30 characters',
        errorMessage: 'Employer Name must be longer than 2 Characters and only 30 allowed',
        validationFunction: validateEmployerName,
        maxlength: 30
    });
const newPreviousSchoolAddress = () =>
    new FormField.Input({
        name: 'previousSchoolAddress',
        placeholder: 'e.g. 1800 Main Street',
        label: "School's Street Address",
        optional: true,
        errorMessage:
            // eslint-disable-next-line max-len
            'Street Address must contain a street number and a street name that is no longer than 6 numbers and 20 characters.',
        validationFunction: validateAddress,
        maxlength: 60,
        tooltip: 'The maximum length for this field is 60 characters'
    });
const newPreviousEmployerAddress = () =>
    new FormField.Input({
        name: 'previousEmployerAddress',
        placeholder: 'e.g. 1800 Main Street',
        label: "Employer's Street Address",
        optional: true,
        errorMessage:
            // eslint-disable-next-line max-len
            'Street Address must contain a street number and a street name that is no longer than 6 numbers and 20 characters.',
        validationFunction: validateAddress,
        maxlength: 60,
        tooltip: 'The maximum length for this field is 60 characters'
    });
const newPreviousSuiteNumber = () =>
    new FormField.Input({
        name: 'previousSuiteNumber',
        placeholder: 'e.g. Suite 101',
        label: 'Suite Number',
        optional: true,
        errorMessage:
            // eslint-disable-next-line max-len
            'Suite/ Apartment Number must contain a suite/apartment number that is no longer than 5 numbers and 5 characters.',
        validationFunction: validateSuiteApartmentNumber,
        maxlength: 60,
        tooltip:
            // eslint-disable-next-line max-len
            'The maximum length for this field is 60 characters. Suite number maximum length is 5.'
    });
const newPreviousCity = () =>
    new FormField.Input({
        name: 'previousCity',
        placeholder: 'e.g. Atlanta',
        label: 'City',
        optional: true,
        errorMessage: 'Must be a valid city name',
        validationFunction: validateCity,
        maxlength: 20,
        tooltip: 'The maximum length for this field is 20 characters'
    });
const newPreviousState = () =>
    new FormField.Select({
        name: 'previousState',
        placeholder: 'State',
        label: 'State',
        optional: true,
        selectOptions: states,
        errorMessage: 'Please select a state',
        validationFunction: validateState
    });
const newPreviousZip = () =>
    new FormField.Input({
        name: 'previousZip',
        placeholder: 'e.g. 30339',
        label: 'Zip',
        optional: true,
        errorMessage: 'Enter a valid 5 digit zip code',
        validationFunction: validateZipCode,
        maxlength: 5
    });
const newPreviousYearsEmployed = () =>
    new FormField.Input({
        name: 'previousYearsEmployed',
        placeholder: 'Years',
        label: '',
        customRequiredLabel: 'Required. Enter an amount from 0-99.',
        errorMessage: 'Enter an amount from 0 to 99.',
        maxlength: 2,
        optional: false,

        validationFunction: validateYear
    });

const newPreviousMonthsEmployed = () =>
    new FormField.Input({
        name: 'previousMonthsEmployed',
        placeholder: 'Months',
        label: '',
        customRequiredLabel: 'Required. Enter an amount from 0-11.',
        errorMessage: 'Enter an amount from 0 to 11.',
        maxlength: 2,
        optional: false,
        validationFunction: validateMonth
    });

const newPreviousOccupation = () =>
    new FormField.Input({
        name: 'previousOccupation',
        placeholder: 'e.g. Engineer',
        label: 'Occupation',
        optional: false,
        errorMessage: 'Enter your primary role with the employer',
        validationFunction: () => true,
        maxlength: 26,
        tooltip: 'Your Job Title or Role at your Employer'
    });

export {
    previousEmploymentStatus,
    previousEmployerName,
    previousStudentEmployerName,
    newPreviousYearsEmployed,
    newPreviousMonthsEmployed,
    newPreviousSchoolAddress,
    newPreviousEmployerAddress,
    newPreviousSuiteNumber,
    newPreviousCity,
    newPreviousState,
    newPreviousZip,
    newPreviousOccupation
};
