import React from 'react';

import { Input } from '@makemydeal/ui-bricks/dist/cox';

import { InputIcon, ThemedInputWrapper } from '../components/shared.styled';
import { FormField, Validator } from '../formValidator';
import { ChangeHandler } from '../formValidator/BaseFormField';

interface InputFormFieldProps {
    id?: string;
    formField: FormField.Input;
    onChangeHandler: ChangeHandler;
    disabled?: boolean;
    icon?: string;
    classname?: string;
}
const InputFormField: React.FC<InputFormFieldProps> = ({ id, formField, onChangeHandler, disabled, icon, classname }) => (
    <ThemedInputWrapper>
        <Input
            className={classname}
            inputId={id}
            name={formField.name ?? ''}
            placeholder={formField.placeholder}
            errorMessage={Validator.getErrorMessage(formField)}
            label={formField.label ?? ''}
            tooltip={formField.tooltip ?? ''}
            optional={formField.optional}
            value={formField.value}
            hasError={formField.hasError}
            disabled={disabled ?? formField.disabled}
            onChangeFunction={onChangeHandler}
            validationFunction={formField.validationFunction}
            maxlength={formField.maxlength ?? undefined}
        />
        {icon && formField.value && <InputIcon>{icon}</InputIcon>}
    </ThemedInputWrapper>
);

export default InputFormField;
