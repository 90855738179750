import { rem } from 'polished';
import styled from 'styled-components';

import { CommonHeader } from '../../components/shared.styled';
import { devices } from '../../utils/deviceUtils';

export const LandingPageBodyContainer = styled.div`
    background-color: ${({ theme }) => theme.creditAppLandingPageBodyContainerBackgroundColor};
    border-radius: ${({ theme }) => theme.creditAppLandingPageBodyContainerBorderRadius};
    @media ${devices.desktop && devices} {
        padding: 0.75rem 1rem;
    }
    @media ${devices.tablet && devices.tabletL} {
        padding: 1.5rem 2rem;
        flex: 0.5;
    }
`;

export const LandingPageBodyTitle = styled(CommonHeader)`
    font-size: 1.3125rem;
    @media ${devices.tablet} {
        font-size: 1rem;
    }
`;

export const InfoList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 0.625rem;
    @media ${devices.desktopX} {
        flex-direction: row;
    }
`;

export const InfoListItem = styled.span`
    color: ${({ theme }) => theme.creditAppInfoListItemColor};
    font-size: 1rem;
    flex-direction: row;
    white-space: nowrap;
    line-height: 1.81;
    @media ${devices.mobile} {
        font-size: 0.9;
    }
    @media ${devices.tablet && devices.tabletL} {
        flex-direction: column;
    }
`;

export const ApplicantChoice = styled.div`
    margin-top: 2.5rem;
    border-radius: 0.5rem;
    margin-bottom: 5.25rem;
    justify-content: space-between;
`;

export const ChoiceHeader = styled(CommonHeader)`
    font-size: 1.125rem;
    margin-bottom: 0.9375rem;
`;

export const ApplicantInfo = styled.div`
    padding: 0.625rem;
`;
export const CheckIcon = styled.div`
    color: ${(props) => props.theme.primaryButtonColor};
    display: inline-block;
    padding-right: 0.5rem;
`;

export const LandingPageDisclaimerText = styled.div`
	@media ${devices.mobile} {
		margin: ${rem(-40)} ${rem(10)} ${rem(-20)} ${rem(10)};
	}
	@media ${devices.mobileL} {
		margin: ${rem(-50)} ${rem(10)} ${rem(-5)} ${rem(10)};
	}
	@media ${devices.desktop} {
		margin: -4rem 0 2.25rem 0;
	}
	color: ${({ theme }) => theme.creditAppLandingPageDisclaimerTextColor};
	font-size: ${rem(12.8)};
	margin: ${rem(-40)} ${rem(10)} ${rem(-40)} ${rem(10)};
}
`;
