import React, { useState } from 'react';

import { faCheckCircle, faCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDollars } from '@makemydeal/dr-common-utils';
import { formatPhoneNumberString } from '@makemydeal/ui-bricks/dist/cox';

import { useExternalResourceState } from '../../../contexts/ExternalResourceContext';
import {
    CREDIT_DECISION_TYPES,
    DEALER_DECISION_TYPES,
    lenderCardTitles,
    OFFER_TYPES,
    TERMS_AND_CONDITIONS_CONTENT,
    TERMS_AND_CONDITIONS_HEADER
} from '../../../types/Constants';
import { Resources } from '../../../types/ExternalResources';
import {
    ConfirmationDecisionCardInfo,
    ConfirmationDecisionCardWrapper,
    ConfirmationMessage,
    ConfirmationSubHeader,
    ConfirmationTitleHeader,
    DecisionTACContent,
    DecisionTACHeader,
    LoanInfo,
    ReadMore,
    ReadMoreWrapper,
    TACContent
} from './ConfirmationCard.styled';

interface ApprovedInfoCard {
    lenderName: string;
    type: any;
    sellRate: string;
    // dealComments: string;
    term: string;
    amount?: string;
    dueSigning?: any;
}

interface DeclinedInfoCard {
    status: any;
    type: any;
    vehicle: string;
    // dealComments: string;
}

interface PendingInfoCard {
    status: any;
    type: any;
    vehicle: string;
}

interface CardInfoMap {
    financeCardInfo: ApprovedInfoCard;
    leaseCardInfo: ApprovedInfoCard;
    pendingCardInfo: PendingInfoCard;
    declinedCardInfo: DeclinedInfoCard;
}

export const ConfirmationCard = React.memo((props: any) => {
    const { dealerPhone, PageData, togglesCondition, offerType, dealerDecision } = props;
    const [imageFetchError, setImageFetchError] = useState(false);
    const dealerPhoneFormatted = formatPhoneNumberString(dealerPhone);
    const { pageType, cardHeaderText1, cardHeaderText2, cardDetailText1, cardDetailText2 } = PageData;
    const externalResourcesState = useExternalResourceState();
    const [isTACExpanded, setIsTACExpanded] = useState(false);
    const enableLenderLogos = (externalResourcesState as Resources).toggles?.toggles?.enableLenderLogos;
    const vehicleYear = (externalResourcesState as Resources).offer?.vehicle?.year;
    const vehicleMake = (externalResourcesState as Resources).offer?.vehicle?.make;
    const vehicleModel = (externalResourcesState as Resources).offer?.vehicle?.model;
    const vehicleTrim = (externalResourcesState as Resources).offer?.vehicle?.trim;
    const vehicleYearMakeModel = `${vehicleYear} ${vehicleMake} ${vehicleModel} ${vehicleTrim}`;
    const lenderLogoUrl = dealerDecision?.creditDecisionLenderLogo;

    const isApproved = pageType === DEALER_DECISION_TYPES.APPROVED;
    const isConditionallyApproved = pageType === DEALER_DECISION_TYPES.CONDITIONALLY_APPROVED;
    const isPending = pageType === DEALER_DECISION_TYPES.PENDING;
    const isDeclined = pageType === DEALER_DECISION_TYPES.DECLINED;

    const isApprovedAndFinanceAndTogglesEnabled =
        togglesCondition && isApproved && offerType === OFFER_TYPES.FINANCE && 'financeCardInfo';

    const isConditionallyApprovedAndFinanceAndTogglesEnabled =
        togglesCondition && isConditionallyApproved && offerType === OFFER_TYPES.FINANCE && 'financeCardInfo';

    const isApprovedAndLeaseAndTogglesEnabled =
        togglesCondition && isApproved && offerType === OFFER_TYPES.LEASE && 'leaseCardInfo';

    const isConditionallyApprovedAndLeaseAndTogglesEnabled =
        togglesCondition && isConditionallyApproved && offerType === OFFER_TYPES.LEASE && 'leaseCardInfo';

    const isPendingAndTogglesEnabled = togglesCondition && isPending && 'pendingCardInfo';

    const isDeclinedAndTogglesEnabled = togglesCondition && isDeclined && 'declinedCardInfo';

    const moneyFactorToAPRFormatter = (): string => {
        if (offerType === 'lease' && dealerDecision) {
            return dealerDecision.creditDecisionRateType === 'MoneyFactor'
                ? `${(dealerDecision.creditDecisionSellRate * 2400).toFixed(2)}%`
                : `${dealerDecision.creditDecisionSellRate.toFixed(2)}%`;
        }
        return '';
    };

    const cardInfoMap: CardInfoMap = {
        financeCardInfo: {
            lenderName: dealerDecision?.creditDecisionLenderName,
            type: offerType,
            amount: formatDollars(dealerDecision?.creditDecisionAmount),
            term: `${dealerDecision?.creditDecisionTerm}/Mo`,
            sellRate: `${dealerDecision?.creditDecisionSellRate}%`
        },
        leaseCardInfo: {
            lenderName: dealerDecision?.creditDecisionLenderName,
            type: offerType,
            dueSigning: formatDollars(dealerDecision?.creditDecisionDueAtSigning),
            term: `${dealerDecision?.creditDecisionTerm}/Mo`,
            sellRate: moneyFactorToAPRFormatter()
        },
        pendingCardInfo: {
            status: dealerDecision?.creditDecisionStatus,
            type: offerType,
            vehicle: vehicleYearMakeModel
        },
        declinedCardInfo: {
            status: dealerDecision?.creditDecisionStatus,
            type: offerType,
            vehicle: vehicleYearMakeModel
        }
    };

    const financeCardInfo: any =
        (isApprovedAndFinanceAndTogglesEnabled || isConditionallyApprovedAndFinanceAndTogglesEnabled) &&
        cardInfoMap.financeCardInfo;
    const leaseCardInfo: any =
        (isApprovedAndLeaseAndTogglesEnabled || isConditionallyApprovedAndLeaseAndTogglesEnabled) && cardInfoMap.leaseCardInfo;

    const pendingCardInfo: any = isPendingAndTogglesEnabled && cardInfoMap.pendingCardInfo;
    const declinedCardInfo: any = isDeclinedAndTogglesEnabled && cardInfoMap.declinedCardInfo;

    const handleImageFetchError = () => {
        setImageFetchError(true);
    };

    switch (pageType) {
        case DEALER_DECISION_TYPES.APPROVED: {
            return (
                <ConfirmationDecisionCardWrapper className="confirmation-decision-card-wrapper">
                    <ConfirmationDecisionCardInfo>
                        <ConfirmationTitleHeader>
                            <div className="credit-decision-confirm-header-icon">
                                <FontAwesomeIcon icon={faCheckCircle} size={'2x'} color="#00b900" />
                            </div>

                            <div className="credit-decision-confirm-header-text">
                                <div className="credit-decision-card-title">{cardHeaderText1}</div>
                                <div className="credit-decision-card-status">{cardHeaderText2}</div>
                            </div>

                            {enableLenderLogos && !imageFetchError && lenderLogoUrl ? (
                                <div className="credit-decision-confirm-header-lender">
                                    <div className="credit-decision-confirm-header-lender-logo">
                                        <img
                                            src={lenderLogoUrl}
                                            width="auto"
                                            height="auto"
                                            onError={handleImageFetchError}
                                            alt="lenderLogo"
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </ConfirmationTitleHeader>

                        {(isApprovedAndFinanceAndTogglesEnabled || isApprovedAndLeaseAndTogglesEnabled) && (
                            <ConfirmationMessage>
                                <div className="majorContainer">
                                    {Object.keys(financeCardInfo || leaseCardInfo).map((item: any, index: any) => {
                                        return (
                                            <div key={index} className="minorContainer">
                                                <span className="title-lender-card-items">{lenderCardTitles[item]}</span>
                                                <span> {financeCardInfo[item] || leaseCardInfo[item]}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="credit-decision-message-disclosure text1">
                                    <hr></hr>
                                    {cardDetailText1}
                                    <span className="credit-decision-dealer-phone">{dealerPhoneFormatted}</span>
                                    {cardDetailText2}
                                </div>
                            </ConfirmationMessage>
                        )}
                    </ConfirmationDecisionCardInfo>
                </ConfirmationDecisionCardWrapper>
            );
        }
        case DEALER_DECISION_TYPES.CONDITIONALLY_APPROVED: {
            return (
                <ConfirmationDecisionCardWrapper className="confirmation-decision-card-wrapper">
                    <ConfirmationDecisionCardInfo>
                        <ConfirmationTitleHeader>
                            <div className="credit-decision-confirm-header-icon">
                                <FontAwesomeIcon icon={faCheckCircle} size={'2x'} color="#00b900" />
                            </div>

                            <div className="credit-decision-confirm-header-text">
                                <div className="credit-decision-card-title">{cardHeaderText1}</div>
                                <div className="credit-decision-card-status">{cardHeaderText2}</div>
                            </div>

                            {enableLenderLogos && !imageFetchError && lenderLogoUrl ? (
                                <div className="credit-decision-confirm-header-lender">
                                    <div className="credit-decision-confirm-header-lender-logo">
                                        <img
                                            src={lenderLogoUrl}
                                            width="auto"
                                            height="auto"
                                            onError={handleImageFetchError}
                                            alt="lenderLogo"
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </ConfirmationTitleHeader>

                        {(isConditionallyApprovedAndFinanceAndTogglesEnabled ||
                            isConditionallyApprovedAndLeaseAndTogglesEnabled) && (
                            <ConfirmationMessage>
                                <div className="majorContainer">
                                    {Object.keys(financeCardInfo || leaseCardInfo).map((item: any, index: any) => {
                                        return (
                                            <div key={index} className="minorContainer">
                                                <span className="title-lender-card-items">{lenderCardTitles[item]}</span>
                                                <span> {financeCardInfo[item] || leaseCardInfo[item]}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="credit-decision-message-disclosure text1">
                                    <hr></hr>
                                    {cardDetailText1}
                                    <span className="credit-decision-dealer-phone">{dealerPhoneFormatted}</span>
                                    {cardDetailText2}
                                </div>
                            </ConfirmationMessage>
                        )}
                    </ConfirmationDecisionCardInfo>
                </ConfirmationDecisionCardWrapper>
            );
        }
        case DEALER_DECISION_TYPES.PENDING:
            return (
                <ConfirmationDecisionCardWrapper className="confirmation-decision-card-wrapper">
                    <ConfirmationDecisionCardInfo>
                        <ConfirmationTitleHeader>
                            <div className="credit-decision-confirm-header-icon fa-layers fa-fw fa-lg fa-2x">
                                <FontAwesomeIcon icon={faCircle} color="#f2ae3c" />
                                <FontAwesomeIcon icon={faExclamationTriangle} color="#161719" transform="shrink-9 up-1.0" />
                            </div>

                            <div className="credit-decision-confirm-header-text">
                                <div className="credit-decision-card-title">{cardHeaderText1}</div>
                                <div className="credit-decision-card-status">{cardHeaderText2}</div>
                            </div>
                        </ConfirmationTitleHeader>

                        {isPendingAndTogglesEnabled && (
                            <ConfirmationMessage>
                                <div className="majorContainer">
                                    {Object.keys(pendingCardInfo).map((item: any, index: any) => {
                                        return (
                                            <div key={index} className="minorContainer">
                                                <span className="title-lender-card-items">{lenderCardTitles[item]}</span>
                                                <span> {pendingCardInfo[item]}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="credit-decision-message-disclosure text1">
                                    <hr></hr>
                                    {cardDetailText1}
                                    <span className="credit-decision-dealer-phone">{dealerPhoneFormatted}</span>.
                                </div>
                            </ConfirmationMessage>
                        )}
                    </ConfirmationDecisionCardInfo>
                </ConfirmationDecisionCardWrapper>
            );

        case DEALER_DECISION_TYPES.DECLINED: {
            return (
                <ConfirmationDecisionCardWrapper className="confirmation-decision-card-wrapper">
                    <ConfirmationDecisionCardInfo>
                        <ConfirmationTitleHeader>
                            <div className="credit-decision-confirm-header-icon fa-layers fa-fw fa-lg fa-2x">
                                <FontAwesomeIcon icon={faCircle} color="#f2ae3c" />
                                <FontAwesomeIcon icon={faExclamationTriangle} color="#161719" transform="shrink-9 up-1.0" />
                            </div>

                            <div className="credit-decision-confirm-header-text">
                                <div className="credit-decision-card-title">{cardHeaderText1}</div>
                                <div className="credit-decision-card-status">{cardHeaderText2}</div>
                            </div>
                        </ConfirmationTitleHeader>

                        {isDeclinedAndTogglesEnabled && (
                            <ConfirmationMessage>
                                <div className="majorContainer">
                                    {Object.keys(declinedCardInfo).map((item: any, index: any) => {
                                        return (
                                            <div key={index} className="minorContainer">
                                                <span className="title-lender-card-items">{lenderCardTitles[item]}</span>
                                                <span> {declinedCardInfo[item]}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="credit-decision-message-disclosure text1">
                                    <hr></hr>
                                    {cardDetailText1}
                                    <span className="credit-decision-dealer-phone">{dealerPhoneFormatted}</span>.
                                </div>
                            </ConfirmationMessage>
                        )}
                    </ConfirmationDecisionCardInfo>
                </ConfirmationDecisionCardWrapper>
            );
        }
        case CREDIT_DECISION_TYPES.PENDING: {
            return (
                <ConfirmationDecisionCardWrapper
                    className="confirmation-decision-card-wrapper"
                    data-testid="confirmation-decision-card-wrapper"
                >
                    <ConfirmationDecisionCardInfo>
                        <ConfirmationTitleHeader>
                            <div className="credit-decision-confirm-header-icon">
                                <FontAwesomeIcon icon={faCheckCircle} size={'2x'} color="#00b900" />
                            </div>

                            <div className="credit-decision-confirm-header-text">
                                <div className="credit-decision-card-title">{cardHeaderText1}</div>
                                <div className="credit-decision-card-status">{cardHeaderText2}</div>
                            </div>
                        </ConfirmationTitleHeader>

                        <ConfirmationMessage>
                            <div className="credit-decision-message-disclosure text1">
                                {cardDetailText1}
                                <span className="credit-decision-dealer-phone">{dealerPhoneFormatted}</span>.
                            </div>
                        </ConfirmationMessage>

                        <ConfirmationSubHeader>Details</ConfirmationSubHeader>
                        <LoanInfo>
                            <div className="decision-confirmation-details-wrapper">
                                <div className="decision-confirmation-details-status-label">Status:</div>
                                <div className="decision-confirmation-details-status-value">{'Pending'}</div>
                            </div>
                            <div className="decision-confirmation-finance-type-wrapper">
                                <div className="decision-confirmation-finance-type-label">Finance Type:</div>
                                <div className="decision-confirmation-finance-type-value">{'Finance'}</div>
                            </div>
                            {(externalResourcesState as Resources).offer?.vehicle && (
                                <div className="decision-confirmation-vehicle-info-wrapper">
                                    <div className="decision-confirmation-vehicle-info-label">Vehicle:</div>
                                    <div className="decision-confirmation-vehicle-info-value">{vehicleYearMakeModel}</div>
                                </div>
                            )}
                        </LoanInfo>
                    </ConfirmationDecisionCardInfo>
                </ConfirmationDecisionCardWrapper>
            );
        }
        case CREDIT_DECISION_TYPES.APPROVED_OR_CONDITIONALLY_APPROVED:
        default: {
            return (
                <ConfirmationDecisionCardWrapper className="confirmation-decision-card-wrapper">
                    <ConfirmationDecisionCardInfo>
                        <ConfirmationTitleHeader>
                            <div className="credit-decision-confirm-header-icon">
                                <FontAwesomeIcon icon={faCheckCircle} size={'2x'} color="#00b900" />
                            </div>

                            <div className="credit-decision-confirm-header-text">
                                <div className="credit-decision-card-title">{cardHeaderText1}</div>
                                <div className="credit-decision-card-status">{cardHeaderText2}</div>
                            </div>
                        </ConfirmationTitleHeader>
                        <ConfirmationMessage>
                            <div className="credit-decision-message-disclosure text1">
                                {cardDetailText1}
                                <span className="credit-decision-dealer-phone">{dealerPhoneFormatted}</span>.
                            </div>
                            <div className="decision-tac">
                                <DecisionTACContent>
                                    <DecisionTACHeader className="tac-header">{TERMS_AND_CONDITIONS_HEADER}</DecisionTACHeader>
                                    <TACContent
                                        data-testid="tac-content-container"
                                        className={isTACExpanded ? 'tac-content-expanded' : 'tac-content-hidden'}
                                    >
                                        {TERMS_AND_CONDITIONS_CONTENT}
                                    </TACContent>
                                </DecisionTACContent>
                                <ReadMoreWrapper>
                                    <ReadMore onClick={() => setIsTACExpanded(!isTACExpanded)}>Read More</ReadMore>
                                </ReadMoreWrapper>
                            </div>
                        </ConfirmationMessage>
                    </ConfirmationDecisionCardInfo>
                </ConfirmationDecisionCardWrapper>
            );
        }
    }
});
