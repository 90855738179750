import { ORIGIN_URLS } from './originUrls';

// the Lambda Url
export const getLambdaUrl = () => {
    if (window.location.href.includes('localhost')) {
        if (process.env.REACT_APP_LAMBDA_EXPRESS) {
            return 'http://localhost:3001/api/creditApp';
        } else {
            return 'https://secure-dev.accelerate.dealer.com/api/creditApp';
        }
    } else {
        return '/api/creditApp';
    }
};

export const getCreditDecisionLambdaUrl = () =>
    window.location.href.includes('localhost')
        ? 'https://secure-dev.accelerate.dealer.com/api/creditDecision'
        : '/api/creditDecision';

export const getOrigin = (env: string) => {
    return ORIGIN_URLS[env];
};
