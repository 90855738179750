import React, { Component } from 'react';

import ErrorPage from './pages/ErrorPage/ErrorPage';

class ErrorBoundary extends Component<{}, { hasError: boolean }> {
    state = { hasError: false };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: Error): void {
        (window as any).NREUM.noticeError(error);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
