import { produce } from '../../../utils/Immer.class';
import { AdditionalIncome } from '../models/AdditionalIncome.model';
import { Employment } from '../models/Employment.model';

export class AdditionalIncomeLinkActionCreator {
    mutateField = (state: Employment) => {
        return produce(state, (draft) => {
            draft.additionalIncome.push(new AdditionalIncome());
        });
    };
    public getAction = () => (prevState: Employment) => this.mutateField(prevState);
}
