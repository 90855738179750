import './App.css';
import React, { useCallback, useEffect } from 'react';

import { KeyValuePair } from './types/KeyValuePair';
import { getOrigin } from './utils/externalUrls';

type IFramedAppProp = {
    urlParams: KeyValuePair;
    logger: { (message?: any, ...optionalParams: any[]): void };
};
const IFramedApp: React.FC<IFramedAppProp> = ({ logger, urlParams }) => {
    const env = urlParams.iframed;
    const iframeUrlParams = new URLSearchParams(urlParams);
    iframeUrlParams.delete('iframed');
    const handleIframeTask = useCallback(
        (e: any) => {
            if (e.data.sourceId === 'CREDIT_APP')
                logger('date received from iframe: ', { context: e.data.context, message: e.data.message });
        },
        [logger]
    );
    useEffect(() => {
        window.addEventListener('message', handleIframeTask, false);
        return () => {
            window.removeEventListener('message', handleIframeTask, false);
        };
    }, [handleIframeTask]);
    return (
        <>
            <div style={{ padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Credit App
                <span style={{ padding: '2px', fontWeight: 'bold' }}>{env}</span>
                Environment Inside An Iframe
            </div>
            <iframe
                title="Accelerate Credit App"
                src={`${getOrigin(env)}?${iframeUrlParams}`}
                frameBorder="1"
                height="900px"
                width="100%"
            />
        </>
    );
};

export default IFramedApp;
