import axios from 'axios';

import { Dealer } from '../types/ExternalResources';
import { getCreditDecisionLambdaUrl } from '../utils/externalUrls';

export async function resendPin(shadowCreditAppId: string, email: string) {
    const url = `${getCreditDecisionLambdaUrl()}/sendPin`;
    const result = await axios.post(url, {
        shadowCreditAppId,
        email
    });

    return result.data;
}

export async function validatePin(email: string, pin: string) {
    const url = `${getCreditDecisionLambdaUrl()}/validatePin`;
    const body = { email, pin };

    return await axios.post(url, body);
}

interface CreditDecisionInfoParams {
    isEmbedded: boolean;
    shadowCreditAppId: string;
    dealerId: string;
    sessionId: string;
    spAssetsVersion: string;
}

export interface CreditDecisionDetails {
    approvedAmount: number;
    approvedRate: number;
    approvedTerm: number;
    calculatedMonthlyPayment: number;
    downPayment: number;
    financeMethod: string;
    lenderName: string;
    lenderId: string;
}

interface CreditDecisionInfo {
    status: 'Approved' | 'Pending';
    dealer: Dealer;
    creditDecisionDetails?: CreditDecisionDetails;
    finalDecision?: boolean;
}

export async function getCreditDecisionInfo(options: CreditDecisionInfoParams): Promise<CreditDecisionInfo> {
    let url = getCreditDecisionLambdaUrl();

    url += `?shadowCreditAppId=${options.shadowCreditAppId}`;
    url += `&dealerId=${options.dealerId}`;

    if (options.isEmbedded) {
        url += `&sessionId=${options.sessionId}`;
        url += `&spAssetsVersion=${options.spAssetsVersion}`;
    }

    const { data } = await axios(url);

    return data.body;
}
