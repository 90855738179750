import { DealerDecision } from '../../types/ExternalResources';

// use when you have one validator against multiple fields that cannot be validated by model
export abstract class CustomValidator {
    public abstract fieldMessage: { field: string; message: string };
    abstract validate(): boolean;
    getErrorMessage(): string {
        return this.validate() ? '' : this.fieldMessage.message;
    }
}

export const isValidDealerDecision = (decision: unknown) => {
    if (!decision || typeof decision !== 'object') return false;
    const {
        creditDecisionEnabled,
        creditDecisionAmount,
        creditDecisionMonthlyPayment,
        creditDecisionTerm
    } = decision as DealerDecision;

    if (!creditDecisionEnabled) return false;
    if (!creditDecisionMonthlyPayment) return false;
    if (!creditDecisionAmount) return false;
    if (!creditDecisionTerm) return false;
    return true;
};
