import React, { useCallback, useEffect } from 'react';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SubmitButton } from './shared.styled';

export enum ButtonState {
    DEFAULT = 'default',
    SUCCESS = 'success',
    SENDING = 'sending'
}

interface SubmitButtonProp {
    onClick: () => void;
    onSuccess: () => void;
    label: string;
    state: ButtonState;
}
const SubmitLeadButton: React.FC<SubmitButtonProp> = ({ onClick, label, state, onSuccess }) => {
    // buttonState
    const [buttonValue, setButtonValue] = React.useState(<>{label}</>);
    const [buttonState, setButtonState] = React.useState(ButtonState.DEFAULT);

    const successState = useCallback(() => {
        setButtonValue(<FontAwesomeIcon icon={faCheck} />);
        setButtonState(ButtonState.SUCCESS);
        setTimeout(onSuccess, 500); // just want to wait to show user something submitted
    }, [onSuccess]);

    const sendingState = useCallback(
        (buttonStateProp: ButtonState) => {
            if (buttonStateProp === ButtonState.SENDING) {
                setButtonValue(<>Sending...</>);
                setButtonState(ButtonState.SENDING);
                setTimeout(() => successState(), 500);
            }
        },
        [successState]
    );

    useEffect(() => {
        sendingState(state);
    }, [state, sendingState]);

    return (
        <SubmitButton
            onClick={() => {
                if (buttonState === ButtonState.DEFAULT) onClick(); // only send once
            }}
            status={buttonState}
        >
            {buttonValue}
        </SubmitButton>
    );
};

export default SubmitLeadButton;
