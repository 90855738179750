import React, { useState } from 'react';

import { Input, Modal } from '@makemydeal/ui-bricks/dist/cox';

import { ThemedInputWrapper } from '../../../components/shared.styled';
import { validateEmail } from '../../../utils/Validation';
import {
    DecisionModal,
    DecisionModalForm,
    DecisionModalInputWrapper,
    ModalText,
    SendCodeButton
} from './DecisionEmailModal.styled';

export interface DecisionEmailModalProps {
    show: boolean;
    saveEmail: (email: string) => void;
    handleClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const DecisionEmailModal: React.FC<DecisionEmailModalProps> = React.memo((props: any) => {
    const { saveEmail, show, handleClose } = props;
    const [email, setEmail] = useState('');

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { value } = e.currentTarget;

        setEmail(value);
    }

    async function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();

        saveEmail(email);
    }

    const emailText =
        // eslint-disable-next-line max-len
        'Enter the email address you provided when you submitted your credit application. If you do not have access to this email, please call our dealership to confirm your identity and receive this information.';

    return (
        show && (
            <DecisionModal className="decision-modal-style">
                <Modal
                    modalHeader={'Verify Your Identity'}
                    closeButtonText={'Back to Enter Code'}
                    handleOpenClose={handleClose}
                    height={'400'}
                    isCtaButton={false}
                >
                    <ModalText>{emailText}</ModalText>
                    <ThemedInputWrapper>
                        <DecisionModalInputWrapper>
                            <DecisionModalForm onSubmit={handleSubmit}>
                                <Input
                                    value={email}
                                    onChangeFunction={handleEmailChange}
                                    type="email"
                                    label={''}
                                    name={'decision_email'}
                                    placeholder={'Enter Email'}
                                    inputmode="email"
                                    validationFunction={validateEmail}
                                    errorMessage={'Invalid email. Make sure the email address is correct.'}
                                />
                                <SendCodeButton
                                    className="decision-modal-button-send"
                                    type="submit"
                                    disabled={!validateEmail(email)}
                                >
                                    Send Code
                                </SendCodeButton>
                            </DecisionModalForm>
                        </DecisionModalInputWrapper>
                    </ThemedInputWrapper>
                </Modal>
            </DecisionModal>
        )
    );
});
