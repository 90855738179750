import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { IPageUIProps, PageUI } from '@makemydeal/dr-activities-common';

import { useCreditAppExperience } from '../contexts/CreditAppExperienceContext';
import { useExternalResourceState } from '../contexts/ExternalResourceContext';
import { useAdobeTag } from '../customHooks/UseAdobeTag.hook';
import { StepperRoutes } from '../pages/Interfaces';
import { PAGES, URLPaths } from '../types/Constants';
import { Resources } from '../types/ExternalResources';
import { constructSCADataLayer, sendAccelerateInfoRecordCreateStatus, sendAdobeEvents } from '../utils/eventUtils';
import ScrollToTop from '../utils/ScrollToTop';
import { getUrlVars } from '../utils/urlParameters';

const stepperRoutes = Object.values(PAGES).reduce((acc, route) => {
    if (route.hasStepper === true) {
        acc.push({
            name: route.name,
            route: route.urlPath
        });
    }
    return acc;
}, [] as StepperRoutes[]);

const WithPageUI = (Comp: any, rest?: Partial<IPageUIProps> | any) => {
    const usePageUI = () => {
        const externalResourcesState = useExternalResourceState();
        const isCreditDecisionEnabled = (externalResourcesState as Resources)?.dealer?.isAccelerateCreditDecisionEnabled;
        const experience = (externalResourcesState as Resources)?.dealer?.isStandaloneCreditAppEnabled;
        const { isStandaloneExperience } = useCreditAppExperience();
        const history = useHistory();
        const location = useLocation();
        const params = getUrlVars();
        const stepFromPath = location.pathname as URLPaths;
        // eslint-disable-next-line no-prototype-builtins
        const scrollToTopDisabled = rest && rest.hasOwnProperty('disableScrollToTop') && rest.disableScrollToTop === true;
        const hasNoStepper = Object.values(PAGES).some((page) => !page.hasStepper && page.urlPath === location.pathname);
        const creditAppWasInitialized =
            stepFromPath === '/' && (externalResourcesState as Resources)?.['credit-app-version'] !== undefined;
        // This event trigger analytics when transitioning from one page to other as part of page render life cycle.
        useAdobeTag(
            PAGES[stepFromPath].adobeDisplayed,
            PAGES[stepFromPath].adobeStandaloneDisplayed,
            PAGES[stepFromPath].urlPath,
            params.sessionId
        );

        if (creditAppWasInitialized && !isStandaloneExperience) {
            sendAccelerateInfoRecordCreateStatus();
        }

        const displayCreditDecisionSubTitle = !isStandaloneExperience && isCreditDecisionEnabled;

        let subtitle = PAGES[stepFromPath].pageSubTitle;
        if (displayCreditDecisionSubTitle && PAGES[stepFromPath].urlPath === '/') {
            subtitle = PAGES[stepFromPath].creditDecisionPageSubTitle;
        }

        return scrollToTopDisabled ? (
            <PageUI
                key={stepFromPath}
                pageClass={PAGES[stepFromPath].pageClass}
                pageTitle={PAGES[stepFromPath].pageTitle}
                pageSubTitle={subtitle}
                urlPath={location.pathname}
                goToRoute={(target) => {
                    const adobeStepperBackNavigation = PAGES[target as URLPaths].adobeStepperNavigation;
                    if (experience) {
                        if (adobeStepperBackNavigation) constructSCADataLayer(adobeStepperBackNavigation);
                    } else {
                        if (adobeStepperBackNavigation) sendAdobeEvents(adobeStepperBackNavigation);
                    }
                    history.push(target);
                }}
                stepperRoutes={hasNoStepper ? [] : stepperRoutes}
                {...rest}
            >
                <Comp {...rest} {...{ location }} {...{ params }} />
            </PageUI>
        ) : (
            <ScrollToTop>
                <PageUI
                    key={stepFromPath}
                    pageClass={PAGES[stepFromPath].pageClass}
                    pageTitle={PAGES[stepFromPath].pageTitle}
                    pageSubTitle={subtitle}
                    urlPath={location.pathname}
                    goToRoute={(target) => {
                        const adobeStepperBackNavigation = PAGES[target as URLPaths].adobeStepperNavigation;
                        const adobeStandaloneStepperBackNavigation = PAGES[target as URLPaths].adobeStandaloneStepperNavigation;

                        if (experience) {
                            if (adobeStandaloneStepperBackNavigation) {
                                constructSCADataLayer(adobeStandaloneStepperBackNavigation);
                            }
                        } else {
                            if (adobeStepperBackNavigation) sendAdobeEvents(adobeStepperBackNavigation);
                        }
                        history.push(target);
                    }}
                    stepperRoutes={hasNoStepper ? [] : stepperRoutes}
                    {...rest}
                >
                    <Comp {...rest} {...{ location }} {...{ params }} />
                </PageUI>
            </ScrollToTop>
        );
    };
    return usePageUI;
};

export default WithPageUI;
