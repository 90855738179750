import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Housing } from '../models/Housing.model';

export class UpdateSpouseInfoActionCreator extends ActionCreator<Housing> {
    constructor(fieldValue: string, private fieldName: string) {
        super(fieldValue);
    }
    mutateField = (state: Housing) => {
        return produce(state, (draft) => {
            const depthMap = this.fieldName.split('.');
            let ref = (draft as any).spouse;
            depthMap.forEach((name) => (ref = ref[name]));
            ref.setValue(this.fieldValue);
        });
    };
    public getAction = () => (prevState: Housing) => this.mutateField(prevState);
}
