export const communityPropertyZipCodes = [
    '35004',
    '35005',
    '35006',
    '35007',
    '35010',
    '35011',
    '35013',
    '35014',
    '35015',
    '35016',
    '35019',
    '35020',
    '35021',
    '35022',
    '35023',
    '35031',
    '35032',
    '35033',
    '35034',
    '35035',
    '35036',
    '35038',
    '35040',
    '35041',
    '35042',
    '35043',
    '35044',
    '35045',
    '35046',
    '35048',
    '35049',
    '35051',
    '35052',
    '35053',
    '35054',
    '35055',
    '35056',
    '35057',
    '35058',
    '35060',
    '35061',
    '35062',
    '35063',
    '35064',
    '35068',
    '35070',
    '35071',
    '35072',
    '35073',
    '35074',
    '35077',
    '35078',
    '35079',
    '35080',
    '35082',
    '35083',
    '35085',
    '35087',
    '35089',
    '35091',
    '35094',
    '35096',
    '35097',
    '35098',
    '35111',
    '35112',
    '35114',
    '35115',
    '35116',
    '35117',
    '35118',
    '35119',
    '35120',
    '35121',
    '35123',
    '35124',
    '35125',
    '35126',
    '35127',
    '35128',
    '35130',
    '35131',
    '35133',
    '35135',
    '35136',
    '35137',
    '35139',
    '35142',
    '35143',
    '35144',
    '35146',
    '35147',
    '35148',
    '35149',
    '35150',
    '35151',
    '35160',
    '35161',
    '35171',
    '35172',
    '35173',
    '35175',
    '35176',
    '35178',
    '35179',
    '35180',
    '35181',
    '35182',
    '35183',
    '35184',
    '35185',
    '35186',
    '35187',
    '35188',
    '35201',
    '35202',
    '35203',
    '35204',
    '35205',
    '35206',
    '35207',
    '35208',
    '35209',
    '35210',
    '35211',
    '35212',
    '35213',
    '35214',
    '35215',
    '35216',
    '35217',
    '35218',
    '35219',
    '35220',
    '35221',
    '35222',
    '35223',
    '35224',
    '35225',
    '35226',
    '35228',
    '35229',
    '35230',
    '35231',
    '35232',
    '35233',
    '35234',
    '35235',
    '35236',
    '35237',
    '35238',
    '35240',
    '35242',
    '35243',
    '35244',
    '35245',
    '35246',
    '35249',
    '35253',
    '35254',
    '35255',
    '35259',
    '35260',
    '35261',
    '35263',
    '35266',
    '35277',
    '35278',
    '35279',
    '35280',
    '35281',
    '35282',
    '35283',
    '35285',
    '35286',
    '35287',
    '35288',
    '35289',
    '35290',
    '35291',
    '35292',
    '35293',
    '35294',
    '35295',
    '35296',
    '35297',
    '35298',
    '35299',
    '35401',
    '35402',
    '35403',
    '35404',
    '35405',
    '35406',
    '35407',
    '35440',
    '35441',
    '35442',
    '35443',
    '35444',
    '35446',
    '35447',
    '35448',
    '35449',
    '35452',
    '35453',
    '35456',
    '35457',
    '35458',
    '35459',
    '35460',
    '35461',
    '35462',
    '35463',
    '35464',
    '35466',
    '35468',
    '35469',
    '35470',
    '35471',
    '35473',
    '35474',
    '35475',
    '35476',
    '35477',
    '35478',
    '35480',
    '35481',
    '35482',
    '35485',
    '35486',
    '35487',
    '35490',
    '35491',
    '35501',
    '35502',
    '35503',
    '35504',
    '35540',
    '35541',
    '35542',
    '35543',
    '35544',
    '35545',
    '35546',
    '35548',
    '35549',
    '35550',
    '35551',
    '35552',
    '35553',
    '35554',
    '35555',
    '35559',
    '35560',
    '35563',
    '35564',
    '35565',
    '35570',
    '35571',
    '35572',
    '35573',
    '35574',
    '35575',
    '35576',
    '35577',
    '35578',
    '35579',
    '35580',
    '35581',
    '35582',
    '35584',
    '35585',
    '35586',
    '35587',
    '35592',
    '35593',
    '35594',
    '35601',
    '35602',
    '35603',
    '35609',
    '35610',
    '35611',
    '35612',
    '35613',
    '35614',
    '35615',
    '35616',
    '35617',
    '35618',
    '35619',
    '35620',
    '35621',
    '35622',
    '35630',
    '35631',
    '35632',
    '35633',
    '35634',
    '35640',
    '35643',
    '35645',
    '35646',
    '35647',
    '35648',
    '35649',
    '35650',
    '35651',
    '35652',
    '35653',
    '35654',
    '35660',
    '35661',
    '35662',
    '35670',
    '35671',
    '35672',
    '35673',
    '35674',
    '35677',
    '35699',
    '35739',
    '35740',
    '35741',
    '35742',
    '35744',
    '35745',
    '35746',
    '35747',
    '35748',
    '35749',
    '35750',
    '35751',
    '35752',
    '35754',
    '35755',
    '35756',
    '35757',
    '35758',
    '35759',
    '35760',
    '35761',
    '35762',
    '35763',
    '35764',
    '35765',
    '35766',
    '35767',
    '35768',
    '35769',
    '35771',
    '35772',
    '35773',
    '35774',
    '35775',
    '35776',
    '35801',
    '35802',
    '35803',
    '35804',
    '35805',
    '35806',
    '35807',
    '35808',
    '35809',
    '35810',
    '35811',
    '35812',
    '35813',
    '35814',
    '35815',
    '35816',
    '35824',
    '35893',
    '35894',
    '35895',
    '35896',
    '35897',
    '35898',
    '35899',
    '35901',
    '35902',
    '35903',
    '35904',
    '35905',
    '35906',
    '35907',
    '35950',
    '35951',
    '35952',
    '35953',
    '35954',
    '35956',
    '35957',
    '35958',
    '35959',
    '35960',
    '35961',
    '35962',
    '35963',
    '35964',
    '35966',
    '35967',
    '35968',
    '35971',
    '35972',
    '35973',
    '35974',
    '35975',
    '35976',
    '35978',
    '35979',
    '35980',
    '35981',
    '35983',
    '35984',
    '35986',
    '35987',
    '35988',
    '35989',
    '35990',
    '36003',
    '36005',
    '36006',
    '36008',
    '36009',
    '36010',
    '36013',
    '36015',
    '36016',
    '36017',
    '36020',
    '36022',
    '36023',
    '36024',
    '36025',
    '36026',
    '36027',
    '36028',
    '36029',
    '36030',
    '36031',
    '36032',
    '36033',
    '36034',
    '36035',
    '36036',
    '36037',
    '36038',
    '36039',
    '36040',
    '36041',
    '36042',
    '36043',
    '36045',
    '36046',
    '36047',
    '36048',
    '36049',
    '36051',
    '36052',
    '36053',
    '36054',
    '36057',
    '36061',
    '36062',
    '36064',
    '36065',
    '36066',
    '36067',
    '36068',
    '36069',
    '36071',
    '36072',
    '36075',
    '36078',
    '36079',
    '36080',
    '36081',
    '36082',
    '36083',
    '36087',
    '36088',
    '36089',
    '36091',
    '36092',
    '36093',
    '36101',
    '36102',
    '36103',
    '36104',
    '36105',
    '36106',
    '36107',
    '36108',
    '36109',
    '36110',
    '36111',
    '36112',
    '36113',
    '36114',
    '36115',
    '36116',
    '36117',
    '36118',
    '36119',
    '36120',
    '36121',
    '36123',
    '36124',
    '36125',
    '36130',
    '36131',
    '36132',
    '36133',
    '36134',
    '36135',
    '36140',
    '36141',
    '36142',
    '36177',
    '36191',
    '36201',
    '36202',
    '36203',
    '36204',
    '36205',
    '36206',
    '36207',
    '36210',
    '36250',
    '36251',
    '36253',
    '36254',
    '36255',
    '36256',
    '36257',
    '36258',
    '36260',
    '36261',
    '36262',
    '36263',
    '36264',
    '36265',
    '36266',
    '36267',
    '36268',
    '36269',
    '36271',
    '36272',
    '36273',
    '36274',
    '36275',
    '36276',
    '36277',
    '36278',
    '36279',
    '36280',
    '36301',
    '36302',
    '36303',
    '36304',
    '36305',
    '36310',
    '36311',
    '36312',
    '36313',
    '36314',
    '36316',
    '36317',
    '36318',
    '36319',
    '36320',
    '36321',
    '36322',
    '36323',
    '36330',
    '36331',
    '36340',
    '36343',
    '36344',
    '36345',
    '36346',
    '36349',
    '36350',
    '36351',
    '36352',
    '36353',
    '36360',
    '36361',
    '36362',
    '36370',
    '36371',
    '36373',
    '36374',
    '36375',
    '36376',
    '36401',
    '36420',
    '36421',
    '36425',
    '36426',
    '36427',
    '36429',
    '36432',
    '36435',
    '36436',
    '36439',
    '36441',
    '36442',
    '36444',
    '36445',
    '36446',
    '36449',
    '36451',
    '36453',
    '36454',
    '36455',
    '36456',
    '36457',
    '36458',
    '36460',
    '36461',
    '36462',
    '36467',
    '36470',
    '36471',
    '36473',
    '36474',
    '36475',
    '36476',
    '36477',
    '36480',
    '36481',
    '36482',
    '36483',
    '36501',
    '36502',
    '36503',
    '36504',
    '36505',
    '36507',
    '36509',
    '36511',
    '36512',
    '36513',
    '36515',
    '36518',
    '36521',
    '36522',
    '36523',
    '36524',
    '36525',
    '36526',
    '36527',
    '36528',
    '36529',
    '36530',
    '36532',
    '36533',
    '36535',
    '36536',
    '36538',
    '36539',
    '36540',
    '36541',
    '36542',
    '36543',
    '36544',
    '36545',
    '36547',
    '36548',
    '36549',
    '36550',
    '36551',
    '36553',
    '36555',
    '36556',
    '36558',
    '36559',
    '36560',
    '36561',
    '36562',
    '36564',
    '36567',
    '36568',
    '36569',
    '36571',
    '36572',
    '36574',
    '36575',
    '36576',
    '36577',
    '36578',
    '36579',
    '36580',
    '36581',
    '36582',
    '36583',
    '36584',
    '36585',
    '36587',
    '36590',
    '36601',
    '36602',
    '36603',
    '36604',
    '36605',
    '36606',
    '36607',
    '36608',
    '36609',
    '36610',
    '36611',
    '36612',
    '36613',
    '36615',
    '36616',
    '36617',
    '36618',
    '36619',
    '36621',
    '36622',
    '36625',
    '36628',
    '36630',
    '36633',
    '36640',
    '36641',
    '36644',
    '36652',
    '36660',
    '36663',
    '36670',
    '36671',
    '36675',
    '36685',
    '36688',
    '36689',
    '36690',
    '36691',
    '36693',
    '36695',
    '36701',
    '36702',
    '36703',
    '36720',
    '36721',
    '36722',
    '36723',
    '36726',
    '36727',
    '36728',
    '36732',
    '36736',
    '36738',
    '36740',
    '36741',
    '36742',
    '36744',
    '36745',
    '36748',
    '36749',
    '36750',
    '36751',
    '36752',
    '36753',
    '36754',
    '36756',
    '36758',
    '36759',
    '36761',
    '36762',
    '36763',
    '36764',
    '36765',
    '36766',
    '36767',
    '36768',
    '36769',
    '36773',
    '36775',
    '36776',
    '36782',
    '36783',
    '36784',
    '36785',
    '36786',
    '36790',
    '36792',
    '36793',
    '36801',
    '36802',
    '36803',
    '36804',
    '36830',
    '36831',
    '36832',
    '36849',
    '36850',
    '36851',
    '36852',
    '36853',
    '36854',
    '36855',
    '36856',
    '36858',
    '36859',
    '36860',
    '36861',
    '36862',
    '36863',
    '36865',
    '36866',
    '36867',
    '36868',
    '36869',
    '36870',
    '36871',
    '36872',
    '36874',
    '36875',
    '36877',
    '36879',
    '36901',
    '36904',
    '36907',
    '36908',
    '36910',
    '36912',
    '36913',
    '36915',
    '36916',
    '36919',
    '36921',
    '36922',
    '36925'
];
