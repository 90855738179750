import React from 'react';

import { formatDollars } from '@makemydeal/dr-common-utils';

import { CreditDecisionDetails } from '../../../api/creditDecisionApi';
import { DealerDecision } from '../../../types/ExternalResources';
import { ConfirmationSubHeader, TableContainer, TableItem, TableItemTitle } from './DecisionTable.styled';

const parseDecision = (decision: DealerDecision | CreditDecisionDetails) => {
    if ('calculatedMonthlyPayment' in decision) {
        const lenderName = decision.lenderName;
        const payment = formatDollars(decision.calculatedMonthlyPayment);
        const loanAmount = formatDollars(decision.approvedAmount);
        const cashDown = formatDollars(decision.downPayment);
        const term = decision.approvedTerm;
        const apr = decision.approvedRate;
        return { apr, cashDown, lenderName, loanAmount, payment, term };
    }
    const lenderName = decision.creditDecisionLenderName;
    const payment = formatDollars(decision.creditDecisionMonthlyPayment);
    const loanAmount = formatDollars(Number.parseFloat(decision.creditDecisionAmount));
    const cashDown = formatDollars(Number.parseFloat(decision.creditDecisionDueAtSigning));
    const term = decision.creditDecisionTerm;
    const apr = decision.creditDecisionSellRate;
    return { apr, cashDown, lenderName, loanAmount, payment, term };
};

export const DecisionTable = ({ decision }: { decision: DealerDecision | CreditDecisionDetails }) => {
    const { apr, cashDown, lenderName, loanAmount, payment, term } = parseDecision(decision);
    return (
        <>
            <ConfirmationSubHeader>{lenderName} Offer Details</ConfirmationSubHeader>
            <TableContainer data-testid="decision-table">
                <TableItem>
                    <TableItemTitle>Finance Type</TableItemTitle>
                    <span>Loan</span>
                </TableItem>
                <TableItem>
                    <TableItemTitle>Payment</TableItemTitle>
                    <span>{payment}/mo</span>
                </TableItem>
                <TableItem>
                    <TableItemTitle>Loan Amount</TableItemTitle>
                    <span>{loanAmount}</span>
                </TableItem>
                <TableItem>
                    <TableItemTitle>Cash Down</TableItemTitle>
                    <span>{cashDown}</span>
                </TableItem>
                <TableItem>
                    <TableItemTitle>Term</TableItemTitle>
                    <span>{term} mo</span>
                </TableItem>
                <TableItem>
                    <TableItemTitle>APR</TableItemTitle>
                    <span>{apr}%</span>
                </TableItem>
            </TableContainer>
        </>
    );
};
