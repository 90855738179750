import React from 'react';

import { StyledHorizontalRule, TitleWrapper } from '../../components/shared.styled';
import { useExternalResourceState } from '../../contexts/ExternalResourceContext';
import { Resources } from '../../types/ExternalResources';
import AdditionalIncomePart from './AdditionalIncomePart';
import { EmploymentDispatcher } from './Employment.dispatcher';
import { Employment } from './models/Employment.model';

interface EmploymentInfoFormProps {
    title: string;
    employment: Employment;
    employmentDispatchers: EmploymentDispatcher;
    isJoint?: boolean;
    className?: string;
    enableEmploymentTimeVsAgeValidation?: boolean;
    yearMonthPickerErrorMessage: string;
    previousYearMonthPickerErrorMessage: string;
}

const EmploymentInfoForm: React.FC<EmploymentInfoFormProps> = (props) => {
    const {
        title,
        employment,
        employmentDispatchers,
        isJoint,
        className,
        enableEmploymentTimeVsAgeValidation,
        yearMonthPickerErrorMessage,
        previousYearMonthPickerErrorMessage
    } = props;
    const { employmentStatus, additionalIncome } = employment;
    const resourcesState = useExternalResourceState();
    const isEmployerAddressRequired = (resourcesState as Resources).dealer.isEmployerAddressRequired;
    const isCreditAppOccupationFieldEnabled = (resourcesState as Resources).toggles.toggles.enableCreditAppOccupationField;
    const isTollFreePhoneNumberAllowed = (resourcesState as Resources).toggles.toggles.isTollFreePhoneNumberAllowed;
    const isIncomeValidationEnabled = (resourcesState as Resources).toggles.toggles.isIncomeValidationEnabled;

    return (
        <div className={className}>
            {isJoint && <TitleWrapper>{title}</TitleWrapper>}
            {employmentStatus.renderEmploymentStatus(employmentDispatchers, {
                isEmployerAddressRequired,
                isCreditAppOccupationFieldEnabled,
                isTollFreePhoneNumberAllowed,
                isIncomeValidationEnabled,
                enableEmploymentTimeVsAgeValidation,
                yearMonthPickerErrorMessage,
                previousYearMonthPickerErrorMessage
            })}
            <StyledHorizontalRule />
            <AdditionalIncomePart
                additionalIncome={additionalIncome}
                employmentDispatchers={employmentDispatchers}
                isIncomeValidationEnabled
            />
        </div>
    );
};

export default EmploymentInfoForm;
