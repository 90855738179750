import { produce } from '../../../utils/Immer.class';
import { ActionCreator } from '../../ActionCreators';
import { Employment } from '../models/Employment.model';
import { EmploymentStatusFactory } from '../models/employmentStatus/Factory';

export class StatusActionCreator extends ActionCreator<Employment> {
    mutateField = (state: Employment) => {
        return produce(state, (draft) => {
            draft.employmentStatus = EmploymentStatusFactory.Build(this.fieldValue);
        });
    };
    public getAction = () => (prevState: Employment) => this.mutateField(prevState);
}
