import React, { useEffect, useRef, useState } from 'react';

import { Link } from '@makemydeal/ui-bricks/dist/cox';

import { AccelerateLoaderAnimationWrapper, ContinueButton, Footer, HeaderReel, SubGif } from './AccelerateLoaderAnimation.styled';

export interface CustomLoaderAnimationProps {
    [x: string]: any;
    gifSrc?: string;
    onSkip?: (e: any) => void;
    showSkipLink?: boolean;
    hideSkipCTA?: boolean;
}

const AccelerateLoaderAnimation: React.FC<CustomLoaderAnimationProps> = ({ gifSrc, showSkipLink, onSkip, hideSkipCTA }) => {
    const [message, setMessage] = useState('');
    const [textToRotate] = useState([
        { text: 'Gathering your information...', timeInMilliseconds: 4000 },
        { text: 'Preparing your paperwork...', timeInMilliseconds: 4000 },
        { text: 'Submitting your application to our preferred lenders...', timeInMilliseconds: 6000 },
        { text: 'Double checking their mailboxes...', timeInMilliseconds: 6000 },
        { text: 'Application received...', timeInMilliseconds: 6000 },
        { text: 'Grabbing some coffee...', timeInMilliseconds: 6000 },
        { text: 'Verifying your identity...', timeInMilliseconds: 8000 },
        { text: 'Identity verified...', timeInMilliseconds: 4000 },
        { text: 'Reviewing your credit profile...', timeInMilliseconds: 8000 },
        { text: 'Crunching the numbers...', timeInMilliseconds: 8000 }
    ]);
    const time = useRef(1000);
    let index = 0;

    const interval: any = useRef();

    useEffect(() => {
        let mounted = true;
        const changingTimer = () => {
            if (mounted) {
                if (index < textToRotate.length) {
                    time.current = textToRotate[index].timeInMilliseconds;
                    index++;
                } else {
                    // When on last item, clear the current interval so we can loop through again.
                    clearInterval(interval.current);
                }
            }
        };

        const reel = () => {
            // Clears the previous setInterval timer
            if (mounted) {
                clearInterval(interval.current);
                if (textToRotate[index]) {
                    setMessage(textToRotate[index].text);
                }
                // changing timer
                changingTimer();
                interval.current = setInterval(reel, time.current);
            }
        };

        reel();

        return function cleanup() {
            mounted = false;
            clearInterval(interval.current);
        };
    }, [textToRotate, index, time]);

    return (
        <>
            <AccelerateLoaderAnimationWrapper data-testid={'accelerate-custom-loader'}>
                <HeaderReel data-testid={'header-reel'}>{message}</HeaderReel>
                <img alt="Loading animation for credit decisions" className="animation" src={gifSrc} />
                <Footer>
                    <SubGif>Hang tight, this could take up to 60 seconds.</SubGif>
                    {!hideSkipCTA && (
                        <>
                            <p>
                                If you do not want to wait, you can continue on with other time saving activities. You will receive
                                an email when decisions become available.
                            </p>
                            {showSkipLink && (
                                <ContinueButton data-testid={'continue-button'}>
                                    <Link onClick={onSkip} className="skip-loader-animation">
                                        Continue to Next Steps
                                    </Link>
                                </ContinueButton>
                            )}
                        </>
                    )}
                </Footer>
            </AccelerateLoaderAnimationWrapper>
        </>
    );
};

export default AccelerateLoaderAnimation;
