import React from 'react';

import { Input, PopUpLabel } from '@makemydeal/ui-bricks/dist/cox';

import { ThemedInputWrapper } from '../components/shared.styled';
import { useWindowSize } from '../customHooks/UseWindowSize.hook';
import { FormField, Validator } from '../formValidator';
import { ChangeHandler } from '../formValidator/BaseFormField';
import { tabletWidth } from '../types/Constants';
import { emptyString } from '../utils/helper';

interface ZipCodeFormFieldProps {
    formField: FormField.Phone;
    applicantAge?: number;
    errorMessage?: string;
    onChangeHandler: ChangeHandler;
}

/**
 *
 * @param applicantAge
 * ZipCodeFormField was built for the Community Property State of Alabama.
 * The prupose is to render a disclaimer should the applicant or Co-Applicant be 19 years of age or younger.
 * So this component needs to know about the applicant age.
 * Key Note:  A zip code field can be made using the InputForm Field should you need one that does not consider age.
 *
 * ex: <InputFormField
 *      formField={zip}
 *      onChangeHandler={addressDispatcher.updateAddressInfo}
 *      maxlength={5} />  (as shown in form components.)
 *
 */
const ZipCodeFormField: React.FC<ZipCodeFormFieldProps> = ({ formField, applicantAge, onChangeHandler, errorMessage }) => {
    const size = useWindowSize();
    const screenSize = size.width ?? 0;
    const isMobile = screenSize <= tabletWidth;

    const currentErrorMessage = formField.value && formField.value.length < 4 ? Validator.getErrorMessage(formField) : errorMessage;
    const hasError = formField.hasError || Boolean(formField.value?.length !== 0 && currentErrorMessage);

    return (
        <>
            <ThemedInputWrapper>
                <Input
                    name={formField.name ?? ''}
                    placeholder={formField.placeholder}
                    errorMessage={currentErrorMessage}
                    label={formField.label ?? ''}
                    labelCustomization={
                        (applicantAge ?? '') <= 19
                            ? {
                                  renderCustomComponent: () => {
                                      return isMobile || emptyString(formField.tooltip ?? '') ? null : (
                                          <PopUpLabel className="primary-link" label="View Disclaimer">
                                              {formField.tooltip ?? ''}
                                          </PopUpLabel>
                                      );
                                  }
                              }
                            : undefined
                    }
                    tooltip={isMobile ? formField.tooltip : undefined}
                    optional={formField.optional}
                    value={formField.value}
                    hasError={hasError}
                    onChangeFunction={onChangeHandler}
                    validationFunction={
                        formField.value && formField.value.length === 5 && !errorMessage
                            ? formField.validationFunction
                            : () => false
                    }
                    maxlength={formField.maxlength ?? undefined}
                />
            </ThemedInputWrapper>
        </>
    );
};

export default ZipCodeFormField;
